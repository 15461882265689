import { utils } from "pixi.js";
import { lazyInject } from "../ioc";
import { EventBus, Events } from "../events";
import { Types } from "../types/Types";
import { AudioAdapter } from "./AudioAdapter";
import {injectable} from "inversify";

@injectable()
export class HowlerAdapter extends utils.EventEmitter implements AudioAdapter {
	@lazyInject(Types.EventBus)
	protected eventBus!: EventBus;

	protected registeredSounds: { [name: string]: Howl } = {};

	protected currentFades: { [name: string]: Howl } = {};

	protected _isMuted: boolean = true;

	public constructor() {
		super();
		this.eventBus.on(Events.Sound.Play, this.play, this);
		this.eventBus.on(Events.Sound.Loop, this.loop, this);
		this.eventBus.on(Events.Sound.Stop, this.stop, this);
		this.eventBus.on(Events.Sound.Pause, this.pause, this);
		this.eventBus.on(Events.Sound.Resume, this.resume, this);
		this.eventBus.on(Events.Sound.Fade, this.fade, this);
		this.eventBus.on(Events.Sound.FadeAndStop, this.fadeAndStop, this);
	}

	public registerSound(name: string, sound: any): void {
		this.registeredSounds[name] = sound as Howl;
	}

	public play(name: string): void {
		console.log("Play:", name);
		if (this.registeredSounds[name] != undefined && !this._isMuted) {
			this.clearFades(name);

			this.registeredSounds[name].volume(1);
			this.registeredSounds[name].play();
		} else if (!this._isMuted) {
			console.error("Sound", name, "not found.");
		}
	}

	public loop(name: string): void {
		console.log("Loop:", name);
		if (this.registeredSounds[name] != undefined && !this._isMuted) {
			this.clearFades(name);

			this.stop(name);

			this.registeredSounds[name].loop(true);
			this.registeredSounds[name].volume(1);
			this.registeredSounds[name].play();
			const fade: Howl = this.registeredSounds[name].fade(this.registeredSounds[name].volume(), 1, 1);
			fade.once("fade", () => {
				this.onFadeComplete(name);
			});
			this.currentFades[name] = fade;
		} else if (!this._isMuted) {
			console.error("Sound", name, "not found.");
		}
	}

	public pause(name: string): void {
		if (this.registeredSounds[name] != undefined) {
			this.registeredSounds[name].pause();
		} else if (!this._isMuted) {
			console.error("Sound", name, "not found.");
		}
	}

	public resume(name: string): void {
		this.play(name);
	}

	public stop(name: string): void {
		if (this.registeredSounds[name] != undefined) {
			this.registeredSounds[name].stop();
		} else if (!this._isMuted) {
			console.error("Sound", name, "not found.");
		}
	}

	public fade(name: string, to: number, duration: number): void {
		if (this.registeredSounds[name] != undefined) {
			this.clearFades(name);

			const fade: Howl = this.registeredSounds[name].fade(this.registeredSounds[name].volume(), to, duration);
			fade.once("fade", () => {
				this.onFadeComplete(name);
			});
			this.currentFades[name] = fade;
		} else if (!this._isMuted) {
			console.error("Sound", name, "not found.");
		}
	}

	public fadeAndStop(name: string, duration: number): void {
		this.fade(name, 0, duration);

		if (this.registeredSounds[name] != undefined && !this._isMuted) {
			if (this.currentFades[name] != undefined) {
				this.currentFades[name].once("fade", () => {
					this.registeredSounds[name].stop();
				});
			}
		}
	}

	protected onFadeComplete(name: string): void {
		this.clearFades(name);
	}

	protected clearFades(name: string): void {
		if (this.currentFades[name] != undefined) {
			this.currentFades[name].off();
			this.currentFades[name].stop();
			delete this.currentFades[name];
		}
	}

	public mute(): void {
		this._isMuted = true;
		Howler.mute(true);
		this.eventBus.emit(Events.Sound.Disabled);
	}

	public unmute(): void {
		this._isMuted = false;
		Howler.mute(false);
		this.eventBus.emit(Events.Sound.Enabled);
	}

	public isMuted(): boolean {
		return this._isMuted;
	}
}

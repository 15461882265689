import { lazyInject } from "../ioc/Inject";
import { LoggingAdapter } from "./LoggingAdapter";
import {Types} from "../types/Types";

export class Logger {
	@lazyInject(Types.LoggingAdapter)
	protected static logger: LoggingAdapter;

	public static fatal(message: string, ...rest: any[]): void {
		if (rest && rest.length > 0) {
			for (const value of rest) {
				if (typeof value === "string") {
					message += " " + value;
				} else {
					message += " " + value.toString();
				}
			}
		}

		this.logger.fatal(message);
	}

	public static error(message: string, ...rest: any[]): void {
		if (rest && rest.length > 0) {
			for (const value of rest) {
				if (typeof value === "string") {
					message += " " + value;
				} else {
					message += " " + value.toString();
				}
			}
		}

		this.logger.error(message);
	}

	public static warn(message: string, ...rest: any[]): void {
		if (rest && rest.length > 0) {
			for (const value of rest) {
				if (typeof value === "string") {
					message += " " + value;
				} else {
					message += " " + value.toString();
				}
			}
		}

		this.logger.warn(message);
	}

	public static info(message: string, ...rest: any[]): void {
		if (rest && rest.length > 0) {
			for (const value of rest) {
				if (typeof value === "string") {
					message += " " + value;
				} else {
					message += " " + value.toString();
				}
			}
		}

		this.logger.info(message);
	}

	public static debug(message: string, ...rest: any[]): void {
		if (rest && rest.length > 0) {
			for (const value of rest) {
				if (typeof value === "string") {
					message += " " + value;
				} else {
					message += " " + value.toString();
				}
			}
		}

		this.logger.debug(message);
	}
}

import { ComponentType, ContainerConfig, HAlign, TextAlign, VAlign } from "@tournament/ui-core";
import { SkinComponentType } from "../../types/SkinComponentType";
import { SkinDisplayChild } from "../../components/config/SkinDisplayChild";
import { Components, HiLoComponentType, HiLoEvents } from "@tournament/hilo-ui-core";

export const DownButton: ContainerConfig<SkinDisplayChild, HiLoComponentType | SkinComponentType> = {
	type: ComponentType.Container,
	layout: {
		dimensions: { heightPercent: 110, widthPercent: 30 },
		vAlign: VAlign.Middle,
		hAlign: HAlign.Right,
		offset: {
			yPercent: 2
		}
	},
	children: [
		{
			id: Components.NumUsersDownBackground,
			type: ComponentType.Sprite,
			src: "UsersDown-Background",
			layout: {
				dimensions: { width: 356, height: 114, widthPercent: 100, heightPercent: 24 },
				offset: {
					yPercent: -7
				},
				hAlign: HAlign.Center
			}
		},
		{
			id: Components.NumUsersDownText,
			type: ComponentType.Text,
			text: "",
			style: {
				fontFamily: ["Oxygen", "sans-serif"],
				fontWeight: "700",
				fill: 0xffffff,
				align: TextAlign.Center
			},
			layout: {
				dimensions: { width: 356, height: 114, widthPercent: 80, heightPercent: 17 },
				offset: {
					yPercent: -3
				},
				hAlign: HAlign.Center
			}
		},
		{
			id: Components.LowerButton,
			type: ComponentType.Button,
			textures: {
				up: "DownButton-Up",
				over: "DownButton-Over",
				down: "DownButton-Down",
				disabled: "DownButton-Disabled"
			},
			clickEvent: HiLoEvents.User.ChooseLower,
			layout: {
				dimensions: { width: 467, height: 465, heightPercent: 85, widthPercent: 100 },
				offset: {
					yPercent: 3
				},
				hAlign: HAlign.Center
			}
		},
		{
			type: ComponentType.Text,
			text: {
				id: "lower"
			},
			style: {
				fontFamily: ["Oxygen", "sans-serif"],
				fontWeight: "700",
				fill: 0xffffff,
				align: TextAlign.Center
			},
			layout: {
				dimensions: { widthPercent: 100, heightPercent: 18 },
				offset: {
					yPercent: 76
				},
				hAlign: HAlign.Center
			}
		}
	]
};

import { Filter } from "pixi.js";
import fragment from "../shaders/gridShader.frag";

export class GridFilter extends Filter {
	public constructor(boxSize: number, lineWeight: number) {
		super(undefined, fragment);
		this.uniforms.dimensions = new Float32Array(2);
		this.uniforms.boxSize = boxSize;
		this.uniforms.lineWeight = lineWeight;
	}

	public apply(
		filterManager: PIXI.systems.FilterSystem,
		input: PIXI.RenderTexture,
		output: PIXI.RenderTexture,
		clear: boolean,
		currentState?: any
	): void {
		this.uniforms.dimensions[0] = input.filterFrame.width;
		this.uniforms.dimensions[1] = input.filterFrame.height;

		filterManager.applyFilter(this, input, output, clear);
	}

	public set boxSize(value: number) {
		this.uniforms.boxSize = value;
	}

	public get boxSize(): number {
		return this.uniforms.boxSize;
	}

	public set lineWeight(value: number) {
		this.uniforms.lineWeight = value;
	}

	public get lineWeight(): number {
		return this.uniforms.lineWeight;
	}
}

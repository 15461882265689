import { Components } from "../types/Components";
import { injectable } from "inversify";
import { AudioAdapter, EventBus, Events, lazyInject, ToggleButton, Types } from "@tournament/ui-core";
import { HiLoEvents } from "../events";

@injectable()
export class SoundController {
	@lazyInject(Types.EventBus)
	protected eventBus!: EventBus;

	@lazyInject(Components.SoundButton)
	protected soundButton!: ToggleButton;

	@lazyInject(Types.AudioAdapter)
	protected audioAdapter!: AudioAdapter;

	protected soundsLoaded: boolean;

	public constructor() {
		this.eventBus.on(HiLoEvents.User.ToggleSound, this.toggleSound, this);
		this.eventBus.on(Events.Sound.Enable, this.enableSound, this);
		this.eventBus.on(Events.Sound.Disable, this.disableSound, this);
		this.eventBus.on(Events.Sound.Enabled, () => this.soundButton.setState(true));
		this.eventBus.on(Events.Sound.Disabled, () => this.soundButton.setState(false));

		this.soundsLoaded = !this.audioAdapter.isMuted();

		this.eventBus.once(Events.Loading.SoundsLoaded, this.onSoundsLoaded, this);

		this.soundButton.setState(!this.audioAdapter.isMuted());
	}
	protected toggleSound(): void {
		if (this.audioAdapter.isMuted()) {
			this.enableSound();
		} else {
			this.disableSound();
		}
	}

	protected enableSound(): void {
		if (!this.soundsLoaded)
			this.eventBus.emit(Events.Loading.LoadSounds);
		
		this.audioAdapter.unmute();
	}

	protected disableSound(): void {
		this.audioAdapter.mute();
	}

	protected onSoundsLoaded(): void {
		this.soundsLoaded = true;
	}
}

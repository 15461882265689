import { injectable } from "inversify";
import { Texture } from "pixi.js";
import { ComponentBuilder } from "./ComponentBuilder";
import { LayoutComponent } from "../LayoutComponent";
import { appContainer } from "../../ioc";
import { LayoutContainer } from "../LayoutContainer";
import { NineSliceSpriteConfig } from "../config";
import { NineSliceSprite } from "../NineSliceSprite";

@injectable()
/**
 * The NineSliceSprite creates Sprite image components that use 9-Slice scaling.
 *
 * @see NineSliceSprite
 * @see NineSliceSpriteConfig
 */
export class NineSliceSpriteBuilder implements ComponentBuilder {
	public build(config: NineSliceSpriteConfig<any, any>, parentDimensions?: { width: number; height: number }): LayoutComponent {
		const container: NineSliceSprite = new NineSliceSprite(
			config.layout,
			Texture.from(config.slices.texture),
			config.slices.leftWidth,
			config.slices.topHeight,
			config.slices.rightWidth,
			config.slices.bottomHeight
		);

		this.setContainerProps(config, container);

		return container;
	}

	protected setContainerProps(config: NineSliceSpriteConfig<any, any>, container: LayoutContainer): void {
		if (config.visible === false) {
			container.visible = false;
		}

		this.createBindings(config.id, container);
	}

	protected createBindings(id: string | undefined, container: LayoutContainer): void {
		if (id != undefined) {
			container.name = id;
			appContainer.bind<LayoutContainer>(id).toConstantValue(container);
		}
	}
}

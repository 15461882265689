import { SoundDialog } from "./components/SoundDialog";
import { PreloadErrorDialog } from "./components/PreloadErrorDialog";
import {
	BackgroundType,
	ComponentType,
	GradientDirection,
	HAlign,
	LayoutConfig,
	LayoutMode,
	TextAlign,
	VAlign
} from "@tournament/ui-core";
import { SkinDisplayChild } from "../components/config/SkinDisplayChild";
import { Components, Controllers } from "@tournament/hilo-ui-core";
import { SAFE_ZONE_HEIGHT } from "./Constants";

export const PreloaderConfig: LayoutConfig<SkinDisplayChild> = {
	components: [
		{
			type: ComponentType.Container,
			layout: {
				dimensions: {
					widthPercent: 100,
					heightPercent: 100
				},
				childLayout: {
					mode: LayoutMode.Vertical,
					hAlign: HAlign.Center,
					padding: 1
				}
			},
			background: {
				type: BackgroundType.Color,
				color: 0x181637,
				gradient: {
					colorStops: [
						{ offset: 0, color: "#0c0c1e" },
						{ offset: 0.5, color: "#181637" },
						{ offset: 1, color: "#0a0c1a" }
					],
					direction: GradientDirection.ToBottom
				}
			},
			children: [
				{
					type: ComponentType.Container,
					layout: {
						dimensions: {
							widthPercent: 100,
							heightPercent: 100
						},
						margin: {
							height: () => window.innerHeight > window.innerWidth ? SAFE_ZONE_HEIGHT : 0,
						}
					},
					children: [
						{
							type: ComponentType.Container,
							layout: {
								dimensions: {
									widthPercent: 100,
									heightPercent: 100
								},
								childLayout: {
									mode: LayoutMode.Vertical,
									vAlign: VAlign.Middle,
									hAlign: HAlign.Center,
									padding: 2
								}
							},
							children: [
								{
									type: ComponentType.Sprite,
									src: "assets/images/logo.png",
									layout: {
										dimensions: { width: 381, height: 43, widthPercent: 35 },
										offset: {
											yPercent: -5
										}
									}
								},
								{
									id: Components.LoadingBar,
									type: ComponentType.LoadingBar,
									layout: {
										dimensions: {
											width: 200,
											height: 4,
											widthPercent: 35
										}
									},
									barColor: 0xffffff,
									trackColor: 0x17152e,
									cornerRadius: 2
								}
							]
						}
					]
				}
			]
		},
		{
			type: ComponentType.Container,
			layout: {
				dimensions: {
					widthPercent: 100,
					heightPercent: 100
				},
				margin: {
					height: () => window.innerHeight > window.innerWidth ? SAFE_ZONE_HEIGHT : 0,
				}
			},
			children: [
				{
					id: Components.VersionNumber,
					type: ComponentType.Text,
					text: "",
					layout: {
						dimensions: {
							widthPercent: 6,
							heightPercent: 3
						},
						offset: {
							yPercent: -2,
							xPercent: 2
						},
						vAlign: VAlign.Bottom
					},
					style: {
						fontFamily: ["Oxygen", "sans-serif"],
						fontWeight: "700",
						fill: 0xaaaaaa,
						align: TextAlign.Left
					}
				},
				SoundDialog,
				PreloadErrorDialog]
		}
	],
	controllers: [Controllers.Preload, Controllers.ClientAPI]
};

import { Ease } from "./Ease";

export class Linear {
	public static easeNone: Ease = (time: number, from: number, to: number, duration: number): number => {
		if (time >= duration) {
			return to;
		}

		return (to - from) * (time / duration) + from;
	};
}

import { injectable } from "inversify";
import {
	Button,
	EventBus,
	Events,
	LayoutContainer,
	lazyInject,
	NineSliceSprite,
	ProgressBar,
	TextField,
	Translations,
	Types
} from "@tournament/ui-core";
import { GameModel } from "../models";
import { Components } from "../types";
import { HiLoEvents } from "../events";
import { HiLoGameMode } from "../models/HiLoGameMode";
import Dinero from "dinero.js";

@injectable()
export class RoundProgressController {
	@lazyInject(Types.Translations)
	protected translations!: Translations;

	@lazyInject(Types.EventBus)
	protected eventBus!: EventBus;

	@lazyInject(Types.GameModel)
	protected model!: GameModel;

	@lazyInject(Components.RoundProgressBarContainer)
	protected progressBarContainer!: LayoutContainer;

	@lazyInject(Components.RoundProgressBar)
	protected progressBar!: ProgressBar;

	@lazyInject(Components.AfterRoundMessage)
	protected afterRoundMessage!: TextField;

	@lazyInject(Components.MillionairePrizeOfferDialog)
	protected prizeOfferDialog!: NineSliceSprite;

	@lazyInject(Components.MillionairePrizeOffer)
	protected prizeOfferValue!: TextField;

	@lazyInject(Components.MillionaireNextPrizeValue)
	protected nextPrizeValue!: TextField;

	@lazyInject(Components.CollectPrizeButton)
	protected collectPrizeButton!: Button;

	@lazyInject(Components.MillionaireContinueButton)
	protected continueButton!: Button;

	@lazyInject(Components.ProgressMinRoundValue)
	protected progressMinRoundValue!: TextField;

	@lazyInject(Components.ProgressMaxRoundValue)
	protected progressMaxRoundValue!: TextField;

	protected currentPrizeIndex: number | undefined;

	public constructor() {
		this.eventBus.on(HiLoEvents.Game.Init, this.init, this);
	}

	protected init(): void {
		this.progressBarContainer.visible = false;

		if (this.model.gameMode === HiLoGameMode.Millionaire) {
			this.eventBus.on(HiLoEvents.Game.RoundUpdated, this.onRoundUpdated, this);
			this.eventBus.on(HiLoEvents.Game.Reset, this.reset, this);
			this.eventBus.on(HiLoEvents.Game.GameOver, this.onGameComplete, this);
			this.eventBus.on(HiLoEvents.Game.PrizeWin, this.onGameComplete, this);

			if (this.model.inPlay) {
				this.initProgressBar();
			} else {
				this.eventBus.once(HiLoEvents.Game.RoundUpdated, this.initProgressBar, this);
			}
			// Ticker.shared.add(this.update, this);
		}
	}

	protected initProgressBar(): void {
		if (this.model.inPlay) {
			this.progressBarContainer.visible = true;

			this.updateProgressBar();
		}
	}

	protected updateProgressBar(): void {
		// Set values from prize ladder for the current round.

		const prizeLadder = this.model.prizeLadder;

		if (this.model.inPlay && prizeLadder != undefined && this.model.playerRoundNumber != undefined) {
			let minRound: number = 0;
			let maxRound: number;
			let prizeAmount: number;
			let previousPrizeAmount: number | undefined;

			for (let i = 0; i < prizeLadder.length; i++) {
				if (this.model.playerRoundNumber <= prizeLadder[i].roundNumber) {
					maxRound = prizeLadder[i].roundNumber;
					prizeAmount = prizeLadder[i].prizeAmount;
					if (i > 0) {
						minRound = prizeLadder[i - 1].roundNumber;
						previousPrizeAmount = prizeLadder[i - 1].prizeAmount;
					}

					this.progressBar.setMaxValue(maxRound - minRound);
					this.progressBar.setProgress(Math.max(0, this.model.playerRoundNumber - 1) - minRound);

					console.log("Player round:", this.model.playerRoundNumber, "min: ", minRound);
					const roundsRemaining: number = maxRound - Math.max(0, this.model.playerRoundNumber - 1);
					this.afterRoundMessage.setText(
						this.translations.get("roundsRemaining", { count: roundsRemaining })
					);

					if (previousPrizeAmount) {
						this.progressMinRoundValue.setText(
							Dinero({
								amount: previousPrizeAmount * 100,
								currency: this.model.currency as any
							}).toFormat("$0,0")
						);
					} else {
						this.progressMinRoundValue.setText("");
					}
					this.progressMaxRoundValue.setText(
						Dinero({
							amount: prizeAmount * 100,
							currency: this.model.currency as any
						}).toFormat("$0,0")
					);

					if (this.currentPrizeIndex !== i) {
						if (this.currentPrizeIndex != undefined) {
							// If we're at a new stage, show prize offer dialog.
							this.offerPrize();
						}

						this.currentPrizeIndex = i;
					}

					break;
				}
			}
		}
	}

	protected onRoundUpdated(): void {
		if (this.model.inPlay) {
			// TODO: Animate progress bar update
			this.updateProgressBar();
		}
	}

	protected offerPrize(): void {
		console.log("Show prize offer dialog");
		this.prizeOfferDialog.visible = true;

		const prizeLadder = this.model.prizeLadder;

		if (prizeLadder != undefined) {
			this.prizeOfferValue.setText(
				this.translations.get("millionaireYouWin", {
					amount: Dinero({
						amount: prizeLadder[this.currentPrizeIndex as number].prizeAmount * 100,
						currency: this.model.currency as any
					}).toFormat("$0")
				})
			);

			if ((this.currentPrizeIndex as number) + 1 < prizeLadder.length) {
				this.nextPrizeValue.visible = true;
				this.nextPrizeValue.setText(
					Dinero({
						amount: prizeLadder[(this.currentPrizeIndex as number) + 1].prizeAmount * 100,
						currency: this.model.currency as any
					}).toFormat("$0")
				);
			} else {
				this.nextPrizeValue.visible = false;
			}

			this.continueButton.once(Events.Interaction.Click, this.onOfferRejected, this);
		}
	}

	protected onOfferAccepted(): void {
		// Prize offer accepted - close game for now.
	}

	protected onOfferRejected(): void {
		// Prize offer rejected - update values and reset progress.
		this.prizeOfferDialog.visible = false;
	}

	protected onGameComplete(): void {
		// Hide progress bar container
		this.progressBarContainer.visible = false;
	}

	protected reset(): void {
		this.currentPrizeIndex = undefined;

		if (this.model.prizeLadder != undefined) {
			this.progressBar.setMaxValue(this.model.prizeLadder[0].roundNumber);
			this.progressBar.setProgress(0);
		}
	}
}

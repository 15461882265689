export const translationsPt = {
	higher: "MAIOR",
	lower: "MENOR",
	higherOrLowerQuestion: "O PRÓXIMO NÚMERO SERÁ\nMAIOR OU MENOR?",
	gameStart: "O JOGO COMEÇAR DAQUI",
	gameStarting: "O JOGO ESTÁ COMEÇANDO...",
	playersRegistered: "{{count}} jogador cadastrado",
	playersRegistered_plural: "{{count}} jogadores cadastrados",
	noPlayersRemaining: "Nenhum jogador restante",
	playersRemaining: "{{count}} jogador restante",
	playersRemaining_plural: "{{count}} jogadores restantes",
	roundTitle: "RODADA {{count}}",
	survivalTitle: "{{count}} RODADA SOBREVIVÊNCIA",
	survivalDescription: "SOBREVIVER ATÉ O FIM\nPARA GANHAR SUA PARTE DO PRÊMIO",
	lastManTitle: "ÚLTIMO HOMEN DE PÉ",
	lastManTitle_plural: "ÚLTIMO {{count}} DE PÉ",
	lastManDescription: "ÚLTIMO HOMEN DE PÉ\nGANHA O PRÊMIO",
	lastManDescription_plural: "ÚLTIMO {{count}} DE PÉ\nDIVIDIR O PRÊMIO",
	leaderboardTitle: "ALTA PONTUAÇÃO DIÁRIA",
	leaderboardDescription: "O JOGADOR COM A PONTUAÇÃO TOTAL MAIS ALTA EM 24 HORAS\nGANHA O PRÊMIO",
	leaderboardDescription_plural: "OS JOGADORES COM A PONTUAÇÃO TOTAL MAIS ALTA EM 24 HORAS\nDIVIDIR O PRÊMIO",
	soundTitle: "Ativar Som",
	soundDescription: "Deseja ativar o som?",
	gameOver: "GAME OVER",
	youWin: "GANHOU",
	betterLuckNextTime: "MAIS SORTE DA PRÓXIMA VEZ",
	knockedOut: "TODOS NOCAUTEADOS",
	congratulations: "PARABÉNS",
	toOurWinners: "AOS NOSSOS GANHADORES",
	andXMore: "..E MAIS {{count}} JOGADOR",
	andXMore_plural: "..E MAIS {{count}} JOGADORES",
	yesLabel: "Sim",
	noLabel: "Não",
	confirmLabel: "OK",
	stayAndWatch: "FICAR OBSERVANDO",
	returnToLobby: "VOLTAR À LISTA",
	errorTitle: "Erro",
	loadingErrorMessage: "Ocorreu um problema iniciando o jogo.\nSe o problema persisti, entre em contato com o suporte.",
	genericErrorMessage: "Ocorreu um problema inesperado.\nSe o problema persistir, entre em contato com o suporte.",
	finishedErrorMessage: "Esse torneio já terminou.\nPor favor, escolha outro jogo da lista.",
	startedErrorMessage: "Esse torneio acabou de começar.\nPor favor, escolha outro torneio da lista.",
	fullErrorMessage: "Infelizmente esse torneio já está cheio.\nPor favor, escolha outro torneio da lista."
};

import { injectable } from "inversify";
import { Texture } from "pixi.js";
import { ComponentBuilder } from "./ComponentBuilder";
import { SpriteConfig } from "../config";
import { LayoutComponent } from "../LayoutComponent";
import { LayoutSprite } from "../LayoutSprite";
import { appContainer } from "../../ioc";

@injectable()
/**
 * The SpriteBuilder creates LayoutSprite components, which wrap the Pixi Sprite class to add layout funcitonality.
 *
 * @see LayoutSprite
 * @see SpriteConfig
 */
export class SpriteBuilder implements ComponentBuilder {
	public build(config: SpriteConfig<any>, parentDimensions?: { width: number; height: number }): LayoutComponent {
		const texture: Texture = Texture.from(config.src);
		const sprite: LayoutSprite = new LayoutSprite(texture, config.layout);

		if (config.id != undefined) {
			sprite.name = config.id;
			appContainer.bind<LayoutSprite>(config.id).toConstantValue(sprite);
		}

		if (config.visible === false) {
			sprite.visible = false;
		}

		if (config.alpha != undefined) {
			sprite.alpha = config.alpha;
		}

		return sprite;
	}
}

import { LinkNode } from "./LinkNode";

/**
 * A simple implementation of a linked list using generics.
 */
export class LinkedList<T> {
	/**
	 * The first node in the list.
	 */
	private head: LinkNode<T> | undefined;
	/**
	 * The second node in the list.
	 */
	private tail: LinkNode<T> | undefined;
	/**
	 * The total number of items in the list.
	 */
	private length: number;

	public constructor() {
		this.length = 0;
	}

	/**
	 * @returns The first node in the list, or undefined if the list is empty.
	 */
	public getHead(): LinkNode<T> | undefined {
		return this.head;
	}

	/**
	 * @returns The last node in the list, or undefined if the list is empty.
	 */
	public getTail(): LinkNode<T> | undefined {
		return this.tail;
	}

	/**
	 * Adds the specified node to the end of the list.
	 *
	 * @param node The node to add to the list.
	 */
	public append(node: LinkNode<T>): LinkedList<T> {
		if (this.head == undefined) {
			this.head = node;
			this.tail = node;
		} else if (this.tail != undefined) {
			node.prev = this.tail;
			this.tail.next = node;
			this.tail = node;
		}

		this.length++;
		return this;
	}

	/**
	 * Adds the specified node to the start of the list.
	 *
	 * @param node The node to add to the list.
	 */
	public prepend(node: LinkNode<T>): LinkedList<T> {
		if (this.head == undefined) {
			this.head = node;
			this.tail = node;
			return this;
		} else {
			node.next = this.head;
			this.head.prev = node;
			this.head = node;
		}

		this.length++;
		return this;
	}

	/**
	 * Removes the specified node from the list.
	 *
	 * @param node The node to remove from the list.
	 * @returns The current list instance.
	 */
	public remove(node: LinkNode<T>): LinkedList<T> {
		if (this.head === this.tail) {
			this.head = this.tail = undefined;
		} else if (this.length > 0) {
			if (node === this.tail) {
				if (node.prev) {
					node.prev.next = undefined;
				}
				this.tail = node.prev;
			} else if (node === this.head) {
				if (node.next) {
					node.next.prev = undefined;
				}
				this.head = node.next;
			} else {
				if (node.next) {
					node.next.prev = node.prev;
				}
				if (node.prev) {
					node.prev.next = node.next;
				}
			}
		}

		this.length--;
		node.next = node.prev = undefined;
		return this;
	}

	/**
	 * Returns the number of items in the list.
	 */
	public getLength(): number {
		return this.length;
	}

	/**
	 * Removes all items from the list.
	 */
	public clear(): void {
		if (this.head == undefined) {
			return;
		}

		let node: LinkNode<T> | undefined = this.head;
		let next: LinkNode<T> | undefined;
		while (node != undefined) {
			next = node.next;
			node.next = node.prev = undefined;
			node = next;
		}

		this.head = this.tail = undefined;

		this.length = 0;
	}

	/**
	 * Returns all items in the list in an array.
	 */
	public toArray(): T[] {
		const result: T[] = [];
		let node: LinkNode<T> | undefined = this.head;

		while (node != undefined) {
			result.push(node.getValue());
			node = node.next;
		}

		return result;
	}

	/**
	 * Returns all items in the list in an array.
	 */
	public toNodeArray(): LinkNode<T>[] {
		const result: LinkNode<T>[] = [];
		let node: LinkNode<T> | undefined = this.head;

		while (node != undefined) {
			result.push(node);
			node = node.next;
		}

		return result;
	}

	/**
	 * Adds all values in the array to the end of the list.
	 *
	 * @param values The array of values to add to the list.
	 */
	public fromArray(values: T[]): LinkedList<T> {
		values.forEach(value => this.append(new LinkNode<T>(value)));
		return this;
	}

	/**
	 * @returns An iterable iterator for the current list.
	 */
	public *getIterator(): IterableIterator<T> {
		let node: LinkNode<T> | undefined = this.head;
		while (node != undefined) {
			yield node.getValue();
			node = node.next;
		}
	}
}

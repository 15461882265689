import "reflect-metadata";
import "regenerator-runtime/runtime";
import "./index.scss";
import { HiLoConfig } from "./config/HiLoConfig";
import { Application, settings, SCALE_MODES } from "pixi.js";
import { GridContainerBuilder } from "./components/builders/GridContainerBuilder";
import * as WebFont from "webfontloader";
import { appContainer, EventBus, HowlerAdapter, HTMLUtils, RollbarLoggingAdapter, Services, Translations, Types, Builders, ContainerBuilder, ComponentType, TextBuilder, SpriteBuilder, SpriteAnimationBuilder, ButtonBuilder, NineSliceSpriteBuilder, LoadingBarBuilder, NineSliceButtonBuilder, Factories, LayoutManager, GameBuilder, ProgressBarBuilder } from "@tournament/ui-core";
import { ChoiceController, Components, Controllers, LeaderboardModeController, GameModel, HiLoClientService, HiLoComponentType, HiLoGame, LastNumbersController, NumberSpinnerBuilder, PreloadController, SoundController, translationsEn, translationsPt, UIController, ClientApiController, RoundProgressController, BackgroundMusicController } from "@tournament/hilo-ui-core";
import { PreloaderConfig } from "./config/PreloaderConfig";
import { HiLoAssetConfig } from "./config/HiLoAssetConfig";
import { BackgroundAnimController } from "./controllers/BackgroundAnimController";
import { TimerAnimController } from "./controllers/TimerAnimController";
import Config from "@tournament/hilo-ui-core/dist/config";
var token = HTMLUtils.getQueryVariable("token") || "";
var gameId = parseInt(HTMLUtils.getQueryVariable("gameId") || "1", 10);
var operatorId = HTMLUtils.getQueryVariable("operator") || "";
var lang = HTMLUtils.getQueryVariable("lang") || "en";
var controlsEnabled = HTMLUtils.getQueryVariable('controlsEnabled') !== 'false';
Config.controlsEnabled = controlsEnabled;
appContainer.bind(Types.VersionInfo).toConstantValue("0.20.14");
appContainer.bind(Types.ApiURL).toConstantValue("https://api.prod.hilo.tgaming.io");
appContainer.bind(Types.Translations).toConstantValue(new Translations(lang, {
  en: {
    translation: translationsEn
  },
  pt: {
    translation: translationsPt
  }
}));
appContainer.bind(Components.PreloadConfig).toConstantValue(PreloaderConfig);
appContainer.bind(Components.AssetConfig).toConstantValue(HiLoAssetConfig);
appContainer.bind(Types.LoggingAdapter).to(RollbarLoggingAdapter).inSingletonScope();
appContainer.bind(Types.GameModel).to(GameModel).inSingletonScope();
appContainer.bind(Types.EventBus).to(EventBus).inSingletonScope();
appContainer.bind(Services.Client).toConstantValue(new HiLoClientService(token, operatorId, gameId));
appContainer.bind(Types.AudioAdapter).to(HowlerAdapter).inSingletonScope();
appContainer.bind(Builders.Component).to(ContainerBuilder).whenTargetNamed(ComponentType.Container);
appContainer.bind(Builders.Component).to(GridContainerBuilder).whenTargetNamed(HiLoComponentType.GridContainer);
appContainer.bind(Builders.Component).to(NumberSpinnerBuilder).whenTargetNamed(HiLoComponentType.NumberSpinner);
appContainer.bind(Builders.Component).to(TextBuilder).whenTargetNamed(ComponentType.Text);
appContainer.bind(Builders.Component).to(SpriteBuilder).whenTargetNamed(ComponentType.Sprite);
appContainer.bind(Builders.Component).to(SpriteAnimationBuilder).whenTargetNamed(ComponentType.SpriteAnimation);
appContainer.bind(Builders.Component).to(ButtonBuilder).whenTargetNamed(ComponentType.Button);
appContainer.bind(Builders.Component).to(ButtonBuilder).whenTargetNamed(ComponentType.ToggleButton);
appContainer.bind(Builders.Component).to(ProgressBarBuilder).whenTargetNamed(ComponentType.ProgressBar);
appContainer.bind(Builders.Component).to(NineSliceSpriteBuilder).whenTargetNamed(ComponentType.NineSliceSprite);
appContainer.bind(Builders.Component).to(LoadingBarBuilder).whenTargetNamed(ComponentType.LoadingBar);
appContainer.bind(Builders.Component).to(NineSliceButtonBuilder).whenTargetNamed(ComponentType.NineSliceButton);
appContainer.bind(Factories.ComponentBuilder).toFactory(function (context) {
  return function (name) {
    return context.container.getNamed(Builders.Component, name);
  };
});
appContainer.bind(Controllers.Preload).to(PreloadController).inSingletonScope();
appContainer.bind(Controllers.UI).to(UIController).inSingletonScope();
appContainer.bind(Controllers.Choice).to(ChoiceController).inSingletonScope();
appContainer.bind(Controllers.LastNumbers).to(LastNumbersController).inSingletonScope();
appContainer.bind(Controllers.BackgroundAnimation).to(BackgroundAnimController).inSingletonScope();
appContainer.bind(Controllers.TimerAnimation).to(TimerAnimController).inSingletonScope();
appContainer.bind(Controllers.Sound).to(SoundController).inSingletonScope();
appContainer.bind(Controllers.Leaderboard).to(LeaderboardModeController).inSingletonScope();
appContainer.bind(Controllers.RoundProgress).to(RoundProgressController).inSingletonScope();
appContainer.bind(Controllers.ClientAPI).to(ClientApiController).inSingletonScope();
appContainer.bind(Controllers.BackgroundMusic).to(BackgroundMusicController).inSingletonScope();
appContainer.bind(Types.LayoutManager).to(LayoutManager).inSingletonScope();
appContainer.bind(Builders.Game).to(GameBuilder).inSingletonScope();
var container = document.querySelector("#app");
var width = document.documentElement.clientWidth;
var height = document.documentElement.clientHeight;
settings.ROUND_PIXELS = true;
settings.SCALE_MODE = SCALE_MODES.LINEAR;
var application = new Application({
  width: width,
  height: height,
  antialias: true,
  backgroundColor: 0x181637,
  sharedTicker: true,
  resolution: window.devicePixelRatio,
  autoDensity: true,
  resizeTo: container
});
appContainer.bind(Types.Application).toConstantValue(application);
container.insertBefore(application.renderer.view, container.firstChild);

function initGame() {
  var game = new HiLoGame(HiLoConfig, width, height);
  game.load();
}

var fontNames = ["Oxygen:700", "Roboto:700"];

function loadFonts() {
  WebFont.load({
    active: function active() {
      setTimeout(function () {
        initGame();
      }, 500);
    },
    inactive: initGame,
    fontloading: function fontloading(familyName) {
      // Create a hidden element using the font, to ensure the font is available in pixi.
      var el = document.createElement("p");
      el.style.fontFamily = familyName;
      el.style.fontSize = "0px";
      el.style.visibility = "hidden";
      el.innerHTML = ".";
      document.body.appendChild(el);
    },
    google: {
      families: fontNames
    }
  });
}

window.onload = loadFonts;
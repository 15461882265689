import { injectable } from "inversify";
import { AnimatedSprite, Ticker } from "pixi.js";
import { Components } from "../types/Components";
import { Direction, GameModel } from "../models";
import {
	EventBus,
	FixedDimensions,
	GraphicsUtils,
	LayoutContainer,
	lazyInject,
	PixiTween,
	Types
} from "@tournament/ui-core";
import { HiLoEvents } from "../events";

@injectable()
export abstract class AbstractBackgroundAnimController {
	@lazyInject(Types.EventBus)
	protected eventBus!: EventBus;

	@lazyInject(Types.GameModel)
	protected model!: GameModel;

	@lazyInject(Components.BackgroundAnimContainer)
	protected animContainer!: LayoutContainer;

	@lazyInject(Components.GameContainer)
	protected gameContainer!: LayoutContainer;

	@lazyInject(Components.PrizeWinDialog)
	protected prizeWinDialog!: LayoutContainer;

	@lazyInject(Components.WinDialogAnimContainer)
	protected winDialogAnimContainer!: LayoutContainer;

	@lazyInject(Components.BackgroundContainer)
	protected backgroundContainer!: LayoutContainer;

	protected animations: Array<PixiTween> = [];

	protected isObserving: boolean = false;

	protected backgroundAnimation: AnimatedSprite | undefined;

	public constructor() {
		this.eventBus.on(HiLoEvents.Game.Reset, this.reset, this);

		this.eventBus.once(HiLoEvents.Game.SetUserChoice, this.setUserChoice, this);

		this.eventBus.on(HiLoEvents.Game.HigherAccepted, () => {
			this.onChooseHigher();
		});

		this.eventBus.on(HiLoEvents.Game.LowerAccepted, () => {
			this.onChooseLower();
		});

		this.eventBus.on(HiLoEvents.Game.ChoiceFailed, this.onChoiceFailed, this);

		this.eventBus.once(HiLoEvents.User.ObserveGame, this.onObserveGame, this);

		this.eventBus.on(HiLoEvents.Game.SpinComplete, this.returnToOriginalColors, this);

		this.eventBus.once(
			HiLoEvents.Game.GameOver,
			() => {
				setTimeout(() => {
					this.onGameOver();
				}, 800);
			},
			this
		);

		this.eventBus.once(
			HiLoEvents.Game.PrizeWin,
			() => {
				setTimeout(() => {
					this.onGameOver();
				}, 800);
			},
			this
		);

		Ticker.shared.add(this.update, this);
	}

	protected onObserveGame(): void {
		this.isObserving = true;
		this.clearBackgroundAnimations();

		this.eventBus.once(
			HiLoEvents.Game.ObserveGameOver,
			() => {
				setTimeout(() => {
					this.onGameOver();
				}, 800);
			},
			this
		);

		this.eventBus.once(
			HiLoEvents.Game.ObserveGameWin,
			() => {
				setTimeout(() => {
					this.onGameOver();
				}, 800);
			},
			this
		);

		this.playGameOverAnimation();
	}

	protected reset(): void {
		this.eventBus.once(
			HiLoEvents.Game.GameOver,
			() => {
				setTimeout(() => {
					this.onGameOver();
				}, 800);
			},
			this
		);

		this.eventBus.once(
			HiLoEvents.Game.PrizeWin,
			() => {
				setTimeout(() => {
					this.onGameOver();
				}, 800);
			},
			this
		);

		this.isObserving = false;
	}

	protected animatePrizeWinGlow(): void {
		this.backgroundAnimation = new AnimatedSprite(GraphicsUtils.createTextureSequence("Shine", 180));
		this.backgroundAnimation.alpha = 0.7;
		const ratio: number = this.backgroundAnimation.width / this.backgroundAnimation.height;

		this.backgroundAnimation.width = this.animContainer.width;
		this.backgroundAnimation.height = this.backgroundAnimation.width / ratio;

		this.backgroundAnimation.x = 0;
		this.backgroundAnimation.y = (this.animContainer.height - this.backgroundAnimation.height) / 2;

		this.backgroundAnimation.loop = true;

		this.winDialogAnimContainer.addChild(this.backgroundAnimation);
		this.winDialogAnimContainer.onResize = (dimensions: FixedDimensions): void => {
			if (this.backgroundAnimation) {
				const ratio: number = this.backgroundAnimation.width / this.backgroundAnimation.height;

				this.backgroundAnimation.width = this.animContainer.width;
				this.backgroundAnimation.height = this.backgroundAnimation.width / ratio;

				this.backgroundAnimation.x = 0;
				this.backgroundAnimation.y = (this.animContainer.height - this.backgroundAnimation.height) / 2;
			}
		};
		this.backgroundAnimation.play();
	}

	protected clearCurrentAnimations(): void {
		for (const animation of this.animations) {
			animation.removeAllListeners();
			animation.stop();
		}

		this.animations.length = 0;
	}

	protected onAnimationComplete(animation: PixiTween): void {
		this.animations.splice(this.animations.indexOf(animation), 1);
	}

	protected update(deltaFrame: number): void {
		for (const animation of this.animations) {
			animation.update(deltaFrame);
		}
	}

	protected abstract setUserChoice(direction: Direction): void;

	protected abstract playGameOverAnimation(): void;

	protected abstract clearBackgroundAnimations(): void;

	protected abstract onGameOver(): void;

	protected abstract onChooseHigher(): void;

	protected abstract onChooseLower(): void;

	protected abstract returnToOriginalColors(): void;

	protected abstract onChoiceFailed(): void;
}

import { Ease } from "./Ease";

export class Bounce {
	public static easeIn: Ease = (time: number, from: number, to: number, duration: number): number => {
		if (time >= duration) {
			return to;
		}

		return to - from - Bounce.easeOut(duration - time, 0, to - from, duration) + from;
	};

	public static easeOut: Ease = (time: number, from: number, to: number, duration: number): number => {
		if (time >= duration) {
			return to;
		}

		if ((time /= duration) < 1 / 2.75) {
			return (to - from) * (7.5625 * time * time) + from;
		} else if (time < 2 / 2.75) {
			return (to - from) * (7.5625 * (time -= 1.5 / 2.75) * time + 0.75) + from;
		} else if (time < 2.5 / 2.75) {
			return (to - from) * (7.5625 * (time -= 2.25 / 2.75) * time + 0.9375) + from;
		}

		return (to - from) * (7.5625 * (time -= 2.625 / 2.75) * time + 0.984375) + from;
	};

	public static easeInOut: Ease = (time: number, from: number, to: number, duration: number): number => {
		if (time >= duration) {
			return to;
		}

		if (time < duration / 2) {
			return Bounce.easeIn(time * 2, 0, to - from, duration) * 0.5 + from;
		}

		return Bounce.easeOut(time * 2 - duration, 0, to - from, duration) * 0.5 + (to - from) * 0.5 + from;
	};
}

import { injectable } from "inversify";
import { GameModel } from "../models/GameModel";
import { Components } from "../types/Components";
import { Graphics, Sprite, Texture, Ticker } from "pixi.js";
import {
	EventBus,
	Events,
	LayoutContainer,
	LayoutSprite,
	lazyInject,
	PixiTween,
	Rect,
	Sine,
	TextField,
	Translations,
	Tween,
	Services,
	Types
} from "@tournament/ui-core";
import { HiLoEvents } from "../events";
import { ClientService } from "../io";
@injectable()
export class GenericTimerAnimController {
	@lazyInject(Types.Translations)
	protected translations!: Translations;

	@lazyInject(Types.EventBus)
	protected eventBus!: EventBus;

	@lazyInject(Types.GameModel)
	protected model!: GameModel;

	@lazyInject(Components.TimerContainer)
	protected timerContainer!: LayoutContainer;

	@lazyInject(Components.TimerBackground)
	protected timerBackground!: LayoutSprite;

	@lazyInject(Components.TimerProgress)
	protected timerProgress!: LayoutSprite;

	@lazyInject(Components.TimerProgressCutoff)
	protected timerProgressCutoff!: LayoutSprite;

	@lazyInject(Components.TimerAnimContainer)
	protected animContainer!: LayoutContainer;

	@lazyInject(Components.NoMoreBetsText)
	protected noMoreBetsText!: TextField;

	@lazyInject(Components.GameStartingText)
	protected gameStartingText!: TextField;

	@lazyInject(Components.GameStartCountDownText)
	protected gameStartCountDownText!: TextField;

	@lazyInject(Services.Client)
	protected clientService!: ClientService;

	protected progressMask: Graphics = new Graphics();
	protected cutoffMask: Graphics = new Graphics();

	protected animations: Array<Tween | PixiTween> = [];

	protected anticipationAnimations: Array<Tween | PixiTween> = [];

	protected hasStarted: boolean = false;

	protected hasFinished: boolean = false;

	protected timerRipple1: Sprite;
	protected timerRipple2: Sprite;

	public constructor() {
		const ripple: Texture = Texture.from("timer-ripple");
		this.timerRipple1 = new Sprite(ripple);
		this.timerRipple2 = new Sprite(ripple);
		this.timerRipple1.alpha = this.timerRipple2.alpha = 0;
		this.animContainer.addChild(this.timerRipple1, this.timerRipple2);
		this.eventBus.once(HiLoEvents.Game.RoundUpdated, this.onRoundUpdated, this);

		this.eventBus.on(HiLoEvents.Game.Reset, this.reset, this);

		this.eventBus.on(HiLoEvents.Game.GameOver, this.onGameOver, this);
		this.eventBus.on(HiLoEvents.Game.PrizeWin, this.onGameOver, this);
		this.eventBus.on(HiLoEvents.Game.ObserveGameWin, this.onGameOver, this);
		this.eventBus.on(HiLoEvents.Game.ObserveGameOver, this.onGameOver, this);

		this.eventBus.on(HiLoEvents.Game.StartSpin, this.startAnticipationAnimations, this);
		this.eventBus.on(HiLoEvents.Game.SpinComplete, this.stopAnticipationAnimations, this);
		this.eventBus.on(HiLoEvents.Game.Reset, this.stopAnticipationAnimations, this);

		this.eventBus.on(HiLoEvents.User.ObserveGame, this.onObserveGame, this);

		this.eventBus.once(HiLoEvents.Game.Init, this.initTimer, this);
		// this.eventBus.on(HiLoEvents.User.ExitSpectatorMode, this.exitSpectatorMode, this);
		this.gameStartingText.setText(this.translations.get("gameStart"));
		this.setProgress(0);
		if (this.clientService.getIsSpectatorMode()) {
			Ticker.shared.add(this.updateSpectatorMode, this);
		}
		else {
			Ticker.shared.add(this.update, this);
		}
	}

	protected exitSpectatorMode():void{
		Ticker.shared.remove(this.updateSpectatorMode, this);
		Ticker.shared.add(this.update, this);
	}

	protected reset(): void {
		this.hasStarted = false;
		this.hasFinished = false;
		this.stopAnticipationAnimations();
		this.timerContainer.visible = true;
		this.timerContainer.alpha = 1;
		this.noMoreBetsText.visible = false;
		this.noMoreBetsText.setText("");
		this.gameStartingText.visible = true;
		this.gameStartingText.setText(this.translations.get("gameStart"));
		this.setProgress(0);
		this.eventBus.once(HiLoEvents.Game.Init, this.initTimer, this);
	}

	protected initTimer(): void {
		if (this.model.currentNumber != undefined && this.model.roundNumber != undefined) {
			this.onRoundUpdated();
		}
	}

	protected onObserveGame(): void {
		this.hasFinished = false;
		this.timerContainer.visible = true;
		this.timerContainer.alpha = 1;
		this.stopAnticipationAnimations();
	}

	protected startAnticipationAnimations(): void {
		this.startTimerRipple(this.timerRipple1, 0);
		this.startTimerRipple(this.timerRipple2, 14);
	}

	protected stopAnticipationAnimations(): void {
		for (const animation of this.anticipationAnimations) {
			animation.removeAllListeners();
			animation.stop();
		}

		this.anticipationAnimations.length = 0;

		this.timerRipple1.alpha = this.timerRipple2.alpha = 0;
	}

	protected startTimerRipple(target: Sprite, delay: number): void {
		const tweenDuration: number = 20;
		const repeatDuration: number = 56;

		const tweenValue: { value: number } = { value: 0 };
		const tween: Tween = new Tween(tweenValue, "value", this)
			.delay(delay)
			.fromTo(0, 1, tweenDuration, Sine.easeOut)
			.delay(repeatDuration - (delay + tweenDuration))
			.repeat(-1);
		const startAlpha: number = 0.3;

		tween.on(Events.Animation.Update, () => {
			this.updateTimerRipple(target, 0.8 + tweenValue.value, startAlpha - startAlpha * tweenValue.value);
		});
		tween.once(Events.Animation.Complete, () => {
			this.updateTimerRipple(target, 0.8 + tweenValue.value, 0);
			tween.removeAllListeners();
		});
		tween.play();

		this.anticipationAnimations.push(tween);
	}

	protected updateTimerRipple(target: Sprite, scale: number, alpha: number): void {
		const dimensions: Rect | undefined = this.timerProgress.getLayoutDimensions();

		if (dimensions) {
			target.width = dimensions.width * scale;
			target.height = dimensions.height * scale;
			target.alpha = alpha;
			target.x = this.timerProgress.worldTransform.tx + (dimensions.width - target.width) / 2;
			target.y = this.timerProgress.worldTransform.ty + (dimensions.height - target.height) / 2;
		}
	}
 
	protected onRoundUpdated(): void {
		this.hasStarted = true;
		this.noMoreBetsText.setText(this.translations.get("higherOrLowerQuestion"));
		this.noMoreBetsText.visible = true;
		this.gameStartingText.visible = false;
		this.gameStartCountDownText.visible = false;
	}

	protected setProgress(progress: number): void {
		if (progress === 0) {
			this.timerProgress.visible = false;
			this.timerProgressCutoff.visible = false;
		} else {
			this.timerProgress.visible = true;
			this.timerProgressCutoff.visible = true;
		}
		const mask: Graphics = this.progressMask;
		const cutoffMask: Graphics = this.cutoffMask;
		cutoffMask.clear();
		mask.clear();
		if (this.model.cutOffTime && this.model.roundDurationSeconds) {
			this.timerProgress.mask = mask;
			this.timerProgressCutoff.mask = cutoffMask;
			const radius: number = this.timerProgress.getWidth() / 2;
			const cx: number = this.timerProgress.worldTransform.tx + radius;
			const cy: number = this.timerProgress.worldTransform.ty + radius;
			const angle: number = (-0.5 + progress / 0.5) * Math.PI;

			const cutoffAngle: number = (1.5 - this.model.cutOffTime / this.model.roundDurationSeconds / 0.5) * Math.PI;

			if (angle < cutoffAngle) {
				mask.beginFill(0x000000);
				mask.moveTo(cx, cy);
				mask.arc(cx, cy, radius, angle, cutoffAngle);
				mask.endFill();
			}

			cutoffMask.beginFill(0x000000);
			cutoffMask.moveTo(cx, cy);
			cutoffMask.arc(cx, cy, radius, Math.max(angle, cutoffAngle), 1.5 * Math.PI);
			cutoffMask.endFill();
		}
	}

	protected update(deltaFrame: number): void {
		if (this.animations.length > 0) {
			for (const animation of this.animations) {
				animation.update(deltaFrame);
			}
		}

		if (this.anticipationAnimations.length > 0) {
			for (const anticipationAnimation of this.anticipationAnimations) {
				anticipationAnimation.update(deltaFrame);
			}
		}

		if (this.model.startTime && !this.hasFinished) {
			if (Date.now() < this.model.startTime && !this.hasStarted) {
				let secondsUntilStart: number = Math.max(Math.floor((this.model.startTime - Date.now()) / 1000), 0);

				const minutes: number = Math.floor(secondsUntilStart / 60);
				secondsUntilStart = secondsUntilStart % 60;

				let timeUntilStart: string = "";

				if (minutes < 10) {
					timeUntilStart += "0";
				}

				timeUntilStart += minutes + ":";

				if (secondsUntilStart < 10) {
					timeUntilStart += "0";
				}

				timeUntilStart += secondsUntilStart;

				this.gameStartCountDownText.setText(timeUntilStart);
				this.gameStartingText.visible = true;
				this.gameStartCountDownText.visible = true;
				this.gameStartCountDownText.resize(this.gameStartCountDownText.getParentDimensions());
			} else if (!this.hasStarted) {
				this.gameStartCountDownText.visible = false;
				this.gameStartingText.setText(this.translations.get("gameStarting"));
			}

			if (
				this.hasStarted &&
				!this.hasFinished &&
				this.model.nextRoundStartTime &&
				this.model.nextRoundStartTime != this.model.startTime &&
				this.model.roundDurationSeconds &&
				this.model.cutOffTime
			) {
				const millisecondsToNextRound: number = Math.max(this.model.nextRoundStartTime - Date.now(), 0);
				const roundDurationMs: number = this.model.roundDurationSeconds * 1000;

				this.setProgress(Math.max(0, (roundDurationMs - millisecondsToNextRound) / roundDurationMs));
			}
		}
	}

	protected updateSpectatorMode(deltaFrame: number): void {
		if (this.animations.length > 0) {
			for (const animation of this.animations) {
				animation.update(deltaFrame);
			}
		}

		if (this.anticipationAnimations.length > 0) {
			for (const anticipationAnimation of this.anticipationAnimations) {
				anticipationAnimation.update(deltaFrame);
			}
		}
		if (this.model.roundDurationSeconds && this.model.nextRoundStartTime) {
			const millisecondsToNextRound: number = Math.max(this.model.nextRoundStartTime - Date.now(), 0);
			const roundDurationMs: number = this.model.roundDurationSeconds * 1000;
			this.setProgress(Math.max(0, (roundDurationMs - millisecondsToNextRound) / roundDurationMs));
		}


	}

	protected onGameOver(): void {
		this.hasFinished = true;
		this.stopAnticipationAnimations();
	}
}

import { injectable } from "inversify";
import { GridContainer } from "../GridContainer";
import { GridContainerConfig } from "../config/GridContainerConfig";
import { appContainer, ComponentBuilder, LayoutComponent, LayoutContainer } from "@tournament/ui-core";

@injectable()
export class GridContainerBuilder implements ComponentBuilder {
	public build(
		config: GridContainerConfig<any, any>,
		parentDimensions?: { width: number; height: number }
	): LayoutComponent {
		const container: GridContainer = new GridContainer(config.layout, config.options);

		this.setContainerProps(config, container);

		return container;
	}

	protected setContainerProps(config: GridContainerConfig<any, any>, container: LayoutContainer): void {
		if (config.background != undefined) {
			container.setBackground(config.background);
		}

		if (config.visible === false) {
			container.visible = false;
		}

		this.createBindings(config.id, container);
	}

	protected createBindings(id: string | undefined, container: LayoutContainer): void {
		if (id != undefined) {
			container.name = id;
			appContainer.bind<LayoutContainer>(id).toConstantValue(container);
		}
	}
}

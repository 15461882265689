import { injectable } from "inversify";
import { ComponentBuilder } from "./ComponentBuilder";
import { LayoutComponent } from "../LayoutComponent";
import { appContainer } from "../../ioc";
import { ProgressBar } from "../ProgressBar";
import { ProgressBarConfig } from "../config/ProgressBarConfig";

@injectable()
/**
 * The ProgressBarBuilder creates progress bar components used for displaying any type of progress from loading bars to
 * round progress in the HiLo games.
 *
 * This was intended to be further modified to include the functionality of and replace the LoadingBar component.
 *
 * @see ProgressBar
 * @see ProgressBarConfig
 */
export class ProgressBarBuilder implements ComponentBuilder {
	public build(config: ProgressBarConfig<any>, parentDimensions?: { width: number; height: number }): LayoutComponent {
		const progressBar: ProgressBar = new ProgressBar(config.layout, config);

		if (config.background != undefined) {
			progressBar.setBackground(config.background);
		}

		if (config.visible === false) {
			progressBar.visible = false;
		}

		if (config.id != undefined) {
			progressBar.name = config.id;
			appContainer.bind<ProgressBar>(config.id).toConstantValue(progressBar);
		}

		return progressBar;
	}
}

import { Ease } from "./Ease";

export class Cubic {
	public static easeIn: Ease = (time: number, from: number, to: number, duration: number): number => {
		if (time >= duration) {
			return to;
		}

		return (to - from) * (time /= duration) * time * time + from;
	};

	public static easeOut: Ease = (time: number, from: number, to: number, duration: number): number => {
		if (time >= duration) {
			return to;
		}

		return (to - from) * ((time = time / duration - 1) * time * time + 1) + from;
	};

	public static easeInOut: Ease = (time: number, from: number, to: number, duration: number): number => {
		if (time >= duration) {
			return to;
		}

		if ((time /= duration / 2) < 1) {
			return ((to - from) / 2) * time * time * time + from;
		}

		return ((to - from) / 2) * ((time -= 2) * time * time + 2) + from;
	};
}

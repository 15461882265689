import { utils, Container, Graphics, Sprite, Ticker } from "pixi.js";
import { Ease, Linear } from "./easing";
import { Events } from "../events";

export enum TweenProperty {
	Alpha,
	Height,
	Rotation,
	Width,
	X,
	Y,
	Sprite,
	Scale,
	Tint
}

interface TweenDefinition {
	from: number;
	to: number;
	ease: Ease;
	easeStrength?: number;
}

interface TweenAction {
	duration: number;
	tween?: TweenDefinition;
}

interface ExecuteAction {
	execute: Function;
}

/**
 * The PixiTween class is a simple tween class based on the gsap tween classes using Robert Penner's easing functions.
 * It adds functionality specifically for working with pixi display children, and adds functionality for waiting between
 * actions, executing functions, etc.
 *
 * Future development was intended to combine this with the Tween class.
 */
export class PixiTween extends utils.EventEmitter {
	/**
	 * The targets to apply the tween to. Can be one or more objects, which allows for example synchronised scaling or
	 * fading of multiple objects.
	 */
	protected targets: Array<Container | Graphics>;

	/**
	 * The property that should be modified during the tween.
	 */
	protected prop: TweenProperty;

	/**
	 * The duration of the tween.
	 */
	protected duration: number = 0;

	/**
	 * The sequence of actions played within the tween.
	 */
	protected actions: Array<TweenAction | ExecuteAction> = [];

	/**
	 * The current tween action.
	 */
	protected currentAction: TweenAction | ExecuteAction | undefined;

	/**
	 * The number of times the tween should repeat before it automatically stops.
	 */
	protected numRepeats: number = 0;

	/**
	 * The total time in frames that the tween has been playing for.
	 */
	protected rawTime: number = 0;

	/**
	 * The current frame in the tween.
	 */
	protected currentFrame: number = 0;

	/**
	 * True if the tween is currently playing.
	 */
	protected _isPlaying: boolean = false;

	/**
	 * True if the tween is currently paused.
	 */
	protected _isPaused: boolean = false;

	/**
	 * The final value of the property after the tween completes.
	 */
	protected finalValue: number = 0;

	/**
	 * Small number used to account for IEEE-754 rounding errors.
	 */
	protected roundingAdjustment: number = 0.0005;

	/**
	 * The parent that controls the animation, if any. If an animation has no parent, it will handle its own updates
	 * when played. This should be set when multiple animations need to be synchronised together.
	 */
	protected _animationParent: any;

	public set animationParent(value: any) {
		if (this._isPlaying && !this._isPaused) {
			Ticker.shared.remove(this.update, this);
		}

		this.animationParent = value;
	}

	public get animationParent(): any {
		return this._animationParent;
	}

	public constructor(
		targets: Container | Graphics | Sprite | Array<Container | Graphics | Sprite>,
		prop: TweenProperty,
		animationParent?: any
	) {
		super();

		if (!Array.isArray(targets)) {
			targets = [targets];
		}

		this.targets = targets;
		this.prop = prop;
		this._animationParent = animationParent;
	}

	/**
	 * Tweens from the current value to the specified to value using Easing.
	 *
	 * @param to The value to tween to.
	 * @param duration The duration of the tween action.
	 * @param ease The ease function to use.
	 * @param easeStrength The strength of the ease. Note this is only applicable to a few easing functions!
	 */
	public to(to: number, duration: number, ease?: Ease, easeStrength?: number): PixiTween {
		return this.fromTo(this.getCurrentValue(), to, duration, ease, easeStrength);
	}

	/**
	 * Tweens from the specified from value to the specified to value.
	 *
	 * @param from The value to tween from.
	 * @param to The value to tween to.
	 * @param duration The duration of the tween action.
	 * @param ease The ease function to use.
	 * @param easeStrength The strength of the ease. Note this is only applicable to a few easing functions!
	 */
	public fromTo(from: number, to: number, duration: number, ease?: Ease, easeStrength?: number): PixiTween {
		this.actions.push({
			duration,
			tween: {
				from,
				to,
				ease: ease || Linear.easeNone,
				easeStrength
			}
		});

		this.finalValue = to;
		this.duration += duration;

		return this;
	}

	/**
	 * Adds a delay between animation sections.
	 *
	 * @param duration The duration of the delay in frames.
	 */
	public delay(duration: number): PixiTween {
		this.actions.push({
			duration
		});

		this.duration += duration;

		return this;
	}

	/**
	 * Executes the specified function when the tween reaches this action.
	 *
	 * @param fn The function to execute.
	 */
	public execute(fn: Function): PixiTween {
		this.actions.push({
			execute: fn
		});

		return this;
	}

	/**
	 * Sets the number of times the tween should repeat.
	 *
	 * @param times The number of times to repeat the tween before stopping automatically.
	 */
	public repeat(times: number): PixiTween {
		if (times < -1) {
			times = -1;
		}

		this.numRepeats = times;

		return this;
	}

	/**
	 * Plays the tween.
	 */
	public play(): void {
		this.rawTime = 0;
		this.currentFrame = 0;

		this.currentAction = this.actions[0];

		if (!this._isPlaying) {
			this._isPlaying = true;
			if (this.animationParent == undefined) {
				Ticker.shared.add(this.update, this);
			}
		} else if (this._isPaused) {
			this.resume();
		}
	}

	/**
	 * Pauses the tween while in progress.
	 *
	 * @see resume
	 */
	public pause(): void {
		if (!this._isPaused) {
			this._isPaused = true;

			if (this._isPlaying && this.animationParent == undefined) {
				Ticker.shared.remove(this.update, this);
			}
		}
	}

	/**
	 * Resumes a paused tween animation.
	 *
	 * @see pause
	 */
	public resume(): void {
		if (this._isPaused) {
			this._isPaused = false;

			if (this._isPlaying && this.animationParent == undefined) {
				Ticker.shared.add(this.update, this);
			}
		}
	}

	/**
	 * Stops the animation from playing and sets the tween to the final value immediately.
	 */
	public stop(): void {
		if (this._isPlaying) {
			if (this.animationParent == undefined) {
				Ticker.shared.remove(this.update, this);
			}

			this.setProperty(this.finalValue);

			this._isPlaying = false;
			this.currentAction = undefined;

			this.emit(Events.Animation.Complete, this);
		}
	}

	/**
	 * Skips to the specified frame and starts playing.
	 *
	 * @param frame The frame number to start playing from.
	 */
	public gotoAndPlay(frame: number): void {
		this.goto(frame);

		if (this.rawTime !== this.getTotalDuration()) {
			if (!this._isPlaying) {
				this.play();
			} else if (this._isPaused) {
				this.resume();
			}
		}
	}

	/**
	 * Skips to the specified frame and stops the animation.
	 *
	 * @param frame The frame number to skip to before stopping.
	 */
	public gotoAndStop(frame: number): void {
		this.goto(frame);

		if (this._isPlaying) {
			// Don't call stop directly here, as we want to stop on the specified frame, not the final frame.
			this._isPlaying = false;
			if (this.animationParent == undefined) {
				Ticker.shared.remove(this.update, this);
			}
		}
	}

	/**
	 * Returns true if the tween is currently playing.t
	 */
	public isPlaying(): boolean {
		return this._isPlaying;
	}

	/**
	 * Skips to the specified frame.
	 *
	 * @param time The frame to skip to.
	 */
	protected goto(time: number): void {
		if (time > this.getTotalDuration()) {
			time = this.getTotalDuration();
		}

		this.rawTime = time;

		this.updateCurrentAction();
	}

	// TODO: Cleanup
	/**
	 * Sets the properties of the targets to the specified value.
	 *
	 * @param value The value to set to.
	 */
	protected setProperty(value: number): void {
		switch (this.prop) {
			case TweenProperty.Alpha:
				for (const target of this.targets) {
					target.alpha = value;
				}
				break;
			case TweenProperty.Height:
				for (const target of this.targets) {
					target.height = value;
				}
				break;
			case TweenProperty.Rotation:
				for (const target of this.targets) {
					target.rotation = value;
				}
				break;
			case TweenProperty.Width:
				for (const target of this.targets) {
					target.width = value;
				}
				break;
			case TweenProperty.X:
				for (const target of this.targets) {
					target.x = value;
				}
				break;
			case TweenProperty.Y:
				for (const target of this.targets) {
					target.y = value;
				}
				break;
			case TweenProperty.Scale:
				for (const target of this.targets) {
					target.scale.x = value;
					target.scale.y = value;
				}
				break;
			case TweenProperty.Tint:
				for (const target of this.targets) {
					if (target instanceof Graphics) {
						target.tint = value;
					}
				}
				break;
			default:
				// Do nothing.
				break;
		}
	}

	/**
	 * Returns the total duration of the animation.
	 */
	protected getTotalDuration(): number {
		return this.numRepeats === -1 ? Number.MAX_SAFE_INTEGER : this.duration * (this.numRepeats + 1);
	}

	/**
	 * Returns the current value of the property being set by the tween.
	 */
	protected getCurrentValue(): number {
		switch (this.prop) {
			case TweenProperty.Alpha:
				return this.targets[0].alpha;
			case TweenProperty.Height:
				return this.targets[0].height;
			case TweenProperty.Rotation:
				return this.targets[0].rotation;
			case TweenProperty.Width:
				return this.targets[0].width;
			case TweenProperty.X:
				return this.targets[0].x;
			case TweenProperty.Y:
				return this.targets[0].y;
			case TweenProperty.Scale:
				return this.targets[0].scale.x;
			case TweenProperty.Tint:
				for (const target of this.targets) {
					if (target instanceof Graphics) {
						return target.tint;
						break;
					}
				}
				console.warn("Tweening tint is not supported for Containers.");
				return 0;
			default:
				console.warn("Attempted to get unknown property", this.prop);
				return 0;
		}
	}

	/**
	 * Updates the animation each frame.
	 *
	 * @param deltaFrame A delta that represents the difference between the number of frame that should have passed
	 * since the last time update was called.
	 */
	public update(deltaFrame: number): void {
		if (this._isPlaying && !this._isPaused) {
			this.rawTime += deltaFrame;

			if (this.rawTime + this.roundingAdjustment > this.getTotalDuration()) {
				this.rawTime = this.getTotalDuration();
				this.stop();
			} else {
				this.updateCurrentAction();
				this.emit(Events.Animation.Update);
			}
		}
	}

	protected updateCurrentAction(): void {
		let currentFrame: number = Math.floor(this.rawTime + this.roundingAdjustment) % this.duration;

		if (currentFrame !== this.currentFrame) {
			let startIndex: number = 0;
			this.currentAction = this.actions[0];
			if (this.currentAction && currentFrame > this.currentFrame) {
				startIndex = this.actions.indexOf(this.currentAction);
			}

			let tween: TweenDefinition | undefined;
			let currentValue: number | undefined;
			for (let i: number = startIndex; i < this.actions.length; i++) {
				// - 1 because we start from 0, and end at total duration - 1.
				if (
					(this.actions[i] as ExecuteAction).execute == undefined &&
					currentFrame < (this.actions[i] as TweenAction).duration - 1
				) {
					this.currentAction = this.actions[i];
					this.currentFrame = currentFrame;

					tween = (this.actions[i] as TweenAction).tween;

					if (tween != undefined) {
						if (this.prop === TweenProperty.Tint) {
							const red: number = Math.round(
								tween.ease.call(
									this,
									this.currentFrame,
									(tween.from >> 16) & 255,
									(tween.to >> 16) & 255,
									(this.actions[i] as TweenAction).duration,
									tween.easeStrength
								)
							);

							const green: number = Math.round(
								tween.ease.call(
									this,
									this.currentFrame,
									(tween.from >> 8) & 255,
									(tween.to >> 8) & 255,
									(this.actions[i] as TweenAction).duration,
									tween.easeStrength
								)
							);

							const blue: number = Math.round(
								tween.ease.call(
									this,
									this.currentFrame,
									tween.from & 255,
									tween.to & 255,
									(this.actions[i] as TweenAction).duration,
									tween.easeStrength
								)
							);

							currentValue = (red << 16) | (green << 8) | blue;
						} else {
							currentValue = tween.ease.call(
								this,
								this.currentFrame,
								tween.from,
								tween.to,
								(this.actions[i] as TweenAction).duration,
								tween.easeStrength
							);
						}
					}
					break;
				} else {
					if ((this.actions[i] as ExecuteAction).execute != undefined) {
						(this.actions[i] as ExecuteAction).execute.call(this);
					} else {
						currentFrame -= (this.actions[i] as TweenAction).duration;

						tween = (this.actions[i] as TweenAction).tween;
						if (tween != undefined) {
							currentValue = tween.to;
						}
					}
				}
			}

			if (currentValue != undefined) {
				this.setProperty(currentValue);
			}
		}
	}
}

import { Ease } from "./Ease";

export class Quadratic {
	public static easeIn: Ease = (time: number, from: number, to: number, duration: number): number => {
		if (time >= duration) {
			return to;
		}

		return (to - from) * (time /= duration) * time + from;
	};

	public static easeOut: Ease = (time: number, from: number, to: number, duration: number): number => {
		if (time >= duration) {
			return to;
		}

		return -(to - from) * (time /= duration) * (time - 2) + from;
	};

	public static easeInOut: Ease = (time: number, from: number, to: number, duration: number): number => {
		if (time >= duration) {
			return to;
		}

		return (-(to - from) / 2) * (--time * (time - 2) - 1) + from;
	};
}

import { ButtonConfig, ComponentType, HAlign, VAlign } from "@tournament/ui-core";
import { Components, HiLoComponentType, HiLoEvents } from "@tournament/hilo-ui-core";
import { SkinComponentType } from "../../types/SkinComponentType";

export const CloseButton: ButtonConfig<HiLoComponentType | SkinComponentType> = {
	id: Components.CloseButton,
	type: ComponentType.Button,
	textures: {
		up: "CloseButton-Up",
		over: "CloseButton-Over",
		down: "CloseButton-Down"
	},
	clickEvent: HiLoEvents.User.Close,
	layout: {
		vAlign: VAlign.Middle,
		hAlign: HAlign.Center,
		dimensions: { width: 100, height: 100, heightPercent: 80, widthPercent: 80 }
	}
};

export const translationsEn = {
	higher: "HI",
	lower: "LO",
	higherOrLowerQuestion: "Will the next number be\nHigher or Lower?",
	gameStart: "Game starts in",
	gameStarting: "Game Starting...",
	playersRegistered: "{{count}} player registered",
	playersRegistered_plural: "{{count}} players registered",
	noPlayersRemaining: "No players remaining",
	playersRemaining: "{{count}} player remaining",
	playersRemaining_plural: "{{count}} players remaining",
	roundTitle: "Round {{count}}",
	survivalRoundTitle: "Round {{count}} of {{total}}",
	survivalTitle: "{{count}} Round Survival",
	survivalDescription: "Survive to the end\nto get a slice of the prize.",
	millionaireTitle: "HiLo Millionaire",
	millionaireDescription:
		"Correctly choose Higher or Lower\nto fill the progress meter.\n\nWhen the meter is full\nYou can collect a prize\nOr continue and try to win more.",
	millionairePrizeDescription: "The maximum prize is",
	millionaireAlreadyPlayed: "You've already played today.",
	millionaireAlreadyPlayed_plural: "You've used all your lives today.",
	millionaireComeBackTomorrow: "Come back tomorrow\nfor another chance to win big prizes!",
	millionairePlayAgainTime: "You can play again in",
	millionaireYouWin: "You win {{amount}}!",
	millionaireNextPrize: "Next Prize",
	roundsRemaining: "{{count}} more round to the next prize...",
	roundsRemaining_plural: "{{count}} more rounds to the next prize...",
	millionaireNextPrizeIs: "The next prize is",
	millionaireDisclaimer: "Continue to give up this prize for the chance to win more.",
	continueLabel: "Continue",
	collectLabel: "Collect",
	lastManTitle: "Last Man Standing",
	lastManTitle_plural: "Last {{count}} Standing",
	lastManDescription: "Last person standing\ntakes home the prize.",
	lastManDescription_plural: "Last {{count}} standing\ntake home a slice of the prize.",
	leaderboardTitle: "Hi-Lo Leaderboard",
	leaderboardDescription:
		"You have {{entries}} lives to build your high score.\nCorrectly choose HI or LO to win {{count}} point.\nLosing uses one of your lives.",
	leaderboardDescription_plural:
		"You have {{entries}} lives to build your high score.\nCorrectly choose HI or LO to win {{count}} points.\nLosing uses one of your lives.",
	leaderboardDescription2:
		"When the game ends, the players with the highest\nscores will win the prizes shown below",
	leaderboardDescription3:
		"When two or more players have identical high scores,\nthe player that made their choice fastest wins.",
	leaderboardDescription4: "Choose quickly to increase your chance of winning!",
	leaderBoardChancesRemaining: "This is your final life remaining to increase your score!",
	leaderBoardChancesRemaining_plural: "You have {{count}} lives remaining to increase your score.",
	leaderboardPosition: "You are {{count}} out of {{total}} players",
	tournamentEnded: "Game Finished",
	finalRoundMessage: "Final\nRound",
	congratsLeaderboard: "Congratulations to our winners!",
	finalRanking: "You finished {{rank}} out of {{total}} players.",
	numberRangeMessage: "Numbers range from\n{{min}} to {{max}}",
	noMoreTries: "Game Over",
	tournamentEnds: "Game ends in",
	yourPosition: "Your current ranking",
	soundTitle: "Enable Sound",
	soundDescription: "Would you like to enable sound?",
	gameOver: "Game Over",
	playAgainMessage: "You have one final life remaining\nto improve your score.\nWould you like to play again?",
	playAgainMessage_plural: "You have {{count}} more lives remaining\nto improve your score.\nWould you like to play again?",
	noTriesLeft: "No more lives remaining today.",
	youWin: "You Win",
	betterLuckNextTime: "Better luck next time",
	knockedOut: "All players knocked out",
	congratulations: "Congratulations",
	toOurWinners: "to our winners",
	andXMore: "..and {{count}} more player",
	andXMore_plural: "..and {{count}} more players",
	yesLabel: "Yes",
	noLabel: "No",
	confirmLabel: "OK",
	stayAndWatch: "Stay and Watch",
	playAgainLabel: "Play Again",
	returnToLobby: "Return To Lobby",
	errorTitle: "Error",
	loadingErrorMessage: "There was a problem starting the game.\nIf this issue persists, please contact support.",
	genericErrorMessage: "There was an unexpected error.\nIf this issue persists, please contact support.",
	finishedErrorMessage: "This game has already finished.\nPlease pick a new game from the lobby.",
	startedErrorMessage: "You just missed the start of this game.\nPlease pick a new game from the lobby.",
	fullErrorMessage: "Unfortunately this game is already full.\nPlease pick a new game from the lobby.",
	tournamentEndingMessage: "The game is just about to finish.\nPlease pick a new game from the lobby."
};

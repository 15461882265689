import { Ease } from "./Ease";

export class Exponential {
	public static easeIn: Ease = (time: number, from: number, to: number, duration: number): number => {
		if (time >= duration) {
			return to;
		}

		return time == 0 ? from : (to - from) * Math.pow(2, 10 * (time / duration - 1)) + from;
	};

	public static easeOut: Ease = (time: number, from: number, to: number, duration: number): number => {
		if (time >= duration) {
			return to;
		}

		return time == duration ? from + (to - from) : (to - from) * (-Math.pow(2, (-10 * time) / duration) + 1) + from;
	};

	public static easeInOut: Ease = (time: number, from: number, to: number, duration: number): number => {
		if (time >= duration) {
			return to;
		}

		if (time == 0) {
			return from;
		}

		if (time == duration) {
			return from + (to - from);
		}

		if ((time /= duration / 2) < 1) {
			return ((to - from) / 2) * Math.pow(2, 10 * (time - 1)) + from;
		}

		return ((to - from) / 2) * (-Math.pow(2, -10 * --time) + 2) + from;
	};
}

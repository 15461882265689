import { injectable } from "inversify";
import { appContainer, ComponentBuilder, LayoutComponent, LayoutContainer } from "@tournament/ui-core";
import { NumberSpinnerConfig } from "../config/NumberSpinnerConfig";
import {NumberSpinner} from "../NumberSpinner";

@injectable()
export class NumberSpinnerBuilder implements ComponentBuilder {
	// TODO: Look at improving the config so builders for things like this aren't necessary.
	public build(
		config: NumberSpinnerConfig<any>,
		parentDimensions?: { width: number; height: number }
	): LayoutComponent {
		const spinner: NumberSpinner = new NumberSpinner(config.layout);
		if (config.background != undefined) {
			spinner.setBackground(config.background);
		}

		if (config.visible === false) {
			spinner.visible = false;
		}

		if (config.id != undefined) {
			spinner.name = config.id;
			appContainer.bind<LayoutContainer>(config.id).toConstantValue(spinner);
		}

		return spinner;
	}
}

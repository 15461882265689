import { Ease } from "./Ease";

export class Elastic {
	public static easeIn: Ease = (
		time: number,
		from: number,
		to: number,
		duration: number,
		strength: number = 1.70158
	): number => {
		if (time >= duration) {
			return to;
		}

		const p = duration * 0.3;
		let a = to - from;

		if (time == 0) {
			return from;
		}

		if ((time /= duration) == 1) {
			return from + (to - from);
		}

		if (a < Math.abs(to - from)) {
			a = to - from;
			strength = p / 4;
		} else {
			strength = (p / (2 * Math.PI)) * Math.asin((to - from) / a);
		}

		return -(a * Math.pow(2, 10 * time--) * Math.sin(((time * duration - strength) * (2 * Math.PI)) / p)) + from;
	};

	public static easeOut: Ease = (
		time: number,
		from: number,
		to: number,
		duration: number,
		strength: number = 1.70158
	): number => {
		if (time >= duration) {
			return to;
		}

		const p = duration * 0.3;
		let a = to - from;

		if (time == 0) {
			return from;
		}

		if ((time /= duration) == 1) {
			return from + (to - from);
		}

		if (a < Math.abs(to - from)) {
			a = to - from;
			strength = p / 4;
		} else {
			strength = (p / (2 * Math.PI)) * Math.asin((to - from) / a);
		}

		return (
			a * Math.pow(2, -10 * time) * Math.sin(((time * duration - strength) * (2 * Math.PI)) / p) +
			(to - from) +
			from
		);
	};

	public static easeInOut: Ease = (
		time: number,
		from: number,
		to: number,
		duration: number,
		strength: number = 1.70158
	): number => {
		if (time >= duration) {
			return to;
		}

		const p = duration * (0.3 * 1.5);
		let a = to - from;

		if (time == 0) {
			return from;
		}

		if ((time /= duration / 2) == 2) {
			return from + (to - from);
		}

		if (a < Math.abs(to - from)) {
			a = to - from;
			strength = p / 4;
		} else {
			strength = (p / (2 * Math.PI)) * Math.asin((to - from) / a);
		}

		if (time < 1) {
			return (
				-0.5 *
					(a * Math.pow(2, 10 * (time -= 1)) * Math.sin(((time * duration - strength) * (2 * Math.PI)) / p)) +
				from
			);
		}

		return (
			a * Math.pow(2, -10 * (time -= 1)) * Math.sin(((time * duration - strength) * (2 * Math.PI)) / p) * 0.5 +
			(to - from) +
			from
		);
	};
}

import { ComponentType, HAlign, LayoutMode, NineSliceSpriteConfig, TextAlign, VAlign } from "@tournament/ui-core";
import { Components, HiLoComponentType } from "@tournament/hilo-ui-core";
import { SkinComponentType } from "../../types/SkinComponentType";
import { SkinDisplayChild } from "../../components/config/SkinDisplayChild";

export const LeaderboardGameInfoDialog: NineSliceSpriteConfig<SkinDisplayChild, HiLoComponentType | SkinComponentType> = {
	id: Components.LeaderboardGameInfoDialog,
	type: ComponentType.NineSliceSprite,
	slices: {
		texture: "GameOverDialog",
		leftWidth: 23,
		topHeight: 23,
		rightWidth: 23,
		bottomHeight: 23
	},
	visible: false,
	layout: {
		vAlign: VAlign.Middle,
		hAlign: HAlign.Center,
		dimensions: {
			width: 750,
			height: 600,
			widthPercent: 85,
			heightPercent: 60
		}
	},
	children: [
		{
			id: Components.LeaderboardGameTitle,
			type: ComponentType.Text,
			text: {
				id: "leaderboardTitle",
				values: { count: 1 }
			},
			style: {
				fontFamily: ["Oxygen", "sans-serif"],
				fontWeight: "700",
				fill: 0xffffff,
				align: TextAlign.Center
			},
			layout: {
				dimensions: {
					widthPercent: 90,
					heightPercent: 10
				},
				offset: {
					yPercent: 5
				},
				hAlign: HAlign.Center
			}
		},
		{
			id: Components.LeaderboardGameDescription,
			type: ComponentType.Text,
			text: {
				id: "leaderboardDescription",
				values: { count: 6 }
			},
			style: {
				fontFamily: ["Oxygen", "sans-serif"],
				fontWeight: "700",
				fill: 0xffffff,
				align: TextAlign.Center
			},
			layout: {
				dimensions: {
					widthPercent: 90,
					heightPercent: 13.5
				},
				offset: {
					yPercent: 15
				},
				hAlign: HAlign.Center
			}
		},
		{
			type: ComponentType.Text,
			text: {
				id: "leaderboardDescription2",
				values: { count: 1 }
			},
			style: {
				fontFamily: ["Oxygen", "sans-serif"],
				fontWeight: "700",
				fill: 0xffffff,
				align: TextAlign.Center
			},
			layout: {
				dimensions: {
					widthPercent: 90,
					heightPercent: 9
				},
				offset: {
					yPercent: 30
				},
				hAlign: HAlign.Center
			}
		},
		{
			type: ComponentType.Text,
			text: {
				id: "leaderboardDescription3",
				values: { count: 1 }
			},
			style: {
				fontFamily: ["Oxygen", "sans-serif"],
				fontWeight: "700",
				fill: 0xffffff,
				align: TextAlign.Center
			},
			layout: {
				dimensions: {
					widthPercent: 90,
					heightPercent: 9
				},
				offset: {
					yPercent: 74
				},
				hAlign: HAlign.Center
			}
		},
		{
			type: ComponentType.Text,
			text: {
				id: "leaderboardDescription4",
				values: { count: 1 }
			},
			style: {
				fontFamily: ["Oxygen", "sans-serif"],
				fontWeight: "700",
				fill: 0xffffff,
				align: TextAlign.Center
			},
			layout: {
				dimensions: {
					widthPercent: 90,
					heightPercent: 4.5
				},
				offset: {
					yPercent: 84
				},
				hAlign: HAlign.Center
			}
		},
		{
			type: ComponentType.Container,
			layout: {
				dimensions: {
					widthPercent: 80,
					heightPercent: 30
				},
				offset: {
					yPercent: 41
				},
				hAlign: HAlign.Center,
				childLayout: {
					mode: LayoutMode.Vertical,
					hAlign: HAlign.Center,
					vAlign: VAlign.Middle
				}
			},
			children: [
				{
					type: ComponentType.NineSliceSprite,
					slices: {
						texture: "LeaderBoard_Background_1",
						leftWidth: 9,
						topHeight: 9,
						rightWidth: 9,
						bottomHeight: 9
					},
					layout: {
						dimensions: {
							widthPercent: 100,
							heightPercent: 35
						}
					},
					children: [
						{
							type: ComponentType.Text,
							text: "1ST POSITION",
							style: {
								fontFamily: ["Oxygen", "sans-serif"],
								fontWeight: "700",
								fill: 0xf5f0fb,
								align: TextAlign.Left
							},
							layout: {
								dimensions: {
									widthPercent: 50,
									heightPercent: 60
								},
								vAlign: VAlign.Middle,
								hAlign: HAlign.Left,
								offset: {
									xPercent: 2
								}
							}
						},
						{
							id: Components.LeaderBoardInfoPrizeValue1,
							type: ComponentType.Text,
							text: "",
							style: {
								fontFamily: ["Oxygen", "sans-serif"],
								fontWeight: "700",
								fill: 0xf5f0fb,
								align: TextAlign.Right
							},
							layout: {
								dimensions: {
									widthPercent: 50,
									heightPercent: 60
								},
								vAlign: VAlign.Middle,
								hAlign: HAlign.Right,
								offset: {
									xPercent: -2
								}
							}
						}
					]
				},
				{
					type: ComponentType.NineSliceSprite,
					slices: {
						texture: "LeaderBoard_Background_2",
						leftWidth: 9,
						topHeight: 9,
						rightWidth: 9,
						bottomHeight: 9
					},
					layout: {
						dimensions: {
							widthPercent: 98,
							heightPercent: 33
						}
					},
					children: [
						{
							type: ComponentType.Text,
							text: "2ND POSITION",
							style: {
								fontFamily: ["Oxygen", "sans-serif"],
								fontWeight: "700",
								fill: 0xf5f0fb,
								align: TextAlign.Left
							},
							layout: {
								dimensions: {
									widthPercent: 50,
									heightPercent: 60
								},
								vAlign: VAlign.Middle,
								hAlign: HAlign.Left,
								offset: {
									xPercent: 2
								}
							}
						},
						{
							id: Components.LeaderBoardInfoPrizeValue2,
							type: ComponentType.Text,
							text: "",
							style: {
								fontFamily: ["Oxygen", "sans-serif"],
								fontWeight: "700",
								fill: 0xf5f0fb,
								align: TextAlign.Right
							},
							layout: {
								dimensions: {
									widthPercent: 50,
									heightPercent: 60
								},
								vAlign: VAlign.Middle,
								hAlign: HAlign.Right,
								offset: {
									xPercent: -2
								}
							}
						}
					]
				},
				{
					type: ComponentType.NineSliceSprite,
					slices: {
						texture: "LeaderBoard_Background_3",
						leftWidth: 9,
						topHeight: 9,
						rightWidth: 9,
						bottomHeight: 9
					},
					layout: {
						dimensions: {
							widthPercent: 96,
							heightPercent: 30
						}
					},
					children: [
						{
							type: ComponentType.Text,
							text: "3RD POSITION",
							style: {
								fontFamily: ["Oxygen", "sans-serif"],
								fontWeight: "700",
								fill: 0xf5f0fb,
								align: TextAlign.Left
							},
							layout: {
								dimensions: {
									widthPercent: 50,
									heightPercent: 60
								},
								vAlign: VAlign.Middle,
								hAlign: HAlign.Left,
								offset: {
									xPercent: 2
								}
							}
						},
						{
							id: Components.LeaderBoardInfoPrizeValue3,
							type: ComponentType.Text,
							text: "",
							style: {
								fontFamily: ["Oxygen", "sans-serif"],
								fontWeight: "700",
								fill: 0xf5f0fb,
								align: TextAlign.Right
							},
							layout: {
								dimensions: {
									widthPercent: 50,
									heightPercent: 60
								},
								vAlign: VAlign.Middle,
								hAlign: HAlign.Right,
								offset: {
									xPercent: -2
								}
							}
						}
					]
				}
			]
		},
		{
			type: ComponentType.Container,
			layout: {
				dimensions: {
					widthPercent: 100,
					heightPercent: 10
				},
				offset: {
					yPercent: 3
				},
				hAlign: HAlign.Center,
				vAlign: VAlign.Bottom,
				childLayout: {
					mode: LayoutMode.Horizontal,
					padding: 5,
					vAlign: VAlign.Middle,
					hAlign: HAlign.Center
				}
			},
			children: [
				{
					id: Components.LeaderboardGameInfoConfirm,
					type: ComponentType.NineSliceButton,
					textures: {
						up: "GameOverDialogButton",
						over: "GameOverDialogButton",
						down: "GameOverDialogButton",
						disabled: "GameOverDialogButton"
					},
					slices: {
						top: 10,
						left: 10,
						right: 10,
						bottom: 10
					},
					layout: {
						dimensions: {
							widthPercent: 22,
							heightPercent: 100
						}
					},
					children: [
						{
							type: ComponentType.Text,
							text: {
								id: "confirmLabel"
							},
							style: {
								fontFamily: ["Oxygen", "sans-serif"],
								fontWeight: "700",
								fill: 0xf5f0fb,
								align: TextAlign.Center
							},
							layout: {
								dimensions: {
									widthPercent: 85,
									heightPercent: 60
								},
								vAlign: VAlign.Middle,
								hAlign: HAlign.Center
							}
						}
					]
				}
			]
		}
	]
};

import { AnimatedSprite, Container, Graphics, Sprite, Texture, Ticker } from "pixi.js";
import { injectable } from "inversify";
import {
	EventBus,
	Events,
	FixedDimensions,
	GraphicsUtils,
	Layout,
	LayoutContainer,
	lazyInject,
	Linear,
	Sine,
	Tween,
	Types
} from "@tournament/ui-core";
import { GameModel } from "../models/GameModel";
import { Components } from "../types/Components";
import { HiLoEvents } from "../events";
import { ErrorMessage, ErrorTitle } from "../types/ErrorMessages";

enum SpinState {
	Idle,
	Accelerating,
	Spinning,
	Stopping,
	Bouncing
}

@injectable()
export class NumberSpinner extends LayoutContainer {
	@lazyInject(Types.EventBus)
	protected eventBus!: EventBus;

	@lazyInject(Types.GameModel)
	protected model!: GameModel;

	@lazyInject(Components.PreviousNumberContainer)
	protected previousNumberContainer!: LayoutContainer;

	protected spinContainer: Container = new Container();
	protected numberContainer: Container = new Container();
	protected spinMask!: Sprite;

	protected numbers: Array<{ container: Container; digits: Sprite[] }> = [];

	protected currentNumber: number | undefined;

	protected padding: number = 0.1;

	protected readonly maxSpeed: number = 70;
	protected currentSpeed: number = 0;
	protected readonly accelerateDuration: number = 30;
	protected readonly stopDuration: number = 20;
	protected readonly bounceDuration: number = 8;
	protected readonly overshootDistance: number = 60;

	protected actualDistanceMoved: number = 0;
	protected expectedDistanceMoved: number = 0;
	protected totalDistanceToMove: number = 0;

	// protected timeElapsed: number | undefined;

	protected spinAnimation: Tween | undefined;

	protected moveUpTween: Tween | undefined;

	protected maxWidth!: number;
	protected childHeight!: number;

	protected paddedChildHeight!: number;

	protected currentPosition: number = 2;

	protected spinState: SpinState = SpinState.Idle;

	protected reelTextures: Texture[] = [];

	protected reelValues: number[] = [];

	protected reelValuePositions: { [key: number]: number } = {};

	protected substitutedReelValues: number[] | undefined;

	protected moveUpAnimation: AnimatedSprite | undefined;

	protected moveUpAnimationRatio: number | undefined;

	public constructor(layout: Layout) {
		super(layout);

		for (let i = 0; i <= 9; i++) {
			this.reelTextures.push(Texture.from("Number_" + i));
			// this.glowTextures.push(Texture.from("Number_" + i + "-Glow"));
		}

		this.eventBus.on(HiLoEvents.Game.Reset, this.reset, this);
		this.eventBus.once(HiLoEvents.Game.Init, this.initSpinner, this);
		this.eventBus.on(HiLoEvents.Game.RoundWin, this.startNumberMoveAnimation, this);
	}

	protected reset(): void {
		this.currentNumber = undefined;
		this.spinContainer.visible = false;
		if (this.spinAnimation) {
			this.spinAnimation.stop();
			this.spinAnimation = undefined;
		}

		this.currentSpeed = 0;
		this.spinState = SpinState.Idle;
		this.eventBus.removeListener(HiLoEvents.Game.RoundUpdated, this.setNextNumber, this);

		if (this.model.currentNumber == undefined) {
			this.eventBus.once(HiLoEvents.Game.RoundUpdated, this.setInitialNumber, this);
		} else {
			this.setInitialNumber();
		}
	}

	protected initSpinner(): void {
		if (this.model.minNumber && this.model.maxNumber) {
			for (let i = this.model.minNumber; i <= this.model.maxNumber; i++) {
				this.reelValues.push(i);
			}

			this.reelValues = this.shuffle(this.reelValues);

			for (let i = 0; i < this.reelValues.length; i++) {
				this.reelValuePositions[this.reelValues[i]] = i;
			}

			this.createNumbers(this.model.maxNumber);

			this.eventBus.on(HiLoEvents.Game.StartSpin, this.startSpin, this);

			if (this.model.currentNumber == undefined) {
				this.eventBus.once(HiLoEvents.Game.RoundUpdated, this.setInitialNumber, this);
			} else {
				this.setInitialNumber();
			}

			this.moveUpAnimation = new AnimatedSprite(GraphicsUtils.createTextureSequence("MoveNumberUp_", 20));
			this.moveUpAnimationRatio = this.moveUpAnimation.width / this.moveUpAnimation.height;

			this.moveUpAnimation.loop = false;
			this.moveUpAnimation.animationSpeed = 1;
			this.moveUpAnimation.visible = false;
			this.moveUpAnimation.onComplete = () => {
				(this.moveUpAnimation as AnimatedSprite).visible = false;
			};

			this.addChild(this.moveUpAnimation);

			Ticker.shared.add(this.update, this);
		} else {
			this.eventBus.emit(HiLoEvents.Game.ShowError, 303, ErrorTitle.Generic, ErrorMessage.Generic);
		}
	}

	protected shuffle(arrayToShuffle: Array<any>): Array<any> {
		let swapIndex;
		for (let i = 0; i < arrayToShuffle.length; i++) {
			swapIndex = Math.round(Math.random() * (arrayToShuffle.length - 1));
			[arrayToShuffle[i], arrayToShuffle[swapIndex]] = [arrayToShuffle[swapIndex], arrayToShuffle[i]];
		}

		return arrayToShuffle;
	}

	protected createNumbers(maxNumber: number): void {
		const numDigits: number = maxNumber.toString(10).length;

		let child: Sprite = new Sprite(this.reelTextures[0]);

		const maxFirstDigit: number = parseInt(maxNumber.toString(10).charAt(0), 10);
		let maxWidth: number = 0;
		let widthTester: Sprite;
		for (let i = 1; i <= maxFirstDigit; i++) {
			widthTester = new Sprite(this.reelTextures[i]);
			if (widthTester.width > maxWidth) {
				maxWidth = widthTester.width;
			}
		}

		this.maxWidth = maxWidth + child.width * (numDigits - 1); // 0 is the widest digit.
		this.childHeight = child.height;
		this.paddedChildHeight = this.childHeight + this.childHeight * this.padding;

		for (let i = 0; i < 3; i++) {
			const digits: Sprite[] = [];
			const valueContainer: Container = new Container();

			for (let j = 0; j < numDigits; j++) {
				child = new Sprite(this.reelTextures[0]); // Set actual digits later.
				valueContainer.addChild(child);
				digits.push(child);
			}

			valueContainer.y = this.paddedChildHeight * (i - 1); // Center middle number.

			this.numbers.push({ container: valueContainer, digits });
			this.numberContainer.addChild(valueContainer);
		}

		this.spinContainer.addChild(this.numberContainer);

		this.spinMask = new Sprite(Texture.from("NumberMask"));
		// this.spinMask.x = -(this.spinMask.width / 2);
		this.spinMask.y = -(this.spinMask.height - this.childHeight) / 2;

		const positionFix = new Graphics();
		positionFix.beginFill(0xff0000, 0);
		positionFix.drawRect(0, 0, this.spinMask.width, this.spinMask.height);
		positionFix.y = this.spinMask.y;
		this.spinContainer.addChild(positionFix);

		this.spinContainer.addChild(this.spinMask);
		this.spinContainer.mask = this.spinMask;

		this.addChildAt(this.spinContainer, 0);

		this.spinContainer.visible = false;
	}

	protected normalisePosition(position: number): number {
		return (position + this.reelValues.length) % this.reelValues.length;
	}

	protected updateDisplayedNumbers(): void {
		if (this.model.currentNumber != undefined && this.reelValues.length > 0) {
			const numDigits: number = (this.model.maxNumber as number).toString(10).length;

			let position: number;
			for (let i = 0; i < 3; i++) {
				position = this.normalisePosition(i + this.currentPosition - 1);

				const value: string = (this.substitutedReelValues
					? this.substitutedReelValues[position]
					: this.reelValues[position]
				).toString(10);

				let totalWidth: number = 0;
				for (let j = 0; j < numDigits; j++) {
					if (value.length > j) {
						this.numbers[i].digits[j].visible = true;
						this.numbers[i].digits[j].scale.x = 1;
						this.numbers[i].digits[j].scale.y = 1;
						this.numbers[i].digits[j].texture = this.reelTextures[parseInt(value.charAt(j))];
						totalWidth += this.numbers[i].digits[j].width;
					} else {
						this.numbers[i].digits[j].visible = false;
					}
				}

				const scale: number = Math.min(this.spinMask.width / this.maxWidth, 1);

				const scaledMaxWidth: number = this.maxWidth * scale;
				const scaledTotalWidth: number = totalWidth * scale;
				let currentPos: number = 0;
				// Center numbers
				for (let j = 0; j < numDigits; j++) {
					if (value.length > j) {
						this.numbers[i].digits[j].x = currentPos + (scaledMaxWidth - scaledTotalWidth) / 2;
						this.numbers[i].digits[j].y = (this.childHeight - this.childHeight * scale) / 2;

						currentPos += this.numbers[i].digits[j].width * scale;

						this.numbers[i].digits[j].scale.x = scale;
						this.numbers[i].digits[j].scale.y = scale;
					}
				}
			}
		}
	}

	protected setInitialNumber(): void {
		this.spinContainer.visible = true;
		this.currentNumber = this.model.currentNumber as number;
		this.currentPosition = this.reelValuePositions[this.currentNumber];

		const parent: LayoutContainer = this.parent as LayoutContainer;
		this.resize({ width: parent.getWidth(), height: parent.getHeight() });

		this.numberContainer.y = 0;
		this.updateDisplayedNumbers();

		this.eventBus.on(HiLoEvents.Game.RoundUpdated, this.setNextNumber, this);
	}

	protected setNextNumber(): void {
		this.spinState = SpinState.Stopping;
		this.currentNumber = this.model.currentNumber as number;

		this.startNextAnimation();
	}

	protected startSpin(): void {
		this.spinState = SpinState.Accelerating;
		this.startNextAnimation();
	}

	protected update(deltaFrame: number): void {
		if (this.moveUpTween != undefined) {
			this.moveUpTween.update(deltaFrame);

			if (!this.moveUpTween.isPlaying()) {
				this.moveUpTween = undefined;
			}
		}

		if (this.spinAnimation) {
			this.spinAnimation.update(deltaFrame);

			if (this.spinState === SpinState.Stopping || this.spinState === SpinState.Bouncing) {
				let amountToMove: number = Math.round(this.expectedDistanceMoved) - this.actualDistanceMoved;
				if (
					(this.totalDistanceToMove > 0 &&
						this.actualDistanceMoved + amountToMove > this.totalDistanceToMove) ||
					(this.totalDistanceToMove < 0 && this.actualDistanceMoved + amountToMove < this.totalDistanceToMove)
				) {
					amountToMove = this.totalDistanceToMove - this.actualDistanceMoved;
				}
				this.currentSpeed = amountToMove;
				this.actualDistanceMoved += amountToMove;
			}

			if (
				(this.spinState === SpinState.Stopping || this.spinState === SpinState.Bouncing) &&
				this.spinAnimation != undefined
			) {
				this.numberContainer.y += this.currentSpeed;
			} else {
				this.numberContainer.y += this.currentSpeed * deltaFrame;
			}

			while (this.numberContainer.y >= this.paddedChildHeight) {
				this.numberContainer.y -= this.paddedChildHeight;
				this.currentPosition--;
			}

			while (this.numberContainer.y <= -this.paddedChildHeight) {
				this.numberContainer.y += this.paddedChildHeight;
				this.currentPosition++;
			}
		}

		this.currentPosition = this.normalisePosition(this.currentPosition);
		this.updateDisplayedNumbers();
	}

	protected onTweenComplete(): void {
		switch (this.spinState) {
			case SpinState.Accelerating:
				this.spinAnimation = undefined;
				this.spinState = SpinState.Spinning;
				break;
			case SpinState.Spinning:
				this.spinState = SpinState.Stopping;
				this.startNextAnimation();
				break;
			case SpinState.Stopping:
				this.spinAnimation = undefined;
				this.spinState = SpinState.Bouncing;
				this.startNextAnimation();
				break;
			case SpinState.Bouncing:
				this.spinAnimation = undefined;
				this.spinState = SpinState.Idle;
				this.currentSpeed = 0;
				this.substitutedReelValues = undefined;

				if (this.currentNumber) {
					this.currentPosition = this.reelValuePositions[this.currentNumber];
					this.updateDisplayedNumbers();
					this.numberContainer.y = 0;
				}

				this.eventBus.emit(HiLoEvents.Game.SpinComplete);
				break;
			default:
				this.spinAnimation = undefined;
				this.currentSpeed = 0;
				break;
		}
	}

	protected startNextAnimation(): void {
		switch (this.spinState) {
			case SpinState.Accelerating:
				this.spinAnimation = new Tween(this, "currentSpeed", this).to(
					this.maxSpeed,
					this.accelerateDuration,
					Sine.easeOut
				);
				this.spinAnimation.once(Events.Animation.Complete, this.onTweenComplete);
				this.spinAnimation.play();
				break;
			case SpinState.Spinning:
				this.currentSpeed = this.maxSpeed;
				break;
			case SpinState.Stopping:
				if (this.currentNumber) {
					let positionsToMove: number = 1;
					this.totalDistanceToMove = this.paddedChildHeight;

					this.totalDistanceToMove += this.overshootDistance;

					if (this.numberContainer.y > 0) {
						positionsToMove++;
						this.totalDistanceToMove += this.paddedChildHeight - this.numberContainer.y;
					}

					this.substitutedReelValues = this.reelValues.concat();
					this.substitutedReelValues[
						this.normalisePosition(this.currentPosition - positionsToMove)
					] = this.currentNumber;

					this.expectedDistanceMoved = 0;
					this.actualDistanceMoved = 0;
					this.spinAnimation = new Tween(this, "expectedDistanceMoved").to(
						this.totalDistanceToMove,
						this.stopDuration,
						Sine.easeOut
					);
					this.spinAnimation.once(Events.Animation.Complete, this.onTweenComplete, this);
					this.spinAnimation.play();
				}
				break;
			case SpinState.Bouncing:
				this.totalDistanceToMove = -this.overshootDistance;
				this.expectedDistanceMoved = 0;
				this.actualDistanceMoved = 0;
				this.spinAnimation = new Tween(this, "expectedDistanceMoved").to(
					this.totalDistanceToMove,
					this.bounceDuration,
					Linear.easeNone
				);
				this.spinAnimation.once(Events.Animation.Complete, this.onTweenComplete, this);
				this.spinAnimation.play();
				break;
			default:
				this.currentSpeed = 0;
				break;
		}
	}

	protected startNumberMoveAnimation(): void {
		if (this.moveUpAnimation && this.moveUpAnimationRatio) {
			this.moveUpAnimation.visible = true;
			if (this.layoutDimensions) {
				this.moveUpAnimation.width = this.layoutDimensions.width * 1.6;
				this.moveUpAnimation.height = this.moveUpAnimation.width / this.moveUpAnimationRatio;
				this.moveUpAnimation.x = (this.layoutDimensions.width - this.moveUpAnimation.width) / 2;
				this.moveUpAnimation.y = -(this.moveUpAnimation.height * 0.4);
			}
			this.moveUpAnimation.gotoAndPlay(0);
		}

		const prop = { value: 0 };

		this.moveUpTween = new Tween(prop, "value", this).delay(17).execute(() => {
			this.eventBus.emit(HiLoEvents.Game.ShowPreviousNumber);
		});
		this.moveUpTween.play();
	}

	public resize(parentDimensions: FixedDimensions): FixedDimensions {
		let dimensions: FixedDimensions;
		if (parentDimensions.width > 0 && parentDimensions.height > 0) {
			dimensions = super.resize(parentDimensions);

			const ratio: number = this.spinContainer.width / this.spinContainer.height;

			this.spinContainer.width = dimensions.width;
			this.spinContainer.height = this.spinContainer.width / ratio;

			this.updateDisplayedNumbers();

			if (this.moveUpAnimation && this.moveUpAnimationRatio) {
				this.moveUpAnimation.width = dimensions.width * 1.6;
				this.moveUpAnimation.height = this.moveUpAnimation.width / this.moveUpAnimationRatio;
				this.moveUpAnimation.x = (dimensions.width - this.moveUpAnimation.width) / 2;
				this.moveUpAnimation.y = -(this.moveUpAnimation.height * 0.4);
			}
		} else if (this.layoutDimensions) {
			dimensions = { width: this.layoutDimensions.width, height: this.layoutDimensions.height };
		} else {
			dimensions = { width: 0, height: 0 };
		}

		return dimensions;
	}
}

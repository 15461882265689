import { Howl } from "howler";
import { LoaderResource } from "pixi.js";

export function SoundLoaderMiddleware(resource: LoaderResource, next: (...params: any[]) => any): void {
	if (resource && ["wav", "ogg", "mp3", "mpeg"].includes(resource.extension)) {
		resource.data = new Howl({
			src: [resource.url],
			onload: (): void => {
				next();
			},
			onloaderror: (soundId: number, error: any): void => {
				console.error("There was an error loading", soundId, ":", error);
			}
		});
	} else {
		next();
	}
}

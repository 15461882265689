import { ButtonConfig, ComponentType, HAlign, VAlign } from "@tournament/ui-core";
import { Components, HiLoComponentType, HiLoEvents } from "@tournament/hilo-ui-core";
import { SkinComponentType } from "../../types/SkinComponentType";

export const SoundButton: ButtonConfig<HiLoComponentType | SkinComponentType> = {
	id: Components.SoundButton,
	type: ComponentType.Button,
	textures: {
		up: "SoundButton-Enabled-Up",
		over: "SoundButton-Enabled-Over",
		down: "SoundButton-Enabled-Down"
	},
	offTextures: {
		up: "SoundButton-Disabled-Up",
		over: "SoundButton-Disabled-Over",
		down: "SoundButton-Disabled-Down"
	},
	clickEvent: HiLoEvents.User.ToggleSound,
	layout: {
		vAlign: VAlign.Middle,
		hAlign: HAlign.Center,
		dimensions: { width: 100, height: 100, heightPercent: 80, widthPercent: 80 }
	}
};

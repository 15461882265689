import { LoggingAdapter } from "./LoggingAdapter";
import Rollbar from "rollbar";
import { injectable } from "inversify";

@injectable()
export class RollbarLoggingAdapter implements LoggingAdapter {
	protected logger: Rollbar;

	public constructor() {
		this.logger = new Rollbar({
			accessToken: "e0276809f9244a7bbceb924321f83866",
			captureUncaught: true,
			captureUnhandledRejections: true,
			payload: {
				environment: "production"
			}
		});
	}

	public fatal(message: string): void {
		this.logger.critical(message);
	}

	public error(message: string): void {
		this.logger.error(message);
	}

	public warn(message: string): void {
		this.logger.warn(message);
	}

	public info(message: string): void {
		this.logger.info(message);
	}

	public debug(message: string): void {
		this.logger.debug(message);
	}
}

import { injectable } from "inversify";
import { Sprite } from "pixi.js";
import { Cubic, Events, LayoutSprite, lazyInject, PixiTween, Rect, Tween, TweenProperty } from "@tournament/ui-core";
import { Components, GenericTimerAnimController } from "@tournament/hilo-ui-core";

@injectable()
export class TimerAnimController extends GenericTimerAnimController {
	@lazyInject(Components.TimerGlow)
	protected timerGlow!: LayoutSprite;

	protected updateTimerRipple(target: Sprite, scale: number, alpha: number): void {
		const dimensions: Rect | undefined = this.timerBackground.getLayoutDimensions();

		if (dimensions) {
			target.width = dimensions.width * scale;
			target.height = dimensions.height * scale;
			target.alpha = alpha;
			target.x = this.timerBackground.worldTransform.tx + (dimensions.width - target.width) / 2;
			target.y = this.timerBackground.worldTransform.ty + (dimensions.height - target.height) / 2;
		}
	}

	protected onGameOver(): void {
		super.onGameOver();

		this.startTimerExplosionAnimation();
	}

	protected startTimerExplosionAnimation(): void {
		this.timerGlow.visible = true;
		this.timerGlow.alpha = 0;

		const tween: PixiTween = new PixiTween(this.timerGlow, TweenProperty.Alpha, this)
			.delay(50)
			.fromTo(0, 1, 10)
			.execute(() => {
				this.timerContainer.visible = false;
			})
			.delay(10)
			.to(0, 10);
		tween.play();
		this.animations.push(tween);

		const tweenValue: { value: number } = { value: 1.1 };

		const scaleTween: Tween = new Tween(tweenValue, "value", this).delay(50).to(1.5, 30, Cubic.easeIn);
		scaleTween.on(Events.Animation.Update, (deltaFrame: number) => {
			this.timerGlow.width = this.timerBackground.width * tweenValue.value;
			this.timerGlow.height = this.timerBackground.width * (tweenValue.value + (tweenValue.value - 1.1) * 2);
			this.timerGlow.x = this.timerBackground.x + (this.timerBackground.width - this.timerGlow.width) / 2;
			this.timerGlow.y = this.timerBackground.y + (this.timerBackground.height - this.timerGlow.height) / 2;
		});

		scaleTween.play();
		this.animations.push(scaleTween);
	}
}

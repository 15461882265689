import { injectable } from "inversify";
import { LayoutComponent } from "./LayoutComponent";
import { LayoutContainer } from "./LayoutContainer";
import { FixedDimensions, Layout } from "./config";
import { Container, Graphics, NineSlicePlane, Texture, Sprite } from "pixi.js";
import { TextField } from "./TextField";
import { PlayerRankInfo } from "../io/models";
import { Events } from "../events";
import { LeaderBoardConfig } from "./config/LeaderBoardConfig";
import { PrizeInfo } from "../io/models/PrizeInfo";
import { lazyInject } from "../ioc";
import { Types } from "../types";
import Dinero from "dinero.js";
import { ColorBackground } from "./config/ColorBackground";

@injectable()
export class LeaderBoard extends LayoutContainer implements LayoutComponent {
	@lazyInject(Types.GameModel)
	protected model!: any;

	protected config: LeaderBoardConfig<any, any>;

	protected rankings: Array<{ container: NineSlicePlane, rank: TextField, displayName: TextField, points: TextField }>;

	protected prizes: Array<{ container: Container, background: Graphics, icon?: Sprite, prizeValue: TextField }>;

	protected rankInfo: Array<PlayerRankInfo> = [];

	protected prizeInfo: Array<PrizeInfo> = [];

	protected rankTextures: Texture[] = [];

	public constructor(config: LeaderBoardConfig<any, any>, layout: Layout) {
		super(layout);

		this.config = config;

		this.rankings = [];
		this.prizes = [];

		for (let i = 0; i < config.rankConfig.length; i++) {
			this.rankTextures.push(Texture.from(config.rankConfig[i].texture));
		}

		this.createRankings();

		if (this.config.numRanksAround) {
			this.eventBus.on(Events.LeaderBoard.Update, this.updateRankings, this);
		} else {
			this.eventBus.on(Events.LeaderBoard.ShowTopRanks, this.updateRankings, this);
		}
	}

	protected createRankings(): void {
		const textures: Texture[] = this.rankTextures;
		const numRankingsToDisplay: number = this.config.numRanksAround ? ((this.config.numRanksAround * 2) + 1) : this.config.displayTopRanks as number;

		for (let i = 0; i < numRankingsToDisplay; i++) {
			let textureIndex: number;
			if (this.config.numRanksAround) {
				const ranksAround: number = this.config.numRanksAround;
				textureIndex = Math.min(i < ranksAround ? ranksAround - i : i - ranksAround, textures.length - 1);
			} else  {
				textureIndex = Math.min(i, textures.length - 1);
			}

			const container: NineSlicePlane = new NineSlicePlane(textures[textureIndex], 9, 9, 9, 9);
			this.addChild(container);

			const rank: TextField = new TextField("", this.config.textStyle, this.config.positionConfig);

			const displayName: TextField = new TextField("", this.config.textStyle, this.config.displayNameConfig);

			const textStyle = { ...this.config.textStyle };
			textStyle.align = "right";

			const points: TextField = new TextField("", textStyle, this.config.pointsConfig);

			container.addChild(rank, displayName, points);

			this.rankings.push({ container, rank, displayName, points });

			const prizeContainer: Container = new Container();
			prizeContainer.visible = false;
			this.addChild(prizeContainer);
			const background: Graphics = new Graphics();

			prizeContainer.addChild(background);

			const prizeTextStyle = { ...this.config.textStyle };
			prizeTextStyle.align = "center";

			let icon: Sprite | undefined;
			if (this.config.prizeConfig.iconLayout != undefined) {
				icon = new Sprite();
				prizeContainer.addChild(icon);

				prizeTextStyle.align = "right";
			}

			const prizeValue: TextField = new TextField("", prizeTextStyle, { ...this.config.prizeConfig.textLayout });

			prizeContainer.addChild(prizeValue);

			this.prizes.push({ container: prizeContainer, background, icon, prizeValue });
		}
	}

	protected updateRankings(playerRankings: Array<PlayerRankInfo>, prizes: Array<PrizeInfo>): void {
		this.rankInfo = playerRankings;
		this.prizeInfo = prizes;

		if (this.layoutDimensions != undefined) {
			const numRankingsToDisplay: number = this.config.numRanksAround ? ((this.config.numRanksAround * 2) + 1) : this.config.displayTopRanks as number;

			for (let i = 0; i < numRankingsToDisplay; i++) {
				if (i < this.rankInfo.length) {
					this.rankings[i].container.visible = true;

					this.rankings[i].rank.setText(this.rankInfo[i].rank.toString(10));
					this.rankings[i].displayName.setText(this.rankInfo[i].displayName);
					this.rankings[i].points.setText(this.rankInfo[i].points + "PTS");

					if (this.rankInfo[i].rank <= this.prizeInfo.length) {
						this.prizes[i].container.visible = true;
						const rank: number = this.rankInfo[i].rank;

						// TODO: Create interfaces for models.
						this.prizes[i].prizeValue.setText(Dinero({ amount: this.prizeInfo[rank - 1].amount * 100, currency: this.prizeInfo[rank - 1].currencyCode as any }).toFormat("$0,0.00"))

					} else {
						this.prizes[i].prizeValue.setText("");
						this.prizes[i].container.visible = false;
					}
				} else {
					this.rankings[i].container.visible = false;
				}
			}

			this.resizeRankingContainers(this.layoutDimensions);
		}

		this.eventBus.emit(Events.LeaderBoard.Updated);
	}

	public resize(parentDimensions: FixedDimensions): FixedDimensions {
		const newDimensions: FixedDimensions = super.resize(parentDimensions);

		this.resizeRankingContainers(newDimensions);

		return newDimensions;
	}

	protected resizeRankingContainers(dimensions: FixedDimensions):  void {
		let cumulativeYPosition: number = 0;

		const numRankingsToDisplay: number = this.config.numRanksAround ? ((this.config.numRanksAround * 2) + 1) : this.config.displayTopRanks as number;
		const playerIndex: number = this.getPlayerIndex();

		const width: number = dimensions.width;
		const height: number = dimensions.height;

		for (let i = 0; i < numRankingsToDisplay; i++) {
			if (i < this.rankInfo.length) {
				let distFromPlayer: number;

				if (this.config.numRanksAround != undefined) {
					distFromPlayer = i < playerIndex ? playerIndex - i : i - playerIndex;
				} else {
					distFromPlayer = i;
				}

				const container = this.rankings[i].container;
				container.texture = this.rankTextures[distFromPlayer];
				const prizeWidth: number = this.prizes[i].container.visible ? width * (this.config.prizeConfig.widthPercent / 100) : 0;
				container.width = width * (this.config.rankConfig[distFromPlayer].widthPercent / 100);
				container.height = height * (this.config.rankConfig[distFromPlayer].heightPercent / 100);
				container.x = (width - (container.width + prizeWidth)) / 2;
				container.y = cumulativeYPosition;

				this.rankings[i].rank.resize({ width: container.width, height: container.height });
				this.rankings[i].displayName.resize({ width: container.width, height: container.height });
				this.rankings[i].points.resize({ width: container.width, height: container.height });

				if (this.prizes[i].container.visible) {
					const prizeContainer: Container = this.prizes[i].container;
					prizeContainer.x = container.x + container.width;
					prizeContainer.y = cumulativeYPosition;

					const background: Graphics = this.prizes[i].background;

					background.clear();
					background.beginFill((this.config.rankConfig[distFromPlayer].background as ColorBackground).color, this.config.rankConfig[distFromPlayer].background.alpha || 1);
					background.drawRect(0, 0,  prizeWidth, container.height);
					background.endFill();

					this.prizes[i].prizeValue.resize({ width: prizeWidth, height: container.height });

					if (this.prizes[i].icon == undefined) {
						this.prizes[i].prizeValue.x = (this.prizes[i].background.width - this.prizes[i].prizeValue.getWidth()) / 2;
					}
				}

				const textAlpha: number = 1 - (0.05 * distFromPlayer);
				this.rankings[i].rank.alpha = this.rankings[i].displayName.alpha = this.rankings[i].points.alpha = this.prizes[i].prizeValue.alpha = textAlpha;

				cumulativeYPosition += this.rankings[i].container.height;
			}
		}
	}

	protected getPlayerIndex(): number {
		for (let i = 0; i < this.rankInfo.length; i++) {
			if (this.rankInfo[i].isPlayer) {
				return i;
			}
		}

		return 0;
	}
}

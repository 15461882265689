import i18next from "i18next";
import { injectable } from "inversify";

@injectable()
export class Translations {
	public constructor(language: string, resources: { [lang: string]: { translation: any }}) {
		i18next.init({
			fallbackLng: "en",
			lng: language,
			debug: true,
			resources: resources
		});
	}

	public get(key: string, values?: any): string {
		return i18next.t(key, values);
	}

	public getForLang(lang: string, key: string, values?: any): string {
		const t = i18next.getFixedT(lang);
		return t(key, values);
	}
}

import { lazyInject, EventBus, Types, Events } from "@tournament/ui-core";
import { injectable } from "inversify";
import { HiLoEvents } from "../events";

@injectable()
export class ClientApiController {
	@lazyInject(Types.EventBus)
	protected eventBus!: EventBus;

	public constructor() {
		this.eventBus.on(HiLoEvents.Game.StartSpin, this.onStartSpin, this);
		this.eventBus.on(HiLoEvents.Game.SpinComplete, this.onSpinComplete, this);
		this.eventBus.on(HiLoEvents.Game.JoinAfterSpectatorMode, this.joinAfterSpectatorMode, this);
		this.eventBus.once(Events.Loading.Complete, this.onLoadComplete, this);
		this.eventBus.on(Events.Sound.Disabled, this.onSoundDisabled, this);
		this.eventBus.on(Events.Sound.Enabled, this.onSoundEnabled, this);
		this.eventBus.on(Events.Interaction.Exit, this.onExit, this);
		window.addEventListener("message", this.onPostMessage.bind(this), false);
	}

	protected onLoadComplete(): void {
		this.postMessage("READY");
	}

	protected onStartSpin(): void {
		this.postMessage("ROUND_START");
	}

	protected onSpinComplete(): void {
		this.postMessage("ROUND_COMPLETE");
	}

	protected onSoundEnabled(): void {
		this.postMessage("SOUND_ENABLED");
	}

	protected onSoundDisabled(): void {
		this.postMessage("SOUND_DISABLED");
	}

	protected onExit(): void {
		this.postMessage("CLOSE");
	}

	protected joinAfterSpectatorMode(): void {
		this.postMessage("JOIN");
	}

	protected onPostMessage(event: MessageEvent): void {
		switch (event.data) {
			case "ENABLE_SOUND":
				this.eventBus.emit(Events.Sound.Enable);
				break;

			case "DISABLE_SOUND":
				this.eventBus.emit(Events.Sound.Disable);
				break;

			case "TRY_AGAIN":
				this.eventBus.emit(HiLoEvents.User.PlayAgain);
				break;
		}
	}

	private postMessage(data: string): void {
		if (!parent) {
			return;
		}

		parent.postMessage(data, "*");
	}
}

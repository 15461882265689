import { Ease } from "./Ease";

export class Back {
	public static easeIn: Ease = (
		time: number,
		from: number,
		to: number,
		duration: number,
		strength: number = 1.70158
	): number => {
		if (time >= duration) {
			return to;
		}

		return (to - from) * (time /= duration) * time * ((strength + 1) * time - strength) + from;
	};

	public static easeOut: Ease = (
		time: number,
		from: number,
		to: number,
		duration: number,
		strength: number = 1.70158
	): number => {
		if (time >= duration) {
			return to;
		}

		return (to - from) * ((time = time / duration - 1) * time * ((strength + 1) * time + strength) + 1) + from;
	};

	public static easeInOut: Ease = (
		time: number,
		from: number,
		to: number,
		duration: number,
		strength: number = 1.70158
	): number => {
		if (time >= duration) {
			return to;
		}

		if ((time /= duration / 2) < 1) {
			return ((to - from) / 2) * (time * time * (((strength *= 1.525) + 1) * time - strength)) + from;
		}

		return ((to - from) / 2) * ((time -= 2) * time * (((strength *= 1.525) + 1) * time + strength) + 2) + from;
	};
}

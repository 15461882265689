import * as superagent from "superagent";
import { injectable } from "inversify";
import { ClientService } from "./ClientService";
import { Direction } from "../models/Direction";
import { GameModel } from "../models/GameModel";
import { JoinGameResponse } from "./response/JoinGameResponse";
import { PlayGameResponse } from "./response/PlayGameResponse";
import { GameStatusResponse } from "./response/GameStatusResponse";
import { GameCompleteResponse } from "./response/GameCompleteResponse";
import { RoundCompleteResponse } from "./response/RoundCompleteResponse";
import { ErrorResponse } from "./response/ErrorResponse";
import { EventBus, lazyInject, Types, PrizeType } from "@tournament/ui-core";
import { HiLoEvents } from "../events";
import { ErrorMessage, ErrorTitle } from "../types";
import { LeaderBoardResponse } from "./response/LeaderBoardResponse";
import { GameInfoResponse } from "./response/GameInfoResponse";
import { LeaderBoardInfoResponse } from "./response/LeaderBoardInfoResponse";
import { HiLoGameMode } from "../models";

@injectable()
export class HiLoClientService implements ClientService {
	@lazyInject(Types.EventBus)
	protected eventBus!: EventBus;

	@lazyInject(Types.GameModel)
	protected model!: GameModel;

	@lazyInject(Types.ApiURL)
	protected hiloApiUrl!: string;

	private token: string;
	private operatorId: string;
	private gameId: number;

	private sessionToken!: string;

	protected pendingRequests: Array<any> = [];

	protected isGameOver: boolean = false;
	protected isSpectatorMode: boolean = false;

	public constructor(token: string, operatorId: string, gameId: number) {
		this.token = token;
		console.log("Init Token:"+this.token);
		if(this.token === "" || this.token === null || this.token === undefined){
			this.isSpectatorMode = true;
		}
		else{
			this.isSpectatorMode = false;
		}
		this.operatorId = operatorId;
		this.gameId = gameId;
	}

	public getIsSpectatorMode():boolean{
		return this.isSpectatorMode;
	}

	public setIsSpectatorMode(isSpectatorMode:boolean):void{
		this.isSpectatorMode = isSpectatorMode;
	}

	public async getGameInfo(): Promise<GameInfoResponse | ErrorResponse> {
		try {
			const response = await superagent.get(`${this.hiloApiUrl}/lobby/${this.operatorId}/${this.gameId}`);
			this.sessionToken = response.body.userToken;
			console.log("Game Info Response:"+JSON.stringify(response));
			console.log("SessionToken:"+this.sessionToken);
			return response.body as GameInfoResponse;
		} catch (error) {
			const code: number = (error.response && error.response.body && error.response.body.code) || -1;
			return new ErrorResponse(code);
		}
	}

	public async joinGame(): Promise<JoinGameResponse | ErrorResponse> {
		try {
			return await this.retry(async () => {
				const response = await superagent
					.post(`${this.hiloApiUrl}/game/join`)
					.set("token", this.token)
					.send({
						gameId: this.gameId,
						operatorId: this.operatorId
					});

				this.sessionToken = response.body.userToken;
				console.log("Join Game Response:"+JSON.stringify(response));
				console.log("Join Game Response: SessionToken:"+this.sessionToken);
				return response.body as JoinGameResponse;
			});
		} catch (error) {
			const code: number = (error.response && error.response.body && error.response.body.code) || -1;
			return new ErrorResponse(code);
		}
	}

	public async play(direction: Direction): Promise<PlayGameResponse | ErrorResponse> {
		if (this.pendingRequests.length > 0) {
			await this.pendingRequests[this.pendingRequests.length - 1];
		}

		const roundNumber: number | undefined = this.model.roundNumber || 1;

		if (!roundNumber) {
			this.eventBus.emit(HiLoEvents.Game.ShowError, 400, ErrorTitle.Generic, ErrorMessage.Generic);
			throw new Error("Game not initialised");
		} else {
			const request = superagent
				.post(`${this.hiloApiUrl}/game/play`)
				.set("session-token", this.sessionToken)
				.send({
					gameId: this.gameId,
					roundNumber: roundNumber,
					direction
				})
				.then((response: any) => {
					this.pendingRequests.splice(this.pendingRequests.indexOf(request), 1);
					this.sessionToken = response.body.userToken;
					return response.body as PlayGameResponse;
				})
				.catch((error: any) => {
					const code: number = (error.response && error.response.body && error.response.body.code) || -1;

					return new ErrorResponse(code);
				});

			this.pendingRequests.push(request);

			return request;
		}
	}

	public async getGameStatus(): Promise<GameStatusResponse | ErrorResponse> {
		if (this.pendingRequests.length > 0) {
			await this.pendingRequests[this.pendingRequests.length - 1];
		}
		const sessionToken = this.sessionToken;
		const request = await superagent
			.get(`${this.hiloApiUrl}/game/${this.operatorId}/${this.gameId}`)
			//.set("session-token", sessionToken)
			.then((response: any) => {
				this.pendingRequests.splice(this.pendingRequests.indexOf(request), 1);
				return response.body as GameStatusResponse;
			})
			.catch((error: any) => {
				const code: number = (error.response && error.response.body && error.response.body.code) || -1;

				if (sessionToken !== this.sessionToken) {
					// Session token updated, retry.
					return this.getGameStatus();
				}

				return new ErrorResponse(code);
			});

		this.pendingRequests.push(request);

		return request;
	}

	public async getRoundResult(
		roundId: number
	): Promise<RoundCompleteResponse | GameCompleteResponse | ErrorResponse> {
		if (this.pendingRequests.length > 0) {
			await this.pendingRequests[this.pendingRequests.length - 1];
		}

		const request = await this.retry(() => {
			return superagent
				.get(`${this.hiloApiUrl}/game/${this.operatorId}/${this.gameId}/round/${roundId}`)
				.set("session-token", this.sessionToken)
				.then((response: any) => {
					this.pendingRequests.splice(this.pendingRequests.indexOf(request), 1);

					if (response.body.winners != undefined) {
						this.isGameOver = true;
						return response.body as GameCompleteResponse;
					} else {
						return response.body as RoundCompleteResponse;
					}
				});
		}).catch((error: any) => {
			const code: number = (error.response && error.response.body && error.response.body.code) || -1;

			return new ErrorResponse(code);
		});

		this.pendingRequests.push(request);

		return request;
	}

	public async getRoundResultSpectatorMode(
		roundId: number
	): Promise<any> {
		if (this.pendingRequests.length > 0) {
			await this.pendingRequests[this.pendingRequests.length - 1];
		}

		const request = await this.retry(() => {
			return superagent
				.get(`${this.hiloApiUrl}/game/${this.operatorId}/${this.gameId}/round/${roundId}`)
				.then((response: any) => {
					this.pendingRequests.splice(this.pendingRequests.indexOf(request), 1);

					if (response.body.winners != undefined) {
						this.isGameOver = true;
						return response.body as GameCompleteResponse;
					} else {
						return response.body as RoundCompleteResponse;
					}
				});
		}).catch((error: any) => {
			const code: number = (error.response && error.response.body && error.response.body.code) || -1;

			return new ErrorResponse(code);
		});

		this.pendingRequests.push(request);

		return request;
	}

	public async collectPrize(): Promise<void | ErrorResponse> {
		return await this.retry(() => {
			const request = superagent
				.get(`${this.hiloApiUrl}/game/${this.operatorId}/${this.gameId}/claim`)
				.set("session-token", this.sessionToken)
				.then((response: any) => {
					return;
				});

			return request;
		}).catch((error: any) => {
			const code: number = (error.response && error.response.body && error.response.body.code) || -1;

			return new ErrorResponse(code);
		});
	}

	public async getLeaderBoard(): Promise<LeaderBoardResponse | ErrorResponse> {
		return await this.retry(() => {
			const request = superagent
				.get(`${this.hiloApiUrl}/game/${this.operatorId}/${this.gameId}/leaderboard`)
				.set("session-token", this.sessionToken)
				.then((response: any) => {
					return response.body as LeaderBoardResponse;
				});

			return request;
		}).catch((error: any) => {
			const code: number = (error.response && error.response.body && error.response.body.code) || -1;

			return new ErrorResponse(code);
		});
	}

	public async getLeaderBoardInfo(): Promise<LeaderBoardInfoResponse | ErrorResponse> {
		try {
			const response = await superagent.get(`${this.hiloApiUrl}/lobby/${this.operatorId}/${this.gameId}/leaderboard`);
			return response.body as LeaderBoardInfoResponse;
		} catch (error) {
			const code: number = (error.response && error.response.body && error.response.body.code) || -1;
			return new ErrorResponse(code);
		}
	}

	public async getLeaderBoardTopRanks(numRanks: number): Promise<LeaderBoardResponse | ErrorResponse> {
		return await this.retry(() => {
			const request = superagent
				.get(`${this.hiloApiUrl}/game/${this.operatorId}/${this.gameId}/leaderboard/top?count=${numRanks}`)
				.set("session-token", this.sessionToken)
				.then((response: any) => {
					return response.body as LeaderBoardResponse;
				});

			return request;
		}).catch((error: any) => {
			const code: number = (error.response && error.response.body && error.response.body.code) || -1;

			return new ErrorResponse(code);
		});
	}

	protected retry(func: () => Promise<any>, numRetries: number = 3, interval: number = 3000): Promise<any> {
		return new Promise((resolve, reject) => {
			return func()
				.then(resolve)
				.catch((error: any) => {
					if (numRetries === 0 || this.isGameOver) {
						reject(error);
					} else {
						if (error.response && error.response.body) {
							if (error.response.body.code === 4 || error.response.body.code === 17) {
								interval = 500;
							} else {
								reject(error);
								return;
							}
						} else {
							numRetries--;
						}

						setTimeout(() => {
							this.retry(func, numRetries, interval).then(resolve, reject);
						}, interval);
					}
				});
		});
	}
}

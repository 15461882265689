import { Application, Container, Ticker } from "pixi.js";
import {
	appContainer,
	AssetConfig,
	Builders,
	EventBus,
	Events,
	GameBuilder,
	LayoutComponent,
	LayoutConfig,
	LayoutContainer,
	LayoutManager,
	lazyInject,
	Logger,
	NineSliceButton,
	Services,
	TextField,
	Translations,
	Types,
	PrizeType
} from "@tournament/ui-core";
import {
	ActiveGameStatusResponse,
	ClientService,
	ErrorResponse,
	GameCompleteResponse,
	GameStatusResponse,
	InactiveGameStatusResponse,
	JoinGameResponse,
	RoundCompleteResponse
} from "../io";
import { Direction, GameModel, HiLoGameMode } from "../models";
import { Components, ErrorMessage, ErrorTitle } from "../types";
import { HiLoEvents } from "../events";
import { LeaderBoardResponse } from "../io/response/LeaderBoardResponse";
import Dinero from "dinero.js";
import { GameInfoResponse } from "../io/response/GameInfoResponse";

export class HiLoGame {
	@lazyInject(Types.Translations)
	protected translations!: Translations;

	@lazyInject(Types.EventBus)
	protected eventBus!: EventBus;

	@lazyInject(Types.LayoutManager)
	protected layoutManager!: LayoutManager;

	@lazyInject(Builders.Game)
	protected gameBuilder!: GameBuilder;

	@lazyInject(Services.Client)
	protected clientService!: ClientService;

	@lazyInject(Types.GameModel)
	protected model!: GameModel;

	@lazyInject(Types.Application)
	protected application!: Application;

	@lazyInject(Components.ErrorDialog)
	protected errorDialog!: LayoutContainer;

	@lazyInject(Components.ErrorDialogConfirm)
	protected errorDialogConfirm!: NineSliceButton;

	@lazyInject(Components.ErrorMessage)
	protected errorMessage!: TextField;

	@lazyInject(Components.ErrorTitle)
	protected errorTitle!: TextField;

	@lazyInject(Components.PreloadConfig)
	protected preloaderConfig!: LayoutConfig<any>;

	@lazyInject(Components.AssetConfig)
	protected assetConfig!: AssetConfig;

	protected preloadContainer: LayoutContainer | undefined;

	protected config: LayoutConfig<any>;

	protected currentChoice: Direction | undefined;

	protected isObserving: boolean = false;

	protected hasLoaded: boolean = false;

	protected isGameOver: boolean = false;

	protected playerRoundNumberAdjustment: number = 0;

	protected isRestarting: boolean = false;

	protected statusTimeoutId: number | undefined;

	protected isFirstRound: boolean = true;

	protected isFirstJoin: boolean = true;

	protected reJoining: boolean = false;

	protected spectatorModeTimeOut: any;
	protected continueSpectatorModeTimeOut: any;

	public constructor(config: LayoutConfig<any>, width: number, height: number) {
		this.config = config;

		this.application.render();

		const preloadComponents: LayoutComponent[] = this.gameBuilder.build(this.preloaderConfig);

		// Instantiate controllers now we've created their dependencies.
		for (const name of this.preloaderConfig.controllers) {
			appContainer.get(name);
		}

		this.preloadContainer = new LayoutContainer({ dimensions: { widthPercent: 100, heightPercent: 100 } });
		for (const component of preloadComponents) {
			this.preloadContainer.addChild(component);

			component.resize({ width, height });
		}

		this.eventBus.on(HiLoEvents.User.Close, this.onClose, this);

		this.eventBus.on(HiLoEvents.User.PlayAgain, this.startNewGame, this);

		this.eventBus.on(HiLoEvents.Game.ShowError, this.displayError, this);

		this.eventBus.on(HiLoEvents.Game.ChoiceMade, this.onChoiceMade, this);

		this.eventBus.on(HiLoEvents.User.ObserveGame, this.onObserveGame, this);

		this.eventBus.on(HiLoEvents.User.CollectPrize, this.collectPrize, this);

		this.eventBus.on(HiLoEvents.User.ExitSpectatorMode, this.exitSpectatorMode, this);

		this.application.stage.addChild(this.preloadContainer);

		Ticker.shared.add(this.update, this);
		window.addEventListener("resize", this.resize);
		this.eventBus.on(HiLoEvents.Game.Resize, this.resize, this);

		this.resize();
	}

	protected async exitSpectatorMode(){
		this.eventBus.emit(HiLoEvents.Game.JoinAfterSpectatorMode);
		// this.clientService.setIsSpectatorMode(false);
		// const gameInfoResponse = await this.clientService.getGameInfo();
		// console.log("GameIntro");
		// console.log(gameInfoResponse);
		// if (gameInfoResponse instanceof ErrorResponse) {
		// 	this.handleServiceError(gameInfoResponse);
		// 	return;
		// }

		// this.mapGameInfo(gameInfoResponse);
		// window.clearTimeout(this.spectatorModeTimeOut);
		// window.clearTimeout(this.continueSpectatorModeTimeOut);
		// this.startNewGame();


		
		// if (this.model.nextRoundStartTime) {
		// 	window.setTimeout(() => {
		// 		console.log("Round Restore Case");
		// 		if (this.model.gameMode === HiLoGameMode.Leaderboard) {
		// 			this.getLeaderBoardAndRoundInfo(
		// 				this.model.roundNumber /*? this.model.roundNumber + 1 : 1*/
		// 			);
		// 		} else {
		// 			this.getRoundInfo(this.model.roundNumber ? this.model.roundNumber + 1 : 1);
		// 		}
		// 	}, this.model.nextRoundStartTime + 300 - Date.now());
		// }
		//this.gameIntro();
	}

	protected onObserveGame(): void {
		this.isObserving = true;
		this.statusTimeoutId = window.setTimeout(() => {
			this.getGameStatus();
		}, 2000);

		setTimeout(() => {
			if (this.model.gameMode === HiLoGameMode.Leaderboard) {
				this.getLeaderBoardAndRoundInfo(this.model.roundNumber ? this.model.roundNumber + 1 : 1);
			} else {
				this.getRoundInfo(this.model.roundNumber ? this.model.roundNumber + 1 : 1);
			}
		}, (this.model.nextRoundStartTime as number) + 300 - Date.now());
	}

	public load(): void {
		Dinero.globalLocale = this.model.locale;
		this.eventBus.once(Events.Loading.Complete, this.gameIntro, this);
		this.eventBus.emit(Events.Loading.Start, this.assetConfig);
		this.eventBus.once(HiLoEvents.Game.Join, this.joinGame, this);
	}

	protected startNewGame(): void {
		this.reset();

		this.eventBus.emit(HiLoEvents.Game.Reset);

		this.joinGame();
	}

	protected reset(): void {
		if (this.statusTimeoutId != undefined) {
			window.clearTimeout(this.statusTimeoutId);
			this.statusTimeoutId = undefined;
		}

		this.isGameOver = false;
		this.isObserving = false;
		this.currentChoice = undefined;
		this.isRestarting = true;
		this.reJoining = true;
		this.resetModel();
		this.isFirstRound = true;
	}

	protected resetModel(): void {
		this.model.startTime = undefined;
		this.model.prizeAmount = undefined;
		this.model.currency = undefined;
		this.model.roundDurationSeconds = undefined;
		this.model.cutOffTime = undefined;
		this.model.participants = undefined;
		this.model.minNumber = undefined;
		this.model.maxNumber = undefined;

		this.model.roundNumber = undefined;
		this.model.playerRoundNumber = undefined;
		this.model.nextRoundStartTime = undefined;
		this.model.currentNumber = undefined;
		this.model.numUpPlayers = undefined;
		this.model.numDownPlayers = undefined;
		this.model.numParticipantsRemaining = undefined;
		this.model.lastNumbers = [];
		this.model.maxRounds = undefined;
		this.model.maxWinners = undefined;
		this.model.winners = undefined;
		this.model.prizeLadder = undefined;
		this.model.pointsPerRound = undefined;
	}

	protected async gameIntro(): Promise<void> {
		const gameInfoResponse = await this.clientService.getGameInfo();
		console.log("GameIntro");
		console.log(gameInfoResponse);
		if (gameInfoResponse instanceof ErrorResponse) {
			this.handleServiceError(gameInfoResponse);
			return;
		}

		this.mapGameInfo(gameInfoResponse);
		if (this.clientService.getIsSpectatorMode()) {
			this.model.lastNumbers = gameInfoResponse.numbers;
		}
		if (this.model.gameMode === HiLoGameMode.Leaderboard) {
			const leaderboardInfoResponse = await this.clientService.getLeaderBoardInfo();

			if (leaderboardInfoResponse instanceof ErrorResponse) {
				this.handleServiceError(leaderboardInfoResponse);
				return;
			}

			this.model.prizes = leaderboardInfoResponse.prizes;
			this.model.leaderboardTotalPlayers = leaderboardInfoResponse.totalCount;
		}

		this.buildGame();
		if (this.clientService.getIsSpectatorMode()) {
			this.startSpectatorModeSequence(gameInfoResponse);
		}
		else {
			this.eventBus.emit(HiLoEvents.Game.Join);
		}
		// this.eventBus.emit(HiLoEvents.Game.ShowGameInfoDialog);	
	}


	protected async startSpectatorModeSequence(gameInfoResponse: any): Promise<void> {
		const response = await this.clientService.getRoundResultSpectatorMode(gameInfoResponse.numbers.length);
		this.model.nextRoundStartTime = response.nextRoundStartTime;
		this.model.currentNumber = response.currentNumber;
		this.model.roundNumber = response.roundNumber;
		this.model.startTime = gameInfoResponse.startTime;
		this.model.endTime = gameInfoResponse.endTime;
		console.log(response);
		this.eventBus.emit(HiLoEvents.Game.Init);
		if (this.model.nextRoundStartTime) {
			this.spectatorModeTimeOut = window.setTimeout(() => {
				this.continueSpectatorMode(this.model.roundNumber ? this.model.roundNumber + 1 : 1, this.model.nextRoundStartTime);
			}, this.model.nextRoundStartTime + 300 - Date.now());
		}
		
	}
	protected async continueSpectatorMode(roundNumber: number, nextRoundStartTime: number | undefined): Promise<void> {
		const gameInfoResponse = await this.clientService.getGameInfo();
		console.log("GameIntro");
		console.log(gameInfoResponse);
		if (gameInfoResponse instanceof ErrorResponse) {
			this.handleServiceError(gameInfoResponse);
			return;
		}

		this.mapGameInfo(gameInfoResponse);
		if (this.clientService.getIsSpectatorMode()) {
			this.model.lastNumbers = gameInfoResponse.numbers;
		}
		const response = await this.clientService.getRoundResultSpectatorMode(roundNumber);
		this.model.nextRoundStartTime = response.nextRoundStartTime;
		this.model.currentNumber = response.currentNumber;
		this.model.roundNumber = response.roundNumber;
		this.model.startTime = nextRoundStartTime;
		this.model.endTime = this.model.endTime;
		console.log(response);
		this.eventBus.emit(HiLoEvents.Game.RoundUpdated);
		this.eventBus.emit(HiLoEvents.Game.ShowPreviousNumber);
		this.statusTimeoutId = window.setTimeout(() => {
			this.getGameStatus();
		}, 500);
		if (this.model.nextRoundStartTime) {
			this.continueSpectatorModeTimeOut = window.setTimeout(() => {
				this.continueSpectatorMode(this.model.roundNumber ? this.model.roundNumber + 1 : 1, this.model.nextRoundStartTime);
			}, this.model.nextRoundStartTime + 300 - Date.now());
		}
	}

	protected async joinGame(): Promise<void> {
		this.clientService.setIsSpectatorMode(false);
		window.clearTimeout(this.spectatorModeTimeOut);
		window.clearTimeout(this.continueSpectatorModeTimeOut);
		const response = await this.clientService.joinGame();
		console.log("JoinGame:")
		console.log(response);
		if (response instanceof ErrorResponse) {
			this.handleServiceError(response);
			return;
		}

		this.isRestarting = false;

		this.mapGameInfo(response);

		this.model.playerRoundNumber = response.playerRoundNumber;
		this.model.livesRemaining = response.livesRemaining;
		this.model.inPlay = response.inPlay !== false;
		if (this.model.playerRoundNumber != undefined && this.model.roundNumber != undefined) {
			this.playerRoundNumberAdjustment = this.model.roundNumber - this.model.playerRoundNumber;
		}

		if (this.model.startTime === undefined || this.model.nextRoundStartTime === undefined) {
			this.displayError(104, ErrorTitle.Generic, ErrorMessage.Generic);
			return;
		}

		if (response.currentDirection != undefined) {
			this.currentChoice = response.currentDirection;
		} else if (!this.model.inPlay) {
			if (response.mode === HiLoGameMode.Standard) {
				this.isObserving = true;
			} else {
				this.isGameOver = true;
			}
		}

		if (this.model.gameMode === HiLoGameMode.Leaderboard) {
			setTimeout(() => {
				this.getLeaderBoardUpdate();
			}, 0);
		}

		if (this.isFirstJoin) {
			if (!this.isGameOver) {
				if (response.roundNumber !== undefined && this.model.inPlay === true && !this.reJoining && response.timestamp > response.startTime) {
					if (this.model.gameMode === HiLoGameMode.Leaderboard) {
						this.getLeaderBoardAndRoundInfo(
							response.roundNumber
						);
					} else {
						this.getRoundInfo(response.roundNumber);
					}
					this.startGame();
					return;
				}
			}
		}

		this.reJoining = false;

		if (!this.isGameOver) {
			this.statusTimeoutId = window.setTimeout(() => {
				this.getGameStatus();
			}, 500);

			if (response.roundNumber == undefined) {
				window.setTimeout(() => {
					if (this.model.gameMode === HiLoGameMode.Leaderboard) {
						this.getLeaderBoardAndRoundInfo(
							this.model.roundNumber ? this.model.roundNumber + 1 : 1
						);
					} else {
						this.getRoundInfo(this.model.roundNumber ? this.model.roundNumber + 1 : 1);
					}
				}, this.model.startTime + 300 - Date.now());
			} else {
				window.setTimeout(() => {
					console.log("Round Restore Case");
					if (this.model.gameMode === HiLoGameMode.Leaderboard) {
						this.getLeaderBoardAndRoundInfo(
							this.model.roundNumber ? this.model.roundNumber + 1 : 1
						);
					} else {
						this.getRoundInfo(this.model.roundNumber ? this.model.roundNumber + 1 : 1);
					}
				}, this.model.nextRoundStartTime + 300 - Date.now());
			}
		}

		if (this.isFirstJoin) {
			this.isFirstJoin = false;
			this.startGame();
			return;
		}

		if (this.isGameOver) {
			if (this.model.gameMode === HiLoGameMode.Leaderboard)
				this.getLeaderBoardTopRanks();

			this.eventBus.emit(HiLoEvents.Game.GameOver);
			return;
		}

		this.eventBus.once(HiLoEvents.Game.RoundUpdated, this.startGame, this);

		if (this.model.roundNumber && (this.model.startTime <= Date.now() || this.model.nextRoundStartTime <= Date.now())) {
			this.getRoundInfo(this.model.roundNumber);
		}
	}

	protected onChoiceMade(direction: Direction): void {
		this.currentChoice = direction;
	}

	protected async getLeaderBoardUpdate(): Promise<void> {
		const response = await this.clientService.getLeaderBoard();

		if (response instanceof ErrorResponse) {
			if (response.code !== 7)
				this.displayError(102, ErrorTitle.Generic, ErrorMessage.Generic);
			return;
		}

		this.model.prizes = response.prizes;
		this.model.leaderboardTotalPlayers = response.totalCount;
		this.model.leaderboardRankings = response.positions;
		console.log("Leaderboard info", response);
		this.eventBus.emit(Events.LeaderBoard.Update, this.model.leaderboardRankings, this.model.prizes);
	}

	protected async getLeaderBoardTopRanks(count: number = 5): Promise<void> {
		const response = await this.clientService.getLeaderBoardTopRanks(count);

		if (response instanceof ErrorResponse) {
			if (response.code !== 7)
				this.displayError(102, ErrorTitle.Generic, ErrorMessage.Generic);
			return;
		}

		this.model.prizes = response.prizes;
		this.model.leaderboardTotalPlayers = response.totalCount;
		this.model.leaderboardRankings = response.positions;
		console.log("Leaderboard info", response);
		this.eventBus.emit(Events.LeaderBoard.ShowTopRanks, this.model.leaderboardRankings, this.model.prizes);
	}

	protected collectPrize(): void {
		this.clientService.collectPrize().then((response: void | ErrorResponse) => {
			if (response instanceof ErrorResponse) {
				if (response.code !== 7) {
					this.displayError(901, ErrorTitle.Generic, ErrorMessage.Generic);
				}
			} else {
				this.closeGame();
			}
		});
	}

	protected async getGameStatus(): Promise<void> {
		if (this.isGameOver || this.isRestarting)
			return;

		const response = await this.clientService.getGameStatus();

		if (response instanceof ErrorResponse) {
			if (response.code !== 7)
				this.displayError(102, ErrorTitle.Generic, ErrorMessage.Generic);
			return;
		}

		if ((response as InactiveGameStatusResponse).participants != undefined) {
			this.model.participants = (response as InactiveGameStatusResponse).participants;

			this.eventBus.emit(HiLoEvents.Game.StatusUpdated);

			this.statusTimeoutId = window.setTimeout(() => {
				this.getGameStatus();
			}, 2000);
		} else {
			const status: ActiveGameStatusResponse = response as ActiveGameStatusResponse;
			this.model.numUpPlayers = status.numUpPlayers;
			this.model.numDownPlayers = status.numDownPlayers;

			this.eventBus.emit(HiLoEvents.Game.StatusUpdated);

			this.statusTimeoutId = window.setTimeout(() => {
				this.getGameStatus();
			}, 2000);
		}
	}

	protected getLeaderBoardAndRoundInfo(roundNumber: number): void {
		this.eventBus.once(
			Events.LeaderBoard.Update,
			() => {
				this.getRoundInfo(roundNumber);
			},
			this
		);

		this.getLeaderBoardUpdate();
	}

	protected async getRoundInfo(roundNumber: number): Promise<void> {
		if (this.isGameOver)
			return;

		const response = await this.clientService.getRoundResult(roundNumber);

		if (response instanceof ErrorResponse) {
			switch (response.code) {
				case 7:
					if (this.model.gameMode === HiLoGameMode.Leaderboard) {
						this.getLeaderBoardAndRoundInfo(this.model.roundNumber || 1);
					} else {
						this.getRoundInfo(this.model.roundNumber || 1);
					}
					break;
				default:
					this.displayError(101, ErrorTitle.Generic, ErrorMessage.Generic);
					break;
			}
			return;
		}

		if ((response as GameCompleteResponse).winners != undefined && this.model.gameMode !== HiLoGameMode.Millionaire) {
			this.model.nextRoundStartTime = undefined;
			this.model.numParticipantsRemaining = response.numParticipantsRemaining;
			this.model.currentNumber = response.currentNumber;
			this.model.winners = (response as GameCompleteResponse).winners;
			this.eventBus.emit(HiLoEvents.Game.RoundUpdated);

			this.isGameOver = true;

			if (this.isObserving) {
				this.eventBus.emit(Events.Sound.Stop, "AnticipationMusic");
				if (this.model.winners.length > 0) {
					this.eventBus.emit(Events.Sound.Play, "WinSound");
					this.eventBus.emit(HiLoEvents.Game.ObserveGameWin, this.model.winners);
				} else
					this.eventBus.emit(HiLoEvents.Game.ObserveGameOver);
			} else {
				// TODO: Change if win animations should be shown for winners playing at end of Leaderboard
				// mode tournament.
				if (
					this.model.gameMode === HiLoGameMode.Standard &&
					(response as GameCompleteResponse).winAmount != undefined
				) {
					this.model.winAmount = (response as GameCompleteResponse).winAmount;
					this.eventBus.emit(Events.Sound.Play, "WinSound");
					this.eventBus.emit(HiLoEvents.Game.PrizeWin);
				} else {
					if (this.model.gameMode === HiLoGameMode.Leaderboard) {
						this.getLeaderBoardTopRanks();
					}
					this.eventBus.emit(HiLoEvents.Game.GameOver);
				}
			}
			return;
		}

		let isGameOverForPlayer: boolean = false;

		if (response.roundNumber !== this.model.roundNumber) {
			if (response.inPlay != undefined && response.inPlay) {
				this.eventBus.emit(Events.Sound.Play, "RoundComplete");
			} else {
				isGameOverForPlayer = true;
			}

			this.currentChoice = undefined;

			this.model.roundNumber = response.roundNumber;

			if (this.model.gameMode !== HiLoGameMode.Standard) {
				this.model.playerRoundNumber = Math.max((this.model.roundNumber - 1) - this.playerRoundNumberAdjustment, 1);
			}
		}

		if (
			this.model.gameMode !== HiLoGameMode.Standard &&
			response.nextRoundStartTime == undefined
		) {
			isGameOverForPlayer = true;
			this.model.nextRoundStartTime = undefined;
		} else {
			this.model.nextRoundStartTime =
				Date.now() +
				(response.nextRoundStartTime - (response as RoundCompleteResponse).timestamp);
		}

		this.model.currentNumber = response.currentNumber;
		this.model.numParticipantsRemaining = response.numParticipantsRemaining;

		this.eventBus.emit(HiLoEvents.Game.RoundUpdated);

		if (
			isGameOverForPlayer &&
			(!this.isObserving || this.model.gameMode !== HiLoGameMode.Standard)
		) {
			this.model.livesRemaining = Math.max((this.model.livesRemaining || 0) - 1, 0);
			if (this.model.gameMode === HiLoGameMode.Leaderboard) {
				this.getLeaderBoardTopRanks();
			}
			this.eventBus.emit(HiLoEvents.Game.GameOver);
		} else {
			window.setTimeout(() => {
				if (this.model.gameMode === HiLoGameMode.Leaderboard) {
					this.getLeaderBoardAndRoundInfo(
						this.model.roundNumber ? this.model.roundNumber + 1 : 1
					);
				} else {
					this.getRoundInfo(this.model.roundNumber ? this.model.roundNumber + 1 : 1);
				}
			}, (this.model.nextRoundStartTime || Date.now()) + 300 - Date.now());
		}

		if (!isGameOverForPlayer || this.isObserving) {
			if (this.isFirstRound)
				this.isFirstRound = false;
			else
				this.eventBus.emit(HiLoEvents.Game.RoundWin);
		}
	}

	protected buildGame(): void {
		const components: LayoutComponent[] = this.gameBuilder.build(this.config);

		// Instantiate controllers now we've created their dependencies.
		for (const name of this.config.controllers) {
			appContainer.get(name);
		}

		const stage: Container = this.application.stage;

		this.application.resize();

		const width = this.application.screen.width;
		const height = this.application.screen.height;

		for (const component of components) {
			stage.addChild(component);
			component.resize({ width, height });
		}

		if (this.preloadContainer) {
			stage.removeChild(this.preloadContainer);
			this.preloadContainer = undefined;
		}

		this.hasLoaded = true;
	}

	protected startGame(): void {
		if (
			(this.model.gameMode === HiLoGameMode.Leaderboard || this.model.gameMode === HiLoGameMode.Millionaire) &&
			this.isGameOver
		) {
			this.eventBus.emit(HiLoEvents.Game.GameOver);
		} else {
			this.eventBus.emit(HiLoEvents.Game.Init);

			if (this.currentChoice != undefined) {
				this.eventBus.emit(HiLoEvents.Game.SetUserChoice, this.currentChoice);
			}

			if (this.isObserving)
				this.eventBus.emit(HiLoEvents.User.ObserveGame);
		}
	}

	protected resize = (): void => {
		this.application.resize();

		const width: number = this.application.screen.width;
		const height: number = this.application.screen.height;

		const stage: Container = this.application.stage;

		for (const child of stage.children) {
			if (((child as unknown) as LayoutComponent).resize != undefined) {
				((child as unknown) as LayoutComponent).resize({ width, height });
			}
		}
	};

	protected update(): void {
		if (this.application) {
			this.application.render();
		}
	}

	protected displayError(code: number, title: string, message: string): void {
		console.log('ERROR', this.hasLoaded);
		if (this.hasLoaded) {
			this.errorTitle.setText(this.translations.get(title));
			this.errorMessage.setText(this.translations.get(message));
			this.errorDialogConfirm.once(Events.Interaction.Click, this.onClose, this);
			Logger.error(
				code.toString(),
				this.translations.getForLang("en", title),
				this.translations.getForLang("en", message)
			);
			this.errorDialog.visible = true;
		} else {
			this.eventBus.emit(HiLoEvents.Game.ShowPreloadError, title, message);
		}
	}

	protected onClose(): void {
		this.eventBus.emit(Events.Sound.Play, "ButtonClick");
		this.closeGame();
	}

	protected closeGame(): void {
		this.eventBus.emit(Events.Interaction.Exit);
	}

	protected mapGameInfo(response: GameInfoResponse): void {
		const currentTime: number = Date.now();

		this.model.gameMode = response.mode;
		this.model.startTime = currentTime + (response.startTime - response.timestamp);
		this.model.endTime = currentTime + (response.endTime - response.timestamp);
		this.model.prizeAmount = response.prizeAmount;
		this.model.currency = response.currency;
		this.model.roundDurationSeconds = response.roundDurationSeconds;
		this.model.cutOffTime = response.cutOffTime;
		this.model.participants = response.participants;
		this.model.minNumber = response.minNumber;
		this.model.maxNumber = response.maxNumber;

		this.model.roundNumber = response.roundNumber;
		this.model.nextRoundStartTime = response.nextRoundStartTime
			? currentTime + (response.nextRoundStartTime - response.timestamp)
			: this.model.startTime;
		this.model.currentNumber = response.currentNumber;
		this.model.numUpPlayers = response.numUpPlayers;
		this.model.numDownPlayers = response.numDownPlayers;
		this.model.numParticipantsRemaining = response.participantsRemaining;
		this.model.lastNumbers = response.previousNumbers || [];
		this.model.maxRounds = response.maxRounds || undefined;
		this.model.maxWinners = response.maxWinners || undefined;
		this.model.livesPerEntry = response.livesPerEntry;
		this.model.pointsPerRound = response.pointsPerRound;
		this.model.prizeLadder = response.prizeLadder;
	}

	protected handleServiceError(response: ErrorResponse): void {
		switch (response.code) {
			case 1:
				// Can't join - already full
				this.displayError(501, ErrorTitle.Generic, ErrorMessage.GameFull);
				break;
			case 2:
				// Can't join - already started
				this.displayError(502, ErrorTitle.Generic, ErrorMessage.GameStarted);
				break;
			case 7:
				// Can't join - already finished
				this.displayError(507, ErrorTitle.Generic, ErrorMessage.GameFinished);
				break;
			case 9:
				// Can't join - game already in last round
				this.displayError(508, ErrorTitle.Generic, ErrorMessage.TournamentEnding);
				break;
			default:
				this.displayError(104, ErrorTitle.Generic, ErrorMessage.Generic);
				break;;
		}
	}
}

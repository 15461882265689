import { FixedDimensions, Layout, LoadingBarConfig } from "./config";
import { injectable } from "inversify";
import { LayoutContainer } from "./LayoutContainer";

@injectable()
export class LoadingBar extends LayoutContainer {
	protected config: LoadingBarConfig<any>;

	protected currentProgress: number = 0;

	public constructor(layout: Layout, config: LoadingBarConfig<any>) {
		super(layout);

		this.config = config;
	}

	public setProgress(progress: number): void {
		if (progress < 0) {
			progress = 0;
		} else if (progress > 100) {
			progress = 100;
		}

		this.currentProgress = progress / 100;

		if (this.layoutDimensions != undefined) {
			this.drawBar(this.layoutDimensions.width, this.layoutDimensions.height);
		}
	}

	public resize(parentDimensions: FixedDimensions): FixedDimensions {
		const dimensions: FixedDimensions = super.resize(parentDimensions);

		this.drawBar(dimensions.width, dimensions.height);

		return dimensions;
	}

	protected drawBar(width: number, height: number): void {
		this.graphics.clear();

		const options: LoadingBarConfig<any> = this.config;
		this.graphics.beginFill(options.trackColor, options.trackAlpha || 1);
		this.graphics.drawRoundedRect(0, 0, width, height, options.cornerRadius || 0);
		this.graphics.endFill();
		this.graphics.beginFill(options.barColor, options.barAlpha || 1);
		this.graphics.drawRoundedRect(
			1,
			1,
			Math.max(0, width * this.currentProgress - 2),
			height - 2,
			options.cornerRadius || 0
		);
		this.graphics.endFill();
	}
}

import { Texture } from "pixi.js";
import { Layout } from "./config";
import { lazyInject } from "../ioc";
import { Types } from "../types/Types";
import { EventBus } from "../events";
import { Button } from "./Button";
import { ButtonFrame } from "./ButtonFrame";

export interface ToggleButtonTextureConfig {
	on: [Texture, Texture, Texture, Texture];
	off: [Texture, Texture, Texture, Texture];
}

export class ToggleButton extends Button {
	@lazyInject(Types.EventBus)
	protected eventBus!: EventBus;

	protected textureGroups: {
		on: [Texture, Texture, Texture, Texture];
		off: [Texture, Texture, Texture, Texture];
	};

	public constructor(textures: ToggleButtonTextureConfig, layout: Layout, onClickEvent?: string) {
		super(textures.on, layout, onClickEvent);

		this.textureGroups = textures;
	}

	protected toggle(): void {
		if (this.textures === this.textureGroups.on) {
			this.setState(false);
		} else {
			this.setState(true);
		}
	}

	public setState(on: boolean): void {
		this.textures = on ? this.textureGroups.on : this.textureGroups.off;

		this.texture = this.textures[this.currentFrame];
	}

	protected onMouseUp(): void {
		if (this.isEnabled) {
			this.toggle();

			this.currentFrame = ButtonFrame.Over;
			if (this.onClickEvent) {
				this.eventBus.emit(this.onClickEvent, this.textures === this.textureGroups.on);
			}
		}
	}

	protected onTouchEnd(): void {
		if (this.isEnabled) {
			this.toggle();

			this.currentFrame = ButtonFrame.Up;
			if (this.onClickEvent) {
				this.eventBus.emit(this.onClickEvent, this.textures === this.textureGroups.on);
			}
		}
	}
}

export enum ComponentType {
	Container = "Container",
	Sprite = "Sprite",
	SpriteAnimation = "SpriteAnimation",
	NineSliceSprite = "NineSliceSprite",
	Button = "Button",
	ToggleButton = "ToggleButton",
	NineSliceButton = "NineSliceButton",
	Text = "Text",
	LoadingBar = "LoadingBar",
	LeaderBoard = "LeaderBoard",
	ProgressBar = "ProgressBar"
}

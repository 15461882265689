import { injectable } from "inversify";
import { lazyInject } from "../../ioc";
import { Factories } from "../../types/Types";
import { ComponentBuilder } from "./ComponentBuilder";
import { ComponentConfig, ContainerConfig, LayoutConfig } from "../config";
import { LayoutComponent } from "../LayoutComponent";

@injectable()
/**
 * The GameBuilder processes the overall LayoutConfig and builds all the components defined within.
 *
 * @see LayoutConfig
 */
export class GameBuilder {
	@lazyInject(Factories.ComponentBuilder)
	protected readonly builderFactory!: (name: string) => ComponentBuilder;

	public build(config: LayoutConfig<any>): LayoutComponent[] {
		return config.components.map(
			(config: ComponentConfig<any>): LayoutComponent => {
				return this.buildComponent(config);
			}
		);
	}

	public buildComponent(config: ComponentConfig<any>): LayoutComponent {
		const builder: ComponentBuilder = this.builderFactory(config.type);
		const result: LayoutComponent = builder.build(config);

		const containerConfig: ContainerConfig<any, any> = config as ContainerConfig<any, any>;
		if (containerConfig.children != undefined) {
			for (const child of containerConfig.children) {
				result.addChild(this.buildComponent(child));
			}
		}

		return result;
	}
}

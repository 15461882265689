import { Graphics, Texture } from "pixi.js";
import { ColorBackground, CustomRoundedCorners, Gradient, GradientDirection } from "../components/config";
import { ColorStop } from "../components/config/ColorStop";

/**
 * The GraphicsUtils class contains some helper methods for commonly used graphics tasks in the framework.
 */
export class GraphicsUtils {
	/**
	 * Creates a sequence of Texture objects from the base name and number of frames provided.
	 *
	 * @param baseName The base name of the textures; everything up to but not including the frame numbers
	 * @param numFrames The number of frames.
	 */
	public static createTextureSequence(baseName: string, numFrames: number): Texture[] {
		const result: Texture[] = [];

		let frameNumber: string;
		for (let i = 0; i < numFrames; i++) {
			frameNumber = "";

			if (i < 10) {
				frameNumber += "0";
			}

			if (numFrames > 99 && i < 100) {
				frameNumber += "0";
			}

			frameNumber += i;

			result.push(Texture.from(baseName + frameNumber));
		}

		return result;
	}

	/**
	 * Helper used to draw a background from the ColorBackground definition, which also supports gradients and rounded
	 * corners.
	 *
	 * @param graphics The graphics object in which to draw the background
	 * @param background The background definition.
	 * @param width The width of the background to draw.
	 * @param height The height of the background to draw.
	 */
	public static drawBackground(graphics: Graphics, background: ColorBackground, width: number, height: number): void {
		graphics.clear();
		if (background.gradient != undefined) {
			const gradient: Gradient = background.gradient;
			const texture: Texture | undefined = GraphicsUtils.createGradientTexture(
				gradient.colorStops,
				width,
				height,
				gradient.direction
			);

			if (texture != undefined) {
				graphics.beginTextureFill({ texture });
			} else {
				graphics.beginFill(background.color, 1);
			}
		} else {
			graphics.beginFill(background.color, 1);
		}

		if (background.cornerRadius != undefined) {
			if (typeof background.cornerRadius === "number") {
				graphics.drawRoundedRect(0, 0, width, height, background.cornerRadius as number);
			} else {
				this.drawCustomRoundedRect(background.cornerRadius, graphics, width, height);
			}
		} else {
			graphics.drawRect(0, 0, width, height);
		}

		graphics.endFill();

		graphics.alpha = background.alpha != undefined ? background.alpha : 1;
	}

	private static drawCustomRoundedRect(
		cornerRadius: CustomRoundedCorners,
		graphics: PIXI.Graphics,
		width: number,
		height: number
	) {
		if (cornerRadius.topLeft) {
			graphics.moveTo(0, cornerRadius.topLeft);
			graphics.arcTo(0, 0, cornerRadius.topLeft, 0, cornerRadius.topLeft);
		} else {
			graphics.moveTo(0, 0);
		}

		if (cornerRadius.topRight) {
			graphics.lineTo(width - cornerRadius.topRight, 0);
			graphics.arcTo(width, 0, width, cornerRadius.topRight, cornerRadius.topRight);
		} else {
			graphics.lineTo(width, 0);
		}

		if (cornerRadius.bottomRight) {
			graphics.lineTo(width, height - cornerRadius.bottomRight);
			graphics.arcTo(width, height, width - cornerRadius.bottomRight, height, cornerRadius.bottomRight);
		} else {
			graphics.lineTo(width, height);
		}

		if (cornerRadius.bottomLeft) {
			graphics.lineTo(cornerRadius.bottomLeft, height);
			graphics.arcTo(0, height, 0, height - cornerRadius.bottomLeft, cornerRadius.bottomLeft);
		} else {
			graphics.lineTo(0, height);
		}
	}

	/**
	 * Creates a canvas object in order to draw a gradient, as this functionality is not available in pixi itself.
	 *
	 * @param colorStops The color stops that define the gradient.
	 * @param width The width of the canvas to create.
	 * @param height The height of the canvas to create.
	 * @param direction The direction the gradient should be drawn.
	 */
	public static createGradientCanvas(
		colorStops: Array<ColorStop>,
		width: number,
		height: number,
		direction: GradientDirection
	): HTMLCanvasElement | undefined {
		const canvas = document.createElement("canvas");
		const context = canvas.getContext("2d");
		if (!context) {
			throw new Error("Unable to get 2d context");
		}

		canvas.width = width;
		canvas.height = height;
		const gradient: CanvasGradient = this.createLinearGradient(context, width, height, direction);
		for (const stop of colorStops) {
			gradient.addColorStop(stop.offset, stop.color);
		}
		context.fillStyle = gradient;
		context.fillRect(0, 0, width, height);

		return canvas;
	}

	/**
	 * Creates a gradient and then converts it into a pixi Texture object.
	 *
	 * @param colorStops The color stops that define the gradient.
	 * @param width The width of the canvas to create.
	 * @param height The height of the canvas to create.
	 * @param direction The direction the gradient should be drawn.
	 */
	public static createGradientTexture(
		colorStops: Array<ColorStop>,
		width: number,
		height: number,
		direction: GradientDirection
	): Texture | undefined {
		const canvas: HTMLCanvasElement | undefined = this.createGradientCanvas(colorStops, width, height, direction);

		return canvas && Texture.from(canvas);
	}

	protected static createLinearGradient(
		context: CanvasRenderingContext2D,
		width: number,
		height: number,
		direction: GradientDirection
	): CanvasGradient {
		switch (direction) {
			case GradientDirection.ToTopLeft:
				return context.createLinearGradient(width, height, 0, 0);
				break;
			case GradientDirection.ToTop:
				return context.createLinearGradient(0, height, 0, 0);
				break;
			case GradientDirection.ToTopRight:
				return context.createLinearGradient(0, height, width, 0);
				break;
			case GradientDirection.ToRight:
				return context.createLinearGradient(0, 0, width, 0);
				break;
			case GradientDirection.ToBottomRight:
				return context.createLinearGradient(0, 0, width, height);
				break;
			default:
			case GradientDirection.ToBottom:
				return context.createLinearGradient(0, 0, 0, height);
				break;
			case GradientDirection.ToBottomLeft:
				return context.createLinearGradient(width, 0, 0, height);
				break;
			case GradientDirection.ToLeft:
				return context.createLinearGradient(width, 0, 0, 0);
				break;
		}
	}
}

import { ComponentType, HAlign, NineSliceSpriteConfig, TextAlign, VAlign } from "@tournament/ui-core";
import { Components, HiLoComponentType } from "@tournament/hilo-ui-core";
import { SkinComponentType } from "../../types/SkinComponentType";
import { SkinDisplayChild } from "../../components/config/SkinDisplayChild";

export const LeaderboardNoMoreEntriesDialog: NineSliceSpriteConfig<
	SkinDisplayChild,
	HiLoComponentType | SkinComponentType
> = {
	id: Components.LeaderboardNoMoreEntriesDialog,
	type: ComponentType.NineSliceSprite,
	slices: {
		texture: "GameOverDialog",
		leftWidth: 23,
		topHeight: 23,
		rightWidth: 23,
		bottomHeight: 23
	},
	visible: false,
	layout: {
		vAlign: VAlign.Middle,
		hAlign: HAlign.Center,
		dimensions: {
			width: 800,
			height: 200,
			widthPercent: 85,
			heightPercent: 20
		}
	},
	children: [
		{
			type: ComponentType.Text,
			text: {
				id: "noMoreTries"
			},
			style: {
				fontFamily: ["Oxygen", "sans-serif"],
				fontWeight: "700",
				fill: 0xffffff,
				align: TextAlign.Center
			},
			layout: {
				dimensions: {
					widthPercent: 80,
					heightPercent: 30
				},
				offset: {
					yPercent: -5
				},
				vAlign: VAlign.Middle,
				hAlign: HAlign.Center
			}
		},
		{
			type: ComponentType.Text,
			visible: false,
			text: {
				id: "tournamentEnds"
			},
			style: {
				fontFamily: ["Oxygen", "sans-serif"],
				fontWeight: "700",
				fill: 0xffffff,
				align: TextAlign.Center
			},
			layout: {
				dimensions: {
					widthPercent: 90,
					heightPercent: 21
				},
				offset: {
					yPercent: 20
				},
				hAlign: HAlign.Center
			}
		},
		{
			id: Components.TournamentEndCountdown,
			visible: false,
			type: ComponentType.Text,
			text: "",
			style: {
				fontFamily: ["Roboto", "sans-serif"],
				fontWeight: "700",
				fill: 0xffffff,
				align: TextAlign.Center
			},
			layout: {
				dimensions: {
					widthPercent: 80,
					heightPercent: 36
				},
				offset: {
					yPercent: 38
				},
				hAlign: HAlign.Center
			}
		},
		{
			id: Components.LeaderboardCurrentRanking,
			type: ComponentType.Text,
			visible: false,
			text: "",
			style: {
				fontFamily: ["Oxygen", "sans-serif"],
				fontWeight: "700",
				fill: 0xffffff,
				align: TextAlign.Center
			},
			layout: {
				dimensions: {
					widthPercent: 90,
					heightPercent: 14
				},
				offset: {
					yPercent: 72
				},
				hAlign: HAlign.Center
			}
		}
	]
};

import { CloseButton } from "./components/CloseButton";
import { SoundButton } from "./components/SoundButton";
import { Timer } from "./components/Timer";
import { SpectatorModeButton } from "./components/SpectatorMode";
import { GameOverDialog } from "./components/GameOverDialog";
import { PrizeWinDialog } from "./components/PrizeWinDialog";
import { ErrorDialog } from "./components/ErrorDialog";
import { ObserverGameOverDialog } from "./components/ObserverGameOverDialog";
import { ObserverGameWonDialog } from "./components/ObserverGameWonDialog";
import { LastManGameInfoDialog } from "./components/LastManGameInfoDialog";
import { SurvivalGameInfoDialog } from "./components/SurvivalGameInfoDialog";
import {
	BackgroundType,
	ComponentType,
	ContainerConfig,
	LayoutConfig,
	LayoutMode,
	/*LayoutType,
	ProgressBarConfig,*/
	TextAlign,
	TextFieldConfig,
	VAlign
} from "@tournament/ui-core";
import { SkinDisplayChild } from "../components/config/SkinDisplayChild";
import { Components, Controllers, HiLoComponentType } from "@tournament/hilo-ui-core";
import { SkinComponentType } from "../types/SkinComponentType";
import { LeaderboardGameInfoDialog } from "./components/LeaderboardGameInfoDialog";
import { LeaderboardGameOverDialog } from "./components/LeaderboardGameOverDialog";
import { LeaderboardNoMoreEntriesDialog } from "./components/LeaderboardNoMoreEntriesDialog";
import { LeaderboardTournamentEndedDialog } from "./components/LeaderboardTournamentEndedDialog";
import { MillionaireGameInfoDialog } from "./components/MillionaireGameInfoDialog";
import { MillionaireGameOverDialog } from "./components/MillionaireGameOverDialog";
import { MillionairePrizeOfferDialog } from "./components/MillionairePrizeOfferDialog";
import { MillionairePlayTomorrowDialog } from "./components/MillionairePlayTomorrowDialog";
import { HAlign } from "@tournament/ui-core/dist/components/config";
import { SAFE_ZONE_HEIGHT } from "./Constants";

const SoundButtonContainer: ContainerConfig<SkinDisplayChild, HiLoComponentType | SkinComponentType> = {
	type: ComponentType.Container,
	layout: {
		dimensions: { heightPercent: 90, widthPercent: 20 }
	},
	children: [SoundButton]
};

const CloseButtonContainer: ContainerConfig<SkinDisplayChild, HiLoComponentType | SkinComponentType> = {
	type: ComponentType.Container,
	layout: {
		dimensions: { heightPercent: 90, widthPercent: 20 }
	},
	children: [CloseButton]
};

const RoundMessage: TextFieldConfig<HiLoComponentType | SkinComponentType> = {
	id: Components.RoundsText,
	type: ComponentType.Text,
	layout: {
		hAlign: HAlign.Center,
		dimensions: { widthPercent: 60, heightPercent: 40 }
	},
	text: "",
	style: {
		fontFamily: ["Oxygen", "sans-serif"],
		fontWeight: "700",
		fill: 0xffffff,
		align: TextAlign.Center
	}
};


const NumRemainingMessage: TextFieldConfig<HiLoComponentType | SkinComponentType> = {
	id: Components.NumRemainingText,
	type: ComponentType.Text,
	layout: {
		hAlign: HAlign.Center,
		dimensions: { widthPercent: 80, heightPercent: 22 }
	},
	text: "",
	style: {
		fontFamily: ["Oxygen", "sans-serif"],
		fontWeight: "700",
		fill: 0xffffff,
		align: TextAlign.Center
	}
};

const Title: ContainerConfig<SkinDisplayChild, HiLoComponentType | SkinComponentType> = {
	type: ComponentType.Container,
	layout: {
		childLayout: {
			mode: LayoutMode.Vertical,
			vAlign: VAlign.Middle
		},
		vAlign: VAlign.Middle,
		hAlign: HAlign.Center,
		dimensions: { heightPercent: 100, widthPercent: 60 },
		offset: {
			yPercent: 1
		}
	},
	children: [RoundMessage, NumRemainingMessage]
};

/*const RoundProgressBar: ProgressBarConfig<HiLoComponentType | SkinComponentType> = {
	id: Components.RoundProgressBar,
	type: ComponentType.ProgressBar,
	background: {
		type: BackgroundType.Image,
		src: "RoundProgressBarBackground",
		dimensions: {
			width: 955,
			height: 37
		},
		layoutType: LayoutType.Fit
	},
	progressBar: {
		type: BackgroundType.Image,
		src: "RoundProgressBar",
		dimensions: { width: 929, height: 1 },
		padding: {
			yPercent: 24.3243,
			xPercent: 0.9424
		},
		layoutType: LayoutType.Stretch
	},
	maskProgress: true,
	layout: {
		dimensions: { width: 955, height: 37, widthPercent: 100, heightPercent: 60 },
		hAlign: HAlign.Center,
		offset: {
			yPercent: 0
		}
	}
};

const RoundProgressContainer: ContainerConfig<SkinDisplayChild, HiLoComponentType | SkinComponentType> = {
	id: Components.RoundProgressBarContainer,
	type: ComponentType.Container,
	layout: {
		dimensions: { widthPercent: 100, heightPercent: 10 }
	},
	children: [
		{
			type: ComponentType.Container,
			layout: {
				hAlign: HAlign.Center,
				childLayout: {
					mode: LayoutMode.Horizontal,
					hAlign: HAlign.Center,
					padding: 2
				},
				dimensions: { widthPercent: 96, heightPercent: 35 }
			},
			children: [
				{
					id: Components.AfterRoundMessage,
					type: ComponentType.Text,
					text: {
						id: "millionaireRoundsRemaining",
						values: {
							rounds: 5
						}
					},
					layout: {
						vAlign: VAlign.Bottom,
						dimensions: { widthPercent: 55, heightPercent: 85 }
					},
					style: {
						fontFamily: ["Oxygen", "sans-serif"],
						fontWeight: "700",
						fill: 0xffffff,
						align: TextAlign.Center
					}
				}
			]
		},
		{
			type: ComponentType.Container,
			layout: {
				hAlign: HAlign.Center,
				dimensions: { width: 955, height: 70, widthPercent: 96, heightPercent: 65 },
				offset: {
					yPercent: 35
				}
			},
			children: [
				RoundProgressBar,
				{
					id: Components.ProgressMinRoundValue,
					type: ComponentType.Text,
					text: "",
					layout: {
						vAlign: VAlign.Top,
						hAlign: HAlign.Left,
						dimensions: { widthPercent: 10, heightPercent: 50 },
						offset: {
							xPercent: 0.5,
							yPercent: 45
						}
					},
					style: {
						fontFamily: ["Oxygen", "sans-serif"],
						fontWeight: "700",
						fill: 0xffffff,
						align: TextAlign.Left
					}
				},
				{
					id: Components.ProgressMaxRoundValue,
					type: ComponentType.Text,
					text: "",
					layout: {
						vAlign: VAlign.Top,
						hAlign: HAlign.Right,
						dimensions: { widthPercent: 10, heightPercent: 60 },
						offset: {
							xPercent: -0.5,
							yPercent: 45
						}
					},
					style: {
						fontFamily: ["Oxygen", "sans-serif"],
						fontWeight: "700",
						fill: 0xffffff,
						align: TextAlign.Right
					}
				}
			]
		}
	]
};*/

const TitleContainer: ContainerConfig<SkinDisplayChild, HiLoComponentType | SkinComponentType> = {
	type: ComponentType.Container,
	layout: {
		childLayout: {
			mode: LayoutMode.Horizontal
		},
		dimensions: { widthPercent: 100, heightPercent: 10 }
	},
	children: [CloseButtonContainer, Title, SoundButtonContainer]
};

const PreviousNumbersContainer: ContainerConfig<SkinDisplayChild, HiLoComponentType | SkinComponentType> = {
	type: ComponentType.Container,
	layout: {
		dimensions: { widthPercent: 100, heightPercent: 7 }
	},
	children: [
		{
			id: Components.PreviousNumberBackground,
			type: ComponentType.Sprite,
			src: "LastNumbersBackground",
			alpha: 0.1,
			layout: {
				dimensions: { widthPercent: 60, heightPercent: 70 },
				vAlign: VAlign.Middle,
				hAlign: HAlign.Left
			}
		},
		{
			id: Components.PreviousNumberContainer,
			type: ComponentType.Container,
			layout: {
				dimensions: { widthPercent: 50, heightPercent: 100 },
				hAlign: HAlign.Left,
				childLayout: {
					mode: LayoutMode.Horizontal,
					hAlign: HAlign.Right,
					padding: 1
				}
			}
		},
		{
			id: Components.TournamentCountDownContainer,
			type: ComponentType.Container,
			visible: false,
			layout: {
				dimensions: { widthPercent: 30, heightPercent: 70 },
				hAlign: HAlign.Right,
				vAlign: VAlign.Middle
			},
			children: [
				{
					type: ComponentType.Sprite,
					src: "CountDownBackground",
					alpha: 0.1,
					layout: {
						dimensions: { widthPercent: 100, heightPercent: 100 },
						vAlign: VAlign.Middle,
						hAlign: HAlign.Right
					}
				},
				{
					id: Components.FinalRoundMessage,
					type: ComponentType.Text,
					text: {
						id: "finalRoundMessage"
					},
					visible: false,
					style: {
						fontFamily: ["Oxygen", "sans-serif"],
						fontWeight: "700",
						fill: 0xffffff,
						align: TextAlign.Right
					},
					layout: {
						dimensions: {
							widthPercent: 90,
							heightPercent: 90
						},
						offset: {
							xPercent: -5
						},
						hAlign: HAlign.Right,
						vAlign: VAlign.Middle
					}
				},
				{
					id: Components.TournamentCountDownTitle,
					visible: false,
					type: ComponentType.Text,
					text: {
						id: "tournamentEnds"
					},
					style: {
						fontFamily: ["Oxygen", "sans-serif"],
						fontWeight: "700",
						fill: 0xffffff,
						align: TextAlign.Right
					},
					layout: {
						dimensions: {
							widthPercent: 90,
							heightPercent: 40
						},
						offset: {
							xPercent: -5,
							yPercent: 2
						},
						hAlign: HAlign.Right,
						vAlign: VAlign.Top
					}
				},
				{
					id: Components.TournamentCountDownTimer,
					visible: false,
					type: ComponentType.Text,
					text: "",
					style: {
						fontFamily: ["Roboto", "sans-serif"],
						fontWeight: "700",
						fill: 0xffffff,
						align: TextAlign.Right
					},
					layout: {
						dimensions: {
							widthPercent: 60,
							heightPercent: 58
						},
						offset: {
							xPercent: -5,
							yPercent: -2
						},
						hAlign: HAlign.Right,
						vAlign: VAlign.Bottom
					}
				}
			]
		}
	]
};

export const HiLoConfig: LayoutConfig<SkinDisplayChild> = {
	components: [
		{
			id: Components.GameContainer,
			type: ComponentType.Container,
			layout: {
				dimensions: { widthPercent: 100, heightPercent: 100 }
			},
			children: [
				{
					id: Components.GridContainer,
					type: SkinComponentType.GridContainer,
					layout: {
						dimensions: { widthPercent: 100, heightPercent: 100 }
					},
					options: {
						maxRows: 100,
						minBoxSize: 4,
						boxSizePercent: 1.5,
						lineWeight: 2
					},
					background: {
						type: BackgroundType.Color,
						color: 0x181637
					},
					children: [
						{
							id: Components.BackgroundContainer,
							type: ComponentType.Container,
							layout: {
								dimensions: { widthPercent: 100, heightPercent: 100 }
							}
						},
						{
							id: Components.BackgroundAnimContainer,
							type: ComponentType.Container,
							layout: {
								dimensions: { widthPercent: 100, heightPercent: 100 }
							}
						},
						{
							id: Components.WinDialogAnimContainer,
							type: ComponentType.Container,
							layout: {
								dimensions: { widthPercent: 100, heightPercent: 100 }
							}
						},
						{
							id: Components.ParticleAnimContainer,
							type: ComponentType.Container,
							layout: {
								dimensions: { widthPercent: 100, heightPercent: 100 }
							}
						},
						{
							id: Components.TimerAnimContainer,
							type: ComponentType.Container,
							layout: {
								dimensions: { widthPercent: 100, heightPercent: 100 }
							}
						}
					]
				},
				{
					id: Components.ChoiceAnimContainer,
					type: ComponentType.Container,
					layout: {
						dimensions: { widthPercent: 100, heightPercent: 100 }
					}
				},
				{
					type: ComponentType.Container,
					layout: {
						childLayout: {
							mode: LayoutMode.Vertical
						},
						dimensions: { widthPercent: 100, heightPercent: 100 },
						margin: {
							height: () => window.innerHeight > window.innerWidth ? SAFE_ZONE_HEIGHT : 0,
						}
					},
					children: [
						TitleContainer,
						PreviousNumbersContainer,
						/*RoundProgressContainer,*/
						Timer,
						SpectatorModeButton
					]
				}
			]
		},
		{
			type: ComponentType.Container,
			layout: {
				dimensions: { widthPercent: 100, heightPercent: 100 },
				margin: {
					height: () => window.innerHeight > window.innerWidth ? SAFE_ZONE_HEIGHT : 0,
				}
			},
			children: [
				LastManGameInfoDialog,
				SurvivalGameInfoDialog,
				LeaderboardGameInfoDialog,
				ObserverGameOverDialog,
				ObserverGameWonDialog,
				GameOverDialog,
				LeaderboardGameOverDialog,
				PrizeWinDialog,
				LeaderboardNoMoreEntriesDialog,
				LeaderboardTournamentEndedDialog,
				MillionaireGameInfoDialog,
				MillionaireGameOverDialog,
				MillionairePrizeOfferDialog,
				MillionairePlayTomorrowDialog,
				ErrorDialog
			]
		}
	],
	controllers: [
		Controllers.UI,
		Controllers.BackgroundAnimation,
		Controllers.Choice,
		Controllers.LastNumbers,
		Controllers.TimerAnimation,
		Controllers.Sound,
		Controllers.BackgroundMusic,
		Controllers.Leaderboard,
		/*Controllers.RoundProgress*/
	]
};

import { Container, Graphics, NineSlicePlane, Sprite, Texture } from "pixi.js";
import { injectable } from "inversify";
import { LayoutComponent } from "./LayoutComponent";
import { lazyInject } from "../ioc";
import { Types } from "../types/Types";
import { LayoutManager } from "../layout";
import { EventBus } from "../events";
import { Background, ChildLayout, FixedDimensions, HAlign, Layout, LayoutMode, Rect, VAlign } from "./config";
import { GraphicsUtils } from "../utils";
import { BackgroundType } from "./config/BackgroundType";
import { ColorBackground } from "./config/ColorBackground";
import { LayoutType } from "./config/LayoutType";

@injectable()
export class LayoutContainer extends Container implements LayoutComponent {
	@lazyInject(Types.LayoutManager)
	protected layoutManager!: LayoutManager;

	@lazyInject(Types.EventBus)
	protected eventBus!: EventBus;

	/**
	 * @inheritDoc
	 */
	public onResize: ((dimensions: FixedDimensions) => void) | undefined;

	/**
	 * The layout configuration for this component.
	 */
	protected layoutConfig: Layout;
	/**
	 * The layout dimensions calculated for the component (does not necessarily match the actual dimensions!)
	 */
	protected layoutDimensions: Rect | undefined;

	/**
	 * A background color or image to be displayed within the container.
	 */
	protected background: Background | undefined;

	/**
	 * The background image to be displayed. Only set when Image or NineSliceImage backgrounds are used.
	 */
	protected backgroundImage: Sprite | NineSlicePlane | undefined;

	/**
	 * Contains the background color for a Color background.
	 */
	protected graphics: Graphics;

	protected totalChildWidth: number = 0;
	protected totalChildHeight: number = 0;
	protected totalPadding: number = 0;

	public constructor(layout: Layout) {
		super();

		this.layoutConfig = layout;

		this.graphics = new Graphics();
		this.addChild(this.graphics);
	}

	/**
	 * @inheritDoc
	 */
	public getLayoutDimensions(): Rect | undefined {
		return this.layoutDimensions;
	}

	/**
	 * Sets the background.
	 *
	 * @param background The Background definition
	 */
	public setBackground(background: Background): void {
		this.background = background;

		if (this.background && this.background.type !== BackgroundType.Color) {
			this.createBackgroundImage();
		}
	}

	/**
	 * @inheritDoc
	 */
	public getWidth(): number {
		return (this.layoutDimensions && this.layoutDimensions.width) || this.width;
	}

	/**
	 * @inheritDoc
	 */
	public getHeight(): number {
		return (this.layoutDimensions && this.layoutDimensions.height) || this.height;
	}

	/**
	 * @inheritDoc
	 */
	public setXPosition(value: number): void {
		if (this.layoutDimensions) {
			this.x = value;
			this.layoutDimensions.x = value;
			this.alignChildren();
		}
	}

	/**
	 * @inheritDoc
	 */
	public setYPosition(value: number): void {
		if (this.layoutDimensions) {
			this.y = value;
			this.layoutDimensions.y = value;
			this.alignChildren();
		}
	}

	/**
	 * @inheritDoc
	 */
	public setXOffset(value: number): void {
		if (this.layoutDimensions) {
			this.x = value + this.layoutDimensions.x;
		}
	}

	/**
	 * @inheritDoc
	 */
	public setYOffset(value: number): void {
		if (this.layoutDimensions) {
			this.y = value + this.layoutDimensions.y;
		}
	}

	/**
	 * @inheritDoc
	 */
	public resize(parentDimensions: FixedDimensions): FixedDimensions {
		const prevDimensions: Rect | undefined = this.layoutDimensions;

		try {
			const layoutDimensions: Rect = this.layoutManager.calculate(this.layoutConfig, parentDimensions);

			if (this.background != undefined) {
				if (this.background.type === BackgroundType.Color) {
					this.drawBackgroundColor(layoutDimensions);
				} else {
					this.resizeBackground(layoutDimensions);
				}
			}

			this.x = layoutDimensions.x;
			this.y = layoutDimensions.y;
			// Setting the width/height of the container will cause the container to scale ALL of its  content to fit to
			// exactly that width and height. This creates a huge amount of undesired behaviour.
			// To solve this, store the calculated dimensions and send those to children so any children can correctly
			// position themselves.
			this.layoutDimensions = layoutDimensions;
		} catch (error) {
			console.warn("Error resizing:", error);
			this.layoutDimensions = prevDimensions || { x: 0, y: 0, width: 1, height: 1 };
		}

		this.resizeChildren(parentDimensions, this.layoutDimensions);

		if (this.onResize != undefined) {
			this.onResize.call(this, this.layoutDimensions);
		}

		return { width: this.layoutDimensions.width, height: this.layoutDimensions.height };
	}

	protected drawBackgroundColor(layoutDimensions: Rect):  void {
		GraphicsUtils.drawBackground(
			this.graphics as Graphics,
			this.background as ColorBackground,
			layoutDimensions.width,
			layoutDimensions.height
		);
	}

	protected createBackgroundImage(): void {
		if (this.background) {
			if (this.background.type === BackgroundType.Image) {
				this.backgroundImage = Sprite.from(this.background.src);
				this.backgroundImage.alpha = this.background.alpha || 1;
			} else if (this.background.type === BackgroundType.NineSliceImage) {
				this.backgroundImage = new NineSlicePlane(Texture.from(this.background.src), this.background.slices.leftWidth, this.background.slices.topHeight, this.background.slices.rightWidth, this.background.slices.bottomHeight);
				this.backgroundImage.alpha = this.background.alpha || 1;
			}

			this.addChild(this.backgroundImage as Sprite | NineSlicePlane);
		}
	}

	protected resizeBackground(layoutDimensions: Rect): void {
		if (this.background && this.backgroundImage) {
			if (this.background.type === BackgroundType.Image) {
				const width: number = this.background.dimensions.width;
				const height: number = this.background.dimensions.height;
				const aspectRatio: number = width / height;

				switch (this.background.layoutType) {
					case LayoutType.Fit:
						if (Math.floor(layoutDimensions.height * aspectRatio) > layoutDimensions.width) {
							this.backgroundImage.width = layoutDimensions.width;
							this.backgroundImage.height = Math.floor(layoutDimensions.width / aspectRatio);
						} else {
							this.backgroundImage.height = layoutDimensions.height;
							this.backgroundImage.width = Math.floor(layoutDimensions.height * aspectRatio);
						}
						break;
					case LayoutType.Stretch:
						this.backgroundImage.width = layoutDimensions.width;
						this.backgroundImage.height = layoutDimensions.height;
						break;
					case LayoutType.OverflowX:
						this.backgroundImage.height = layoutDimensions.height;
						this.backgroundImage.width = Math.floor(layoutDimensions.height * aspectRatio);
						break;
					case LayoutType.OverflowY:
						this.backgroundImage.width = layoutDimensions.width;
						this.backgroundImage.height = Math.floor(layoutDimensions.width / aspectRatio);
						break;
				}

				this.backgroundImage.x = (layoutDimensions.width - this.backgroundImage.width) / 2;
				this.backgroundImage.y = (layoutDimensions.height - this.backgroundImage.height) / 2;

			} else if (this.background.type === BackgroundType.NineSliceImage) {
				this.backgroundImage.width = layoutDimensions.width;
				this.backgroundImage.height = layoutDimensions.height;
			}
		}
	}

	protected resizeChildren(parentDimensions: FixedDimensions, layoutDimensions: Rect): void {
		try {
			const childLayout = this.layoutConfig.childLayout;
			const padding: number = this.getChildPadding(childLayout, parentDimensions);

			this.totalPadding = 0;
			let totalChildWidth: number = 0;
			let totalChildHeight: number = 0;
			let childDimensions: FixedDimensions;
			let child: LayoutComponent;
			let numLayoutChildren: number = 0;
			for (let i = 0; i < this.children.length; i++) {
				if (
					this.getIgnoredResizeChildren().indexOf(this.children[i]) === -1 &&
					((this.children[i] as unknown) as LayoutComponent).resize != undefined
				) {
					child = (this.children[i] as unknown) as LayoutComponent;
					childDimensions = child.resize(layoutDimensions);

					if (childLayout != undefined) {
						if (childLayout.mode === LayoutMode.Horizontal) {
							((child as unknown) as LayoutComponent).setXPosition(totalChildWidth + this.totalPadding);
							totalChildWidth += childDimensions.width;
							totalChildHeight = Math.max(totalChildHeight, childDimensions.height);
						} else if (childLayout.mode === LayoutMode.Vertical) {
							((child as unknown) as LayoutComponent).setYPosition(totalChildHeight + this.totalPadding);
							totalChildWidth = Math.max(totalChildWidth, childDimensions.width);
							totalChildHeight += childDimensions.height;
						}

						if (i < this.children.length - 1) {
							this.totalPadding += padding;
						}
					}

					numLayoutChildren++;
				}
			}

			this.totalChildWidth = totalChildWidth;
			this.totalChildHeight = totalChildHeight;

			this.alignChildren();
		} catch (error) {
			console.warn("Error resizing children:", error);
		}
	}

	protected getIgnoredResizeChildren(): any[] {
		return [this.background];
	}

	protected getChildPadding(childLayout: ChildLayout | undefined, parentDimensions: FixedDimensions) {
		let padding: number = 0;
		if (childLayout != undefined && childLayout.padding) {
			if (childLayout.mode === LayoutMode.Horizontal) {
				padding = (childLayout.padding / 100) * parentDimensions.width;
			} else if (childLayout.mode === LayoutMode.Vertical) {
				padding = (childLayout.padding / 100) * parentDimensions.height;
			}
		}

		return padding;
	}

	protected alignChildren(): void {
		const layoutDimensions: Rect | undefined = this.layoutDimensions;

		if (layoutDimensions != undefined) {
			if (this.children.length > 0 && this.layoutConfig.childLayout != undefined) {
				let numLayoutChildren: number = 0;
				const childLayout = this.layoutConfig.childLayout;
				const totalChildWidth: number = this.totalChildWidth;
				const totalChildHeight: number = this.totalChildHeight;
				let child: LayoutComponent;
				for (let i: number = 0; i < this.children.length; i++) {
					if (
						this.children[i] !== this.backgroundImage &&
						((this.children[i] as unknown) as LayoutComponent).resize != undefined
					) {
						child = (this.children[i] as unknown) as LayoutComponent;

						if (childLayout.mode === LayoutMode.Horizontal) {
							switch (childLayout.hAlign) {
								case HAlign.Center:
									child.setXOffset(
										(layoutDimensions.width - (totalChildWidth + this.totalPadding)) / 2
									);
									break;
								case HAlign.Right:
									child.setXOffset(layoutDimensions.width - (totalChildWidth + this.totalPadding));
									break;
								default:
									// Left aligned, do nothing.
									break;
							}

							switch (childLayout.vAlign) {
								case VAlign.Middle:
									child.setYOffset((layoutDimensions.height - child.getHeight()) / 2);
									break;
								case VAlign.Bottom:
									child.setYOffset(
										layoutDimensions.y + (layoutDimensions.height - child.getHeight())
									);
									break;
								default:
									// Top aligned, do nothing.
									break;
							}
						} else if (childLayout.mode === LayoutMode.Vertical) {
							switch (childLayout.hAlign) {
								case HAlign.Center:
									child.setXOffset((layoutDimensions.width - child.getWidth()) / 2);
									break;
								case HAlign.Right:
									child.setXOffset(layoutDimensions.x + (layoutDimensions.width - child.getWidth()));
									break;
								default:
									// Left aligned, do nothing.
									break;
							}

							switch (childLayout.vAlign) {
								case VAlign.Middle:
									child.setYOffset(
										(layoutDimensions.height - (totalChildHeight + this.totalPadding)) / 2
									);
									break;
								case VAlign.Bottom:
									child.setYOffset(layoutDimensions.height - (totalChildHeight + this.totalPadding));
									break;
								default:
									// Top aligned, do nothing.
									break;
							}
						}

						numLayoutChildren++;
					}
				}
			}
		}
	}
}

export class HTMLUtils {
	/**
	 * Returns the value of a query variable, if one is defined matching the name provided.
	 *
	 * @param name The name of the query variable.
	 */
	public static getQueryVariable(name: string): string | undefined {
		const query: string = window.location.search.substring(1);
		const vars: string[] = query.split("&");
		for (let i = 0; i < vars.length; i++) {
			const values: string[] = vars[i].split("=");
			if(values[0] == name) {
				return values[1];
			}
		}

		return undefined;
	}
}

export enum Controllers {
	Choice = "ChoiceController",
	UI = "UIController",
	Sound = "SoundController",
	Preload = "PreloadController",
	BackgroundAnimation = "BackgroundAnimationController",
	LastNumbers = "LastNumbersController",
	TimerAnimation = "TimerAnimationController",
	Leaderboard = "LeaderboardModeController",
	RoundProgress = "RoundProgrressController",
	ClientAPI = "ClientAPIController",
	BackgroundMusic = "BackgroundMusicController"
}

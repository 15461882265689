/**
 * A simple Node implementation for use in linked lists.
 *
 * @see LinkedList
 */
export class LinkNode<T> {
	/**
	 * A reference to the previous node in the list, if any.
	 */
	public prev: LinkNode<T> | undefined;
	/**
	 * A reference to the next node in the list, if any.
	 */
	public next: LinkNode<T> | undefined;
	/**
	 * The value of this node.
	 */
	private value: T;
	
	public constructor(value: T) {
		this.value = value;
	}

	/**
	 * Returns the value for the current node.
	 */
	public getValue(): T {
		return this.value;
	}
}
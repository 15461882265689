import { ComponentType, ContainerConfig, HAlign, TextAlign, VAlign } from "@tournament/ui-core";
import { Components, HiLoComponentType, HiLoEvents } from "@tournament/hilo-ui-core";
import { SkinComponentType } from "../../types/SkinComponentType";
import { SkinDisplayChild } from "../../components/config/SkinDisplayChild";
export const SpectatorModeButton: ContainerConfig<SkinDisplayChild, HiLoComponentType | SkinComponentType> = {
    id: Components.SpectatorModeButton,
	type: ComponentType.Container,
	layout: {
		dimensions: { heightPercent: 15, widthPercent: 100 },
		vAlign: VAlign.Middle,
		hAlign: HAlign.Center,
		offset: {
			yPercent: 0
		}
	},
	children: [
		{
			id: Components.ExitSpectatorMode,
			type: ComponentType.Button,
			textures: {
				up: "Join_button_Original.png",
				over: "Join_button_Original.png",
				down: "Join_button_down.png",
				disabled: "Join_button_Original.png"
			},
			clickEvent: HiLoEvents.User.ExitSpectatorMode,
			layout: {
				dimensions: { width: 114, height: 114, heightPercent: 100, widthPercent: 100 },
				offset: {
					yPercent: -130
				},
				hAlign: HAlign.Center
			}
		},
        {
			id: Components.JoinText,
			type: ComponentType.Text,
			text: "JOIN",
			style: {
				fontFamily: ["Oxygen", "sans-serif"],
				fontWeight: "700",
				fill: 0xffffff,
				align: TextAlign.Center
			},
			layout: {
				dimensions: { widthPercent: 100, heightPercent: 20 },
				hAlign: HAlign.Center,
				vAlign: VAlign.Middle,
				offset: {
					yPercent: -130
				}
			}
		},
        {
			id: Components.SpectatorModeText,
			type: ComponentType.Text,
			text: "SPECTATOR MODE",
			style: {
				fontFamily: ["Oxygen", "sans-serif"],
				fontWeight: "700",
				fill: 0xffffff,
				align: TextAlign.Center
			},
			layout: {
				dimensions: { widthPercent: 100, heightPercent: 20 },
				hAlign: HAlign.Center,
				vAlign: VAlign.Middle,
				offset: {
					yPercent: -50
				}
			}
		}
	]
};

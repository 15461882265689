import { NineSlicePlane, Texture } from "pixi.js";
import { lazyInject } from "../ioc";
import { Types } from "../types/Types";
import { EventBus } from "../events";
import { LayoutContainer } from "./LayoutContainer";
import { FixedDimensions, Layout } from "./config";

export class NineSliceSprite extends LayoutContainer {
	@lazyInject(Types.EventBus)
	protected eventBus!: EventBus;

	protected slicePlane: NineSlicePlane;

	public constructor(
		layout: Layout,
		texture: Texture,
		leftWidth?: number,
		topHeight?: number,
		rightWidth?: number,
		bottomHeight?: number
	) {
		super(layout);

		this.slicePlane = new NineSlicePlane(texture, leftWidth, topHeight, rightWidth, bottomHeight);
		this.addChild(this.slicePlane);
	}

	public resize(parentDimensions: FixedDimensions): FixedDimensions {
		const dimensions: FixedDimensions = super.resize(parentDimensions);

		this.slicePlane.width = dimensions.width;
		this.slicePlane.height = dimensions.height;

		return dimensions;
	}
}

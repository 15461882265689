import { AnimatedSprite, Texture, Ticker } from "pixi.js";
import { injectable } from "inversify";
import { lazyInject } from "../ioc";
import { Types } from "../types/Types";
import { LayoutComponent } from "./LayoutComponent";
import { LayoutManager } from "../layout";
import { EventBus, Events } from "../events";
import { FixedDimensions, Layout, Rect } from "./config";
import { LayoutSprite } from "./LayoutSprite";

@injectable()
export class LayoutSpriteAnimation extends LayoutSprite implements LayoutComponent {
	@lazyInject(Types.LayoutManager)
	protected layoutManager!: LayoutManager;

	@lazyInject(Types.EventBus)
	protected eventBus!: EventBus;

	/**
	 * @inheritDoc
	 */
	public onResize: ((dimensions: FixedDimensions) => void) | undefined;

	/**
	 * The layout configuration for this component.
	 */
	protected layoutConfig: Layout;
	/**
	 * The layout dimensions calculated for the component (does not necessarily match the actual dimensions!)
	 */
	protected layoutDimensions: Rect | undefined;

	/**
	 * The list of textures or frame objects that make up the animation.
	 */
	protected textures: Texture[] | AnimatedSprite.FrameObject[];
	/**
	 * The duration of the tween.
	 */
	protected duration: number = 0;

	/**
	 * Small number used to account for IEEE-754 rounding errors.
	 */
	protected roundingAdjustment: number = 0.0005;
	/**
	 * True if the tween is currently playing.
	 */
	protected _isPlaying: boolean = false;
	/**
	 * True if the tween is currently paused.
	 */
	protected _isPaused: boolean = false;
	/**
	 * The number of times the tween should repeat before it automatically stops.
	 */
	protected numRepeats: number = 0;

	/**
	 * The current time in frames that the animation has been playing for.
	 */
	protected time: number = 0;
	/**
	 * The total time in frames that the tween has been playing for.
	 */
	protected rawTime: number = 0;
	/**
	 * The current frame in the tween.
	 */
	protected currentFrame: number = 0;

	/**
	 * The parent that controls the animation, if any. If an animation has no parent, it will handle its own updates
	 * when played. This should be set when multiple animations need to be synchronised together.
	 */
	protected _animationParent: any;

	public set animationParent(value: any) {
		if (this._isPlaying && !this._isPaused) {
			Ticker.shared.remove(this.update, this);
		}

		this.animationParent = value;
	}

	public get animationParent(): any {
		return this._animationParent;
	}

	public constructor(textures: Texture[] | AnimatedSprite.FrameObject[], layout: Layout) {
		super(textures[0] instanceof Texture ? textures[0] : textures[0].texture, layout);

		this.textures = textures;
		
		this.duration = textures.length;

		this.roundPixels = true;

		this.layoutConfig = layout;
	}

	/**
	 * Returns the total duration of the animation.
	 */
	protected getTotalDuration(): number {
		// As we start from frame 0 instead of 1, we need to remove 1 from the final total.
		return this.numRepeats === -1 ? Number.MAX_SAFE_INTEGER : this.duration * (this.numRepeats + 1) - 1;
	}

	/**
	 * Plays the tween.
	 */
	public play(): void {
		this.rawTime = 0;
		this.time = 0;
		this.currentFrame = 0;

		if (!this._isPlaying) {
			this._isPlaying = true;
			if (this.animationParent == undefined) {
				Ticker.shared.add(this.update, this);
			}
		} else if (this._isPaused) {
			this.resume();
		}
	}

	/**
	 * Pauses the tween while in progress.
	 *
	 * @see resume
	 */
	public pause(): void {
		if (!this._isPaused) {
			this._isPaused = true;

			if (this._isPlaying && this.animationParent == undefined) {
				Ticker.shared.remove(this.update, this);
			}
		}
	}

	/**
	 * Resumes a paused tween animation.
	 *
	 * @see pause
	 */
	public resume(): void {
		if (this._isPaused) {
			this._isPaused = false;

			if (this._isPlaying && this.animationParent == undefined) {
				Ticker.shared.add(this.update, this);
			}
		}
	}

	/**
	 * Stops the animation from playing and sets the tween to the final value immediately.
	 */
	public stop(): void {
		if (this._isPlaying) {
			this._isPlaying = false;

			if (this.animationParent == undefined) {
				Ticker.shared.remove(this.update, this);
			}

			this.emit(Events.Animation.Complete, this);
		}
	}

	/**
	 * Skips to the specified frame and starts playing.
	 *
	 * @param frame The frame number to start playing from.
	 */
	public gotoAndPlay(frameNumber: number): void {
		this.goto(frameNumber);

		if (this.rawTime !== this.getTotalDuration()) {
			if (!this._isPlaying) {
				this.play();
			} else if (this._isPaused) {
				this.resume();
			}
		}
	}

	/**
	 * Skips to the specified frame and stops the animation.
	 *
	 * @param frame The frame number to skip to before stopping.
	 */
	public gotoAndStop(frameNumber: number): void {
		this.goto(frameNumber);

		if (this._isPlaying) {
			// Don't call stop directly here, as we want to stop on the specified frame, not the final frame.
			this._isPlaying = false;
			if (this.animationParent == undefined) {
				Ticker.shared.remove(this.update, this);
			}
		}
	}

	/**
	 * Skips to the specified frame.
	 *
	 * @param time The frame to skip to.
	 */
	protected goto(frameNumber: number): void {
		if (frameNumber > this.getTotalDuration()) {
			frameNumber = this.getTotalDuration();
		}

		this.rawTime = frameNumber;
		this.time = frameNumber % this.duration;

		this.updateCurrentFrame();
	}

	/**
	 * Updates the animation each frame.
	 *
	 * @param deltaFrame A delta that represents the difference between the number of frame that should have passed
	 * since the last time update was called.
	 */
	public update(deltaFrame: number): void {
		if (this._isPlaying && !this._isPaused) {
			this.rawTime += deltaFrame;
			// frames run from 0 to textures.length - 1, wrap back to 0 when we reach duration.
			this.time = (this.time + deltaFrame + this.duration) % this.duration;

			if (this.rawTime + this.roundingAdjustment >= this.getTotalDuration()) {
				this.rawTime = this.getTotalDuration();
				this.time = this.duration - 1; // frames run from 0 to textures.length - 1.
			}

			this.updateCurrentFrame();

			if (this.rawTime === this.getTotalDuration()) {
				this.stop();
			}
		}
	}

	protected updateCurrentFrame(): void {
		const frameNumber: number = Math.floor(this.time);
		
		if (this.textures[frameNumber] instanceof Texture) {
			this.texture = this.textures[frameNumber] as Texture;
		} else {
			this.texture = (this.textures[frameNumber] as AnimatedSprite.FrameObject).texture;
		}
	}

	/**
	 * @inheritDoc
	 */
	public getLayoutDimensions(): Rect | undefined {
		return this.layoutDimensions;
	}

	/**
	 * @inheritDoc
	 */
	public getWidth(): number {
		return (this.layoutDimensions && this.layoutDimensions.width) || this.width;
	}

	/**
	 * @inheritDoc
	 */
	public getHeight(): number {
		return (this.layoutDimensions && this.layoutDimensions.height) || this.height;
	}

	/**
	 * @inheritDoc
	 */
	public setXPosition(value: number): void {
		if (this.layoutDimensions) {
			this.x = value;
			this.layoutDimensions.x = value;
		}
	}

	/**
	 * @inheritDoc
	 */
	public setYPosition(value: number): void {
		if (this.layoutDimensions) {
			this.y = value;
			this.layoutDimensions.y = value;
		}
	}

	/**
	 * @inheritDoc
	 */
	public setXOffset(value: number): void {
		if (this.layoutDimensions) {
			this.x = value + this.layoutDimensions.x;
		}
	}

	/**
	 * @inheritDoc
	 */
	public setYOffset(value: number): void {
		if (this.layoutDimensions) {
			this.y = value + this.layoutDimensions.y;
		}
	}

	/**
	 * @inheritDoc
	 */
	public resize(parentDimensions: FixedDimensions): FixedDimensions {
		const prevDimensions: Rect | undefined = this.layoutDimensions;

		try {
			const { x, y, width, height } = this.layoutManager.calculate(this.layoutConfig, parentDimensions);

			this.x = x;
			this.y = y;
			this.width = width;
			this.height = height;

			this.layoutDimensions = { x, y, width, height };
		} catch (error) {
			console.warn("Error resizing:", error);
			this.layoutDimensions = prevDimensions || { x: 0, y: 0, width: 1, height: 1 };
		}

		if (this.onResize != undefined) {
			this.onResize.call(this, this.layoutDimensions);
		}

		return { width: this.layoutDimensions.width, height: this.layoutDimensions.height };
	}
}

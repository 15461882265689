import { GridFilter } from "./filters/GridFilter";
import { FixedDimensions, Layout, LayoutContainer, lazyInject, Types } from "@tournament/ui-core";
import { GameModel } from "@tournament/hilo-ui-core";

export interface GridOptions {
	maxRows: number;
	minBoxSize: number;
	boxSizePercent: number;
	lineWeight: number;
}

export class GridContainer extends LayoutContainer {
	@lazyInject(Types.GameModel)
	protected model!: GameModel;

	protected gridFilter: GridFilter;

	protected readonly maxRows: number;
	protected readonly minBoxSize: number;
	protected readonly boxSizePercent: number;
	protected readonly lineWeight: number;

	protected boxSize: number = 8;

	public constructor(layout: Layout, options: GridOptions) {
		super(layout);

		this.maxRows = options.maxRows;
		this.minBoxSize = options.minBoxSize;
		this.boxSizePercent = options.boxSizePercent;
		this.lineWeight = options.lineWeight;

		this.gridFilter = new GridFilter(this.boxSize, options.lineWeight);

		this.filters = [this.gridFilter];
	}

	protected updateGrid(height: number): void {
		this.boxSize = Math.max(this.minBoxSize, Math.floor(height * (this.boxSizePercent / this.maxRows)));

		this.gridFilter.boxSize = this.boxSize;

		this.filters = [this.gridFilter];
	}

	public resize(parentDimensions: FixedDimensions): FixedDimensions {
		const dimensions: FixedDimensions = super.resize(parentDimensions);

		this.updateGrid(dimensions.height);

		return dimensions;
	}
}

import { injectable } from "inversify";
import { NineSlicePlane, Texture } from "pixi.js";
import { ComponentBuilder } from "./ComponentBuilder";
import { LayoutComponent } from "../LayoutComponent";
import { appContainer } from "../../ioc";
import { NineSliceButtonConfig } from "../config";
import { NineSliceButton } from "../NineSliceButton";

@injectable()
/**
 * The NineSliceButtonBuilder creates Button components displayed using 9-Sliced images for each state.
 *
 * @see NineSliceButton
 * @see NineSliceButtonConfig
 */
export class NineSliceButtonBuilder implements ComponentBuilder {
	public build(config: NineSliceButtonConfig<any, any>, parentDimensions?: { width: number; height: number }): LayoutComponent {
		const upTexture: Texture = Texture.from(config.textures.up);
		const overTexture: Texture = config.textures.over ? Texture.from(config.textures.over) : upTexture;
		const downTexture: Texture = config.textures.down ? Texture.from(config.textures.down) : overTexture;
		const disabledTexture: Texture = config.textures.disabled
			? Texture.from(config.textures.disabled)
			: downTexture;

		const left: number = config.slices.left;
		const top: number = config.slices.top;
		const right: number = config.slices.right;
		const bottom: number = config.slices.bottom;

		const up: NineSlicePlane = new NineSlicePlane(upTexture, left, top, right, bottom);
		const over: NineSlicePlane = new NineSlicePlane(overTexture, left, top, right, bottom);
		const down: NineSlicePlane = new NineSlicePlane(downTexture, left, top, right, bottom);
		const disabled: NineSlicePlane = new NineSlicePlane(disabledTexture, left, top, right, bottom);

		const textures: [NineSlicePlane, NineSlicePlane, NineSlicePlane, NineSlicePlane] = [up, over, down, disabled];
		const button: NineSliceButton = new NineSliceButton(textures, config.layout, config.clickEvent);

		if (config.visible === false) {
			button.visible = false;
		}

		if (config.background) {
			button.setBackground(config.background);
		}

		if (config.id != undefined) {
			button.name = config.id;
			appContainer.bind<NineSliceButton>(config.id).toConstantValue(button);
		}

		return button;
	}
}

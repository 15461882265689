import { injectable } from "inversify";
import { lazyInject, EventBus, Types, Events, AudioAdapter } from "@tournament/ui-core";
import { HiLoEvents } from "../events";

@injectable()
export class BackgroundMusicController {
    @lazyInject(Types.EventBus)
    protected eventBus!: EventBus;

    @lazyInject(Types.AudioAdapter)
    protected audioAdapter!: AudioAdapter;
    
    protected playing: boolean = false;
    protected loaded: boolean = false;

    constructor() {
        this.eventBus.once(Events.Loading.SoundsLoaded, this.onSoundsLoaded, this);
        this.eventBus.on(HiLoEvents.Game.Init, this.start, this);
        this.eventBus.on(HiLoEvents.Game.Reset, this.start, this);
        this.eventBus.on(HiLoEvents.User.ObserveGame, this.start, this);
        this.eventBus.on(HiLoEvents.Game.GameOver, this.stop, this);
        this.eventBus.on(HiLoEvents.Game.ObserveGameOver, this.stop, this);
        this.eventBus.on(HiLoEvents.Game.ObserveGameWin, this.stop, this);
        this.loaded = !this.audioAdapter.isMuted();
    }

    protected start(): void {
        if (this.playing)
            return;

        if (this.loaded)
            this.eventBus.emit(Events.Sound.Loop, "BackgroundMusic");

        this.playing = true;
    }

    protected stop(): void {
        if (!this.playing)
            return;

        this.eventBus.emit(Events.Sound.FadeAndStop, "BackgroundMusic", 1250);
        this.playing = false;
    }

    protected onSoundsLoaded(): void {
        this.loaded = true;

        if (!this.playing)
            return;
        
        this.eventBus.emit(Events.Sound.Loop, "BackgroundMusic");
    }
}
import { UpButton } from "./UpButton";
import { ComponentType, ContainerConfig, Events, HAlign, LayoutMode, TextAlign, VAlign } from "@tournament/ui-core";
import { Components, HiLoComponentType } from "@tournament/hilo-ui-core";
import { SkinComponentType } from "../../types/SkinComponentType";
import { SkinDisplayChild } from "../../components/config/SkinDisplayChild";
import { DownButton } from "./DownButton";

const ButtonContainer: ContainerConfig<SkinDisplayChild, HiLoComponentType | SkinComponentType> = {
	id: Components.ButtonContainer,
	type: ComponentType.Container,
	layout: {
		dimensions: { widthPercent: 100, heightPercent: 25 }
	},
	children: [
		UpButton,
		{
			id: Components.NumberRangeText,
			type: ComponentType.Text,
			text: "NUMBERS RANGE FROM\n1 TO 21",
			style: {
				fontFamily: ["Oxygen", "sans-serif"],
				fontWeight: "700",
				fill: 0xffffff,
				align: TextAlign.Center
			},
			layout: {
				dimensions: { widthPercent: 30, heightPercent: 50 },
				hAlign: HAlign.Center,
				vAlign: VAlign.Middle,
				offset: {
					yPercent: 2
				}
			}
		},
		DownButton
	]
};

export const Timer: ContainerConfig<SkinDisplayChild, HiLoComponentType | SkinComponentType> = {
	type: ComponentType.Container,
	layout: {
		dimensions: { widthPercent: 100, heightPercent: 80 },
		childLayout: {
			mode: LayoutMode.Vertical,
			vAlign: VAlign.Middle
		}
	},
	children: [
		{
			id: "TimerSizer",
			type: ComponentType.Container,
			layout: {
				hAlign: HAlign.Center,
				dimensions: { width: 100, height: 125, widthPercent: 100, heightPercent: 100 },
				childLayout: {
					mode: LayoutMode.Vertical,
					vAlign: VAlign.Middle,
					hAlign: HAlign.Center
				}
			},
			children: [
				{
					id: Components.TimerContainer,
					type: ComponentType.Container,
					layout: {
						vAlign: VAlign.Top,
						dimensions: { width: 100, height: 100, widthPercent: 100, heightPercent: 75 }
					},
					children: [
						{
							id: Components.TimerBackground,
							type: ComponentType.Sprite,
							src: "Timer-Background",
							layout: {
								dimensions: { widthPercent: 100, heightPercent: 100 }
							}
						},
						{
							id: Components.TimerProgressCutoff,
							type: ComponentType.Sprite,
							src: "Timer-Progress-Cutoff",
							layout: {
								dimensions: { widthPercent: 90, heightPercent: 90 },
								hAlign: HAlign.Center,
								vAlign: VAlign.Middle
							}
						},
						{
							id: Components.TimerProgress,
							type: ComponentType.Sprite,
							src: "Timer-Progress",
							layout: {
								dimensions: { widthPercent: 90, heightPercent: 90 },
								hAlign: HAlign.Center,
								vAlign: VAlign.Middle
							}
						},
						{
							type: ComponentType.Container,
							layout: {
								hAlign: HAlign.Center,
								vAlign: VAlign.Middle,
								dimensions: { widthPercent: 60, heightPercent: 55 },
								offset: {
									yPercent: 6
								}
							},
							children: [
								{
									id: Components.GameStartingText,
									type: ComponentType.Text,
									text: "",
									style: {
										fontFamily: ["Oxygen", "sans-serif"],
										fontWeight: "700",
										fill: 0xffffff,
										align: TextAlign.Center
									},
									layout: {
										dimensions: { widthPercent: 80, heightPercent: 10 },
										vAlign: VAlign.Top,
										hAlign: HAlign.Center,
										offset: {
											yPercent: -2
										}
									}
								},
								{
									id: Components.GameStartCountDownText,
									type: ComponentType.Text,
									text: "",
									style: {
										fontFamily: ["Roboto", "sans-serif"],
										fontWeight: "700",
										fill: 0xffffff,
										align: TextAlign.Center
									},
									layout: {
										dimensions: { width: 474, height: 316, widthPercent: 100, heightPercent: 75 },
										hAlign: HAlign.Center,
										offset: {
											yPercent: 4
										}
									}
								},
								{
									id: Components.NumberSpinner,
									type: HiLoComponentType.NumberSpinner,
									layout: {
										dimensions: { width: 474, height: 316, widthPercent: 105, heightPercent: 80 },
										hAlign: HAlign.Center,
										offset: {
											yPercent: -9
										}
									}
								},
								{
									id: Components.NoMoreBetsText,
									type: ComponentType.Text,
									text: "",
									visible: false,
									style: {
										fontFamily: ["Oxygen", "sans-serif"],
										fontWeight: "700",
										fill: 0xffffff,
										align: TextAlign.Center
									},
									layout: {
										dimensions: { widthPercent: 80, heightPercent: 16 },
										vAlign: VAlign.Bottom,
										hAlign: HAlign.Center,
										offset: {
											yPercent: -3
										}
									}
								}
							]
						},
						{
							id: Components.TimerGlow,
							type: ComponentType.Sprite,
							src: "timer-glow",
							visible: false,
							layout: {
								dimensions: { widthPercent: 107, heightPercent: 107 },
								hAlign: HAlign.Center,
								vAlign: VAlign.Middle
							}
						}
					]
				},
				ButtonContainer
			]
		}
	]
};

import { injectable } from "inversify";
import { ComponentBuilder } from "./ComponentBuilder";
import { LayoutComponent } from "../LayoutComponent";
import { appContainer } from "../../ioc";
import { LoadingBar } from "../LoadingBar";
import { LoadingBarConfig } from "../config";

@injectable()
/**
 * The LoadingBarBuilder creates LoadingBar components used to display loading progress.
 *
 * This was intended to be replaced after the ProgressBar component was created, which improves functionality.
 *
 * @see LoadingBar
 * @see LoadingBarConfig
 */
export class LoadingBarBuilder implements ComponentBuilder {
	public build(config: LoadingBarConfig<any>, parentDimensions?: { width: number; height: number }): LayoutComponent {
		const loadingBar: LoadingBar = new LoadingBar(config.layout, config);
		if (config.background != undefined) {
			loadingBar.setBackground(config.background);
		}

		if (config.visible === false) {
			loadingBar.visible = false;
		}

		if (config.id != undefined) {
			loadingBar.name = config.id;
			appContainer.bind<LoadingBar>(config.id).toConstantValue(loadingBar);
		}

		return loadingBar;
	}
}

import { injectable } from "inversify";
import { GameModel } from "../models/GameModel";
import { ClientService } from "../io/ClientService";
import { Components } from "../types/Components";
import { Sprite, Texture, Ticker } from "pixi.js";
import { Direction } from "../models/Direction";
import { ErrorResponse } from "../io/response/ErrorResponse";
import { PlayGameResponse } from "../io/response/PlayGameResponse";
import {
	Button,
	ButtonFrame,
	EventBus,
	Events,
	LayoutContainer,
	lazyInject,
	PixiTween,
	Rect,
	TextField,
	Services,
	Sine,
	Tween,
	TweenProperty,
	Types
} from "@tournament/ui-core";
import { HiLoEvents } from "../events";

@injectable()
export class ChoiceController {
	@lazyInject(Types.EventBus)
	protected eventBus!: EventBus;

	@lazyInject(Types.GameModel)
	protected model!: GameModel;

	@lazyInject(Services.Client)
	protected clientService!: ClientService;

	@lazyInject(Components.HigherButton)
	protected higherButton!: Button;

	@lazyInject(Components.LowerButton)
	protected lowerButton!: Button;

	@lazyInject(Components.ChoiceAnimContainer)
	protected animContainer!: LayoutContainer;

	@lazyInject(Components.ButtonContainer)
	protected buttonContainer!: LayoutContainer;

	@lazyInject(Components.NumberRangeText)
	protected numberRangeText!: TextField;

	protected currentChoice: Direction | undefined;

	protected higherRipple: Sprite;
	protected lowerRipple: Sprite;

	protected choiceRipple: Tween | undefined;
	protected choiceRippleFade: PixiTween | undefined;

	protected currentNumber: number | undefined;

	protected isSpinning: boolean = false;

	protected isObserving: boolean = false;

	protected isGameOver: boolean = false;

	public constructor() {
		this.eventBus.on(HiLoEvents.Game.Reset, this.reset, this);

		this.eventBus.once(HiLoEvents.Game.SetUserChoice, this.setUserChoice, this);

		this.eventBus.on(HiLoEvents.User.ChooseHigher, () => {
			this.chooseDirection(Direction.Higher);
		});

		this.eventBus.on(HiLoEvents.User.ChooseLower, () => {
			this.chooseDirection(Direction.Lower);
		});

		this.eventBus.on(HiLoEvents.User.ObserveGame, this.onObserveGame, this);

		this.eventBus.on(HiLoEvents.Game.GameOver, this.onGameComplete, this);
		this.eventBus.on(HiLoEvents.Game.PrizeWin, this.onGameComplete, this);

		this.eventBus.on(HiLoEvents.Game.SpinComplete, this.onSpinComplete, this);

		this.eventBus.once(HiLoEvents.Game.Init, this.onInitGame, this);

		this.eventBus.on(HiLoEvents.Game.RoundUpdated, this.onRoundUpdated, this);
		//this.eventBus.on(HiLoEvents.User.ExitSpectatorMode, this.exitSpectatorMode, this);

		this.setChoiceEnabled(false);

		this.higherRipple = new Sprite(Texture.from("button-ripple"));
		this.higherRipple.visible = false;
		this.lowerRipple = new Sprite(Texture.from("button-ripple"));
		this.lowerRipple.scale.y = -1;
		this.lowerRipple.visible = false;
		this.animContainer.addChild(this.higherRipple, this.lowerRipple);

		Ticker.shared.add(this.update, this);
	}

	protected exitSpectatorMode(): void {
		this.setChoiceEnabled(false);
		this.buttonContainer.visible = true;
	}

	protected reset(): void {
		this.isObserving = false;
		this.isGameOver = false;
		this.isSpinning = false;
		this.currentChoice = undefined;
		this.currentNumber = undefined;
		this.buttonContainer.visible = true;

		this.clearChoiceRipple();

		this.eventBus.once(HiLoEvents.Game.SetUserChoice, this.setUserChoice, this);

		this.eventBus.once(HiLoEvents.Game.Init, this.onInitGame, this);

		this.eventBus.emit(Events.Sound.Stop, "AnticipationMusic");

		Ticker.shared.remove(this.update, this);
		Ticker.shared.add(this.update, this);
	}

	protected onInitGame(): void {
		if (this.model.startTime != undefined) {
			if (
				this.model.nextRoundStartTime != undefined &&
				this.model.nextRoundStartTime !== this.model.startTime &&
				this.model.cutOffTime != undefined
			) {
				const millisecondsToNextRound: number = Math.max(this.model.nextRoundStartTime - Date.now(), 0);

				if (!this.isSpinning && millisecondsToNextRound <= this.model.cutOffTime * 1000) {
					this.startSpin();
				} else {
					this.currentNumber = this.model.currentNumber;
					this.setChoiceEnabled(true);
				}
			}
		}
		if (this.clientService.getIsSpectatorMode()) {
			this.setChoiceEnabled(false);
			this.higherButton.tint = 0x808080;
			this.lowerButton.tint = 0x808080;
			this.numberRangeText.visible = false;
		}
	}

	protected onObserveGame(): void {
		this.isObserving = true;
		this.buttonContainer.visible = true;
		this.setChoiceEnabled(false);
		this.clearChoiceRipple();

		Ticker.shared.remove(this.update, this);
		Ticker.shared.add(this.update, this);
	}

	protected onRoundUpdated(): void {
		this.isSpinning = false;

		const newNumber: number = this.model.currentNumber as number;

		if (this.currentNumber) {
			this.currentNumber = newNumber;
			this.currentChoice = undefined;

			if (!this.isGameOver && !this.clientService.getIsSpectatorMode()) {
				this.setChoiceEnabled(true);
			}
		} else {
			this.eventBus.emit(Events.Sound.Play, "StartGame");
			this.currentNumber = newNumber;
			if (!this.clientService.getIsSpectatorMode()) {
				this.setChoiceEnabled(true);
			}
		}
	}

	protected startSpin(): void {
		this.isSpinning = true;

		if (this.currentChoice === Direction.Lower) {
			this.higherButton.setEnabled(false);
		} else if (this.currentChoice === Direction.Higher) {
			this.lowerButton.setEnabled(false);
		} else {
			this.setChoiceEnabled(false);
		}

		this.eventBus.emit(HiLoEvents.Game.StartSpin);
		this.eventBus.emit(Events.Sound.Play, "AnticipationMusic");
	}

	protected setUserChoice(direction: Direction): void {
		this.eventBus.emit(HiLoEvents.Game.ChoiceMade, direction);

		let isAfterCutoff: boolean = false;

		if (this.model.nextRoundStartTime) {
			const millisecondsToNextRound: number = Math.max(this.model.nextRoundStartTime - Date.now(), 0);

			isAfterCutoff = millisecondsToNextRound <= (this.model.cutOffTime as number) * 1000;
		}

		this.currentChoice = direction;
		// Freeze on down (highlighted) frame.
		if (direction === Direction.Lower) {
			this.clearChoiceRipple();

			this.startChoiceRipple(this.lowerRipple);

			if (this.currentNumber !== this.model.maxNumber && !isAfterCutoff) {
				this.higherButton.setEnabled(true);
			}

			this.lowerButton.setEnabled(false, ButtonFrame.Over);
		} else {
			this.clearChoiceRipple();

			this.startChoiceRipple(this.higherRipple);

			if (this.currentNumber !== this.model.minNumber && !isAfterCutoff) {
				this.lowerButton.setEnabled(true);
			}

			this.higherButton.setEnabled(false, ButtonFrame.Over);
		}
	}

	protected chooseDirection(direction: Direction): void {
		this.eventBus.emit(Events.Sound.Play, "HiLoClick");

		this.clearChoiceRipple();

		if (direction === Direction.Lower) {
			this.lowerButton.setEnabled(false, ButtonFrame.Over);
			this.higherButton.setEnabled(false);
		} else {
			this.lowerButton.setEnabled(false);
			this.higherButton.setEnabled(false, ButtonFrame.Over);
		}

		this.clientService.play(direction).then((response: PlayGameResponse | ErrorResponse) => {
			if (response instanceof ErrorResponse) {
				console.log("Play called after cut-off time");
				this.eventBus.emit(HiLoEvents.Game.ChoiceFailed);
			} else {
				this.setUserChoice(direction);

				if (direction === Direction.Higher) {
					this.eventBus.emit(HiLoEvents.Game.HigherAccepted);
				} else {
					this.eventBus.emit(HiLoEvents.Game.LowerAccepted);
				}
			}
		});
	}

	protected onSpinComplete(): void {
		this.clearChoiceRipple();
		this.eventBus.emit(Events.Sound.FadeAndStop, "AnticipationMusic", 50);
	}

	protected startChoiceRipple(target: Sprite): void {
		target.visible = true;

		this.updateChoiceRipple(target, 0);

		const rippleDuration: number = 30;

		const tweenValue: { value: number } = { value: 0 };
		const tween: Tween = new Tween(tweenValue, "value", this).fromTo(0, 1, rippleDuration, Sine.easeOut).repeat(-1);

		tween.on(Events.Animation.Update, () => {
			this.updateChoiceRipple(target, tweenValue.value);
		});
		tween.once(Events.Animation.Complete, () => {
			this.updateChoiceRipple(target, tweenValue.value);
			tween.removeAllListeners();
		});

		this.choiceRipple = tween;
		this.choiceRippleFade = new PixiTween([target], TweenProperty.Alpha, this)
			.delay(5)
			.fromTo(1, 0, 25, Sine.easeOut)
			.repeat(-1);

		this.choiceRipple.play();
		this.choiceRippleFade.play();
	}

	protected updateChoiceRipple(target: Sprite, percentageMove: number): void {
		let offset: number = target.height * 0.3 * percentageMove;

		let button: Button;
		if (target === this.higherRipple) {
			button = this.higherButton;
			offset = -offset;
		} else {
			button = this.lowerButton;
		}

		const dimensions: Rect | undefined = button.getLayoutDimensions();

		if (dimensions) {
			if (button === this.lowerButton) {
				offset += target.height;
			}

			target.width = dimensions.width;
			target.height = dimensions.height;
			target.x = button.worldTransform.tx;
			target.y = offset + button.worldTransform.ty;
		}
	}

	protected clearChoiceRipple(): void {
		if (this.choiceRipple != undefined) {
			this.choiceRipple.stop();
			this.choiceRipple = undefined;
		}

		if (this.choiceRippleFade != undefined) {
			this.choiceRippleFade.stop();
			this.choiceRippleFade = undefined;
		}

		this.higherRipple.visible = false;
		this.lowerRipple.visible = false;
	}

	protected update(deltaFrame: number): void {
		if (this.choiceRipple != undefined) {
			this.choiceRipple.update(deltaFrame);
		}

		if (this.choiceRippleFade != undefined) {
			this.choiceRippleFade.update(deltaFrame);
		}

		if (this.model.startTime != undefined) {
			if (
				this.model.nextRoundStartTime != undefined &&
				this.model.nextRoundStartTime !== this.model.startTime &&
				this.model.cutOffTime != undefined
			) {
				const millisecondsToNextRound: number = Math.max(this.model.nextRoundStartTime - Date.now(), 0);

				if (!this.isSpinning && millisecondsToNextRound <= this.model.cutOffTime * 1000) {
					this.startSpin();
				}
			}
		}
	}

	protected setChoiceEnabled(value: boolean): void {
		let isAfterCutoff: boolean = false;

		if (this.model.nextRoundStartTime) {
			const millisecondsToNextRound: number = Math.max(this.model.nextRoundStartTime - Date.now(), 0);

			isAfterCutoff = millisecondsToNextRound <= (this.model.cutOffTime as number) * 1000;
		}

		if (this.currentNumber == undefined || this.isObserving || isAfterCutoff) {
			value = false;
		}

		this.higherButton.setEnabled(value);
		this.lowerButton.setEnabled(value);

		if (value) {
			if (this.currentNumber === this.model.minNumber) {
				this.lowerButton.setEnabled(false);
			} else if (this.currentNumber === this.model.maxNumber) {
				this.higherButton.setEnabled(false);
			}
		}
	}

	protected onGameComplete(): void {
		this.isGameOver = true;
		this.buttonContainer.visible = false;
		Ticker.shared.remove(this.update, this);
		this.setChoiceEnabled(false);

		if (this.model.winners == undefined) {
			this.eventBus.once(HiLoEvents.User.ObserveGame, this.onObserveGame, this);
		}
	}
}

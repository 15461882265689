import { injectable } from "inversify";
import { FixedDimensions, RelativeDimensions , Rect, Layout, HAlign, VAlign} from "../components/config";

@injectable()
export class LayoutManager {
	/**
	 * Calculates the layout dimensions and position of an component relative to its parent.
	 *
	 * @param layout The layout configuration for the component
	 * @param parentDimensions The dimensions of the parent component.
	 */
	public calculate(layout: Layout, parentDimensions: FixedDimensions): Rect {
		const dimensions: FixedDimensions = this.calculateDimensions(layout, parentDimensions);
		const position: { x: number; y: number } = this.calculatePosition(layout, dimensions, parentDimensions);

		return {
			x: position.x,
			y: position.y,
			width: dimensions.width,
			height: dimensions.height
		};
	}

	protected calculatePosition(
		layout: Layout,
		dimensions: FixedDimensions,
		parentDimensions: FixedDimensions
	): { x: number; y: number } {
		let { x, y } = this.calculateAlignedPosition(layout, dimensions, parentDimensions);
		const offset = layout.offset;

		if (offset) {
			x += offset.x || 0;
			y += offset.y || 0;

			if (offset.xPercent) {
				x += (offset.xPercent / 100) * parentDimensions.width;
			}

			if (offset.yPercent) {
				y += (offset.yPercent / 100) * parentDimensions.height;
			}
		}

		return { x, y };
	}

	protected calculateAlignedPosition(
		layout: Layout,
		dimensions: FixedDimensions,
		parentDimensions: FixedDimensions
	): { x: number; y: number } {
		const hAlign: HAlign = layout.hAlign || HAlign.Left;
		const vAlign: VAlign = layout.vAlign || VAlign.Top;

		let x: number;
		let y: number;

		switch (hAlign) {
			case HAlign.Left:
			default:
				x = 0;
				break;
			case HAlign.Center:
				x = (parentDimensions.width - dimensions.width) / 2;
				break;
			case HAlign.Right:
				x = parentDimensions.width - dimensions.width;
				break;
		}

		switch (vAlign) {
			case VAlign.Top:
			default:
				y = 0;
				break;
			case VAlign.Middle:
				y = (parentDimensions.height - dimensions.height) / 2;
				break;
			case VAlign.Bottom:
				y = parentDimensions.height - dimensions.height;
				break;
		}

		return { x, y };
	}

	protected calculateDimensions(layout: Layout, parentDimensions: FixedDimensions): FixedDimensions {
		let width: number = 0;
		let height: number = 0;

		const dimensionsKeys = Object.keys(layout.dimensions);
		if (dimensionsKeys.indexOf("width") !== -1 && dimensionsKeys.indexOf("height") !== -1) {
			width = (layout.dimensions as FixedDimensions).width;
			height = (layout.dimensions as FixedDimensions).height;
			const aspectRatio: number = width / height;

			if (dimensionsKeys.indexOf("widthPercent") !== -1) {
				if (dimensionsKeys.indexOf("heightPercent") !== -1) {
					// If all of width, height, width percent and height percent are set, grow the container to be as
					// large as possible while keeping the aspect ratio.
					width = Math.min(
						parentDimensions.width * ((layout.dimensions as RelativeDimensions).widthPercent / 100),
						parentDimensions.height *
							((layout.dimensions as RelativeDimensions).heightPercent / 100) *
							aspectRatio
					);
					height = width / aspectRatio;
				} else {
					width = parentDimensions.width * ((layout.dimensions as RelativeDimensions).widthPercent / 100);
					height = width / aspectRatio;
				}
			} else if (dimensionsKeys.indexOf("heightPercent") !== -1) {
				height = parentDimensions.height * ((layout.dimensions as RelativeDimensions).heightPercent / 100);
				width = height * aspectRatio;
			}
		} else if (dimensionsKeys.indexOf("widthPercent") !== -1 && dimensionsKeys.indexOf("heightPercent") !== -1) {
			width = parentDimensions.width * ((layout.dimensions as RelativeDimensions).widthPercent / 100);
			height = parentDimensions.height * ((layout.dimensions as RelativeDimensions).heightPercent / 100);
		}

		if (layout.margin?.height) {
			height -= typeof layout.margin.height === 'function' ? layout.margin.height() : layout.margin.height;
		}

		if (layout.margin?.width) {
			width -= typeof layout.margin.width === 'function' ? layout.margin.width() : layout.margin.width;
		}

		return { width, height };
	}
}

import { injectable } from "inversify";
import { GameModel } from "../models/GameModel";
import { Ticker } from "pixi.js";
import { EventBus, LayoutContainer, lazyInject, TextField, Types } from "@tournament/ui-core";
import { Components } from "../types";
import { HiLoEvents } from "../events";
import { HiLoGameMode } from "../models/HiLoGameMode";

@injectable()
export class LeaderboardModeController {
	@lazyInject(Types.EventBus)
	protected eventBus!: EventBus;

	@lazyInject(Types.GameModel)
	protected model!: GameModel;

	@lazyInject(Components.TournamentCountDownContainer)
	protected countDownContainer!: LayoutContainer;

	@lazyInject(Components.TournamentCountDownTitle)
	protected countDownTitle!: TextField;

	@lazyInject(Components.TournamentCountDownTimer)
	protected countDownTimer!: TextField;

	@lazyInject(Components.FinalRoundMessage)
	protected finalRoundMessage!: TextField;

	protected timeElapsed: number = 0;

	protected readonly DISPLAY_DURATION: number = 120;

	protected isGameOver: boolean = false;

	public constructor() {
		this.countDownContainer.visible = false;
		this.eventBus.on(HiLoEvents.Game.Init, this.initTournamentTimer, this);
		this.eventBus.on(HiLoEvents.Game.Reset, this.reset, this);
		this.eventBus.on(HiLoEvents.Game.GameOver, this.onGameComplete, this);
		this.eventBus.on(HiLoEvents.Game.PrizeWin, this.onGameComplete, this);

		Ticker.shared.add(this.update, this);
	}

	protected initTournamentTimer(): void {
		if (this.model.gameMode === HiLoGameMode.Leaderboard || this.model.gameMode === HiLoGameMode.Millionaire) {
			if (
				this.model.startTime &&
				this.model.startTime <= Date.now()
			) {
				this.showTimer();
			}
		}
	}

	protected showTimer(): void {
		this.countDownContainer.visible = true;
	}

	protected update(deltaFrame: number): void {
		if (this.model.gameMode === HiLoGameMode.Leaderboard || this.model.gameMode === HiLoGameMode.Millionaire) {
			this.updateTournamentEndTimer();
		}
	}

	protected updateTournamentEndTimer(): void {
		if (this.model.endTime) {
			if (Date.now() < this.model.endTime) {
				let secondsUntilEnd: number = Math.max(Math.floor((this.model.endTime - Date.now()) / 1000), 0);

				if (secondsUntilEnd === 0) {
					this.countDownContainer.visible = false;
					return;
				} else if (
					(this.model.roundDurationSeconds && secondsUntilEnd <= this.model.roundDurationSeconds) ||
					(this.model.nextRoundStartTime && this.model.nextRoundStartTime >= this.model.endTime)
				) {
					this.showLastRoundMessage();
					return;
				}

				const hours: number = Math.floor(secondsUntilEnd / 3600);
				const minutes: number = Math.floor(secondsUntilEnd / 60) - hours * 60;

				secondsUntilEnd = secondsUntilEnd % 60;

				let timeUntilEnd: string = "";

				if (hours < 10) {
					timeUntilEnd += "0";
				}

				timeUntilEnd += hours + ":";

				if (minutes < 10) {
					timeUntilEnd += "0";
				}

				timeUntilEnd += minutes + ":";

				if (secondsUntilEnd < 10) {
					timeUntilEnd += "0";
				}

				timeUntilEnd += secondsUntilEnd;

				this.countDownTimer.setText(timeUntilEnd);
				this.countDownTimer.resize(this.countDownTimer.getParentDimensions());
			} else {
				this.countDownTimer.setText("00:00:00");
				this.countDownTimer.resize(this.countDownTimer.getParentDimensions());
			}
		}
	}

	protected showLastRoundMessage(): void {
		this.countDownTimer.visible = false;
		this.countDownTitle.visible = false;
		this.finalRoundMessage.visible = true;
		this.countDownContainer.visible = true;
	}

	protected onGameComplete(): void {
		this.isGameOver = true;
		this.countDownContainer.visible = false;
	}

	protected reset(): void {
		this.isGameOver = false;
		if (this.model.gameMode === HiLoGameMode.Leaderboard || this.model.gameMode === HiLoGameMode.Millionaire) {
			this.countDownContainer.visible = false;
			this.countDownTimer.visible = false;
			this.countDownTitle.visible = false;
			this.finalRoundMessage.visible = false;
		}
	}
}

import { Loader, LoaderResource, utils } from "pixi.js";
import { AssetConfig } from "../components/config";
import { Events } from "../events";
import { AudioAdapter } from "../audio";
import { injectable } from "inversify";
import { Howl } from "howler";
import {SoundLoaderMiddleware} from "../middleware";
import {lazyInject} from "../ioc";
import {Logger} from "../logging";
import {Types} from "../types/Types";

@injectable()
/**
 * The AssetLoader loads all of the fonts, images and sounds defined in the asset config.
 */
export class AssetLoader extends utils.EventEmitter {
	@lazyInject(Types.AudioAdapter)
	protected audioAdapter!: AudioAdapter;

	protected totalAssets: number = 0;
	protected assetsRemaining: number = 0;

	public load(config: any, loadSounds: boolean = false): void {
		const loader: Loader = new Loader();

		Object.keys(config.fonts).forEach((key: string): void => {
			loader.add(key, config.fonts[key]);
			this.totalAssets++;
		});

		Object.keys(config.images).forEach((key: string): void => {
			loader.add(key, config.images[key]);
			this.totalAssets++;
		});

		if (loadSounds) {
			loader.use(SoundLoaderMiddleware);

			Object.keys(config.sounds).forEach((key: string): void => {
				// loader.add(key, config.sounds[key]);
				const sound = new Howl({
					...config.sounds[key],
					onload: (): void => {
						this.onSoundLoaded(key, sound);
					},
					onloaderror: (soundId: number, error: any): void => {
						// Ignore failed sound loading
						Logger.error("There was an error loading", soundId, ":", error);

						// Continue after failed sound load (ignore failed sounds as we can still play!)

						// We've loaded an asset so reduce the remaining count.
						this.assetsRemaining--;

						const progress: number = Math.round(((this.totalAssets - this.assetsRemaining) / this.totalAssets) * 100);

						this.emit(Events.Loading.Progress, progress);

						if (this.totalAssets === 0) {
							this.totalAssets = this.assetsRemaining = 0;
							this.emit(Events.Loading.Complete);
						}
					}
				});

				this.totalAssets++;
			});
		}

		this.assetsRemaining = this.totalAssets;

		loader.on(Events.Loading.Progress, this.onProgress, this);
		loader.once(Events.Loading.Error, this.onError, this);
		loader.once(Events.Loading.Complete, this.onComplete, this);

		loader.load();
	}

	public loadSounds(config: any): void {
		const loader: Loader = new Loader();

		this.assetsRemaining = this.totalAssets = 0;


		Object.keys(config.sounds).forEach((key: string): void => {
			const sound = new Howl({
				...config.sounds[key],
				onload: (): void => {
					this.onSoundLoaded(key, sound);
				},
				onloaderror: (soundId: number, error: any): void => {
					Logger.error("There was an error loading", soundId, ":", error);

					// Continue after failed sound load (ignore failed sounds as we can still play!)

					// We've loaded an asset so reduce the remaining count.
					this.assetsRemaining--;

					const progress: number = Math.round(((this.totalAssets - this.assetsRemaining) / this.totalAssets) * 100);

					this.emit(Events.Loading.Progress, progress);

					if (this.assetsRemaining === 0) {
						this.totalAssets = this.assetsRemaining = 0;
						this.emit(Events.Loading.Complete);
					}
				}
			});

			this.totalAssets++;
		});

		this.assetsRemaining = this.totalAssets;
	}

	protected onSoundLoaded(key: string, sound: Howl): void {
		// We've loaded an asset so reduce the remaining count.
		this.assetsRemaining--;

		this.audioAdapter.registerSound(key, sound);

		const progress: number = Math.round(((this.totalAssets - this.assetsRemaining) / this.totalAssets) * 100);

		this.emit(Events.Loading.Progress, progress);

		if (this.assetsRemaining === 0) {
			this.totalAssets = this.assetsRemaining = 0;
			this.emit(Events.Loading.Complete);
		}
	}

	protected onProgress(loader: Loader, resource: LoaderResource): void {
		// We've loaded an asset so reduce the remaining count.
		this.assetsRemaining--;

		// If there's children, treat them as the same resource so the progress bar doesn't move while we load them.
		if (resource.children.length > 0) {
			this.assetsRemaining += resource.children.length;
		}

		const progress: number = Math.round(((this.totalAssets - this.assetsRemaining) / this.totalAssets) * 100);

		this.emit(Events.Loading.Progress, progress);
	}

	protected onError(error: Error, loader: Loader, resource: LoaderResource): void {
		loader.removeAllListeners();
		throw error;
	}

	protected onComplete(loader: Loader, object: any): void {
		loader.removeAllListeners();
		if (this.assetsRemaining === 0) {
			this.totalAssets = this.assetsRemaining = 0;
			this.emit(Events.Loading.Complete);
		}
	}
}

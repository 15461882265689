export enum ErrorMessage {
	GenericLoading = "loadingErrorMessage",
	Generic = "genericErrorMessage",
	GameFinished = "finishedErrorMessage",
	GameStarted = "startedErrorMessage",
	GameFull = "fullErrorMessage",
	TournamentEnding = "tournamentEndingMessage"
}

export enum ErrorTitle {
	Generic = "errorTitle"
}

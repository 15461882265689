export enum Types {
    EventBus = "EventBus",
    LayoutManager = "LayoutManager",
    AudioAdapter = "AudioAdapter",
    Application = "Application",
    Translations = "Translations",
    LoggingAdapter = "LoggingAdapter",
    GameModel = "GameModel",
    ApiURL = "ApiURL",
    VersionInfo = "VersionInfo"
}

export enum Builders {
    Component = "ComponentBuilder",
    Game = "GameBuilder"
}

export enum Factories {
    ComponentBuilder = "ComponentBuilderFactory"
}

export enum Services {
    Client = "ClientService",
    RNG = "RNGService"
}

import { Texture } from "pixi.js";
import { LayoutSprite } from "./LayoutSprite";
import { Layout } from "./config";
import { lazyInject } from "../ioc";
import { Types } from "../types/Types";
import { EventBus, Events } from "../events";
import { ButtonFrame } from "./ButtonFrame";

export class Button extends LayoutSprite {
	@lazyInject(Types.EventBus)
	protected eventBus!: EventBus;

	/**
	 * The textures to use for the button in order of the states; Up, Over, Down, Disabled.
	 */
	protected textures: Texture[];

	/**
	 * True if the button is enabled.
	 */
	protected isEnabled: boolean = true;

	/**
	 * The event to fire on the global event bus when the button is clicked. If not set, will dispatch a local click
	 * event instead.
	 */
	protected onClickEvent: string | undefined;

	/**
	 * The currently displayed ButtonFrame.
	 *
	 * @see ButtonFrame
	 */
	protected _currentFrame: ButtonFrame = ButtonFrame.Up;

	protected get currentFrame(): ButtonFrame {
		return this._currentFrame;
	}

	protected set currentFrame(value: ButtonFrame) {
		if (this._currentFrame !== value) {
			this._currentFrame = value;
			this.texture = this.textures[value];
		}
	}

	public constructor(textures: [Texture, Texture, Texture, Texture], layout: Layout, onClickEvent?: string) {
		super(textures[ButtonFrame.Up], layout);

		this.onClickEvent = onClickEvent;

		this.textures = textures;
		this.buttonMode = true;
		this.interactive = true;

		this.addListeners();
	}

	protected addListeners(): void {
		this.removeAllListeners();
		this.on("mouseover", this.onMouseOver, this);

		this.on("mousedown", this.onMouseDown, this);
		this.on("touchstart", this.onMouseDown, this);

		this.on("mouseup", this.onMouseUp, this);
		// As there is no touch out event, touch end should go straight to the up state.
		this.on("touchend", this.onTouchEnd, this);

		this.on("mouseout", this.onMouseOut, this);

		this.on("mouseupoutside", this.onMouseUpOutside, this);
		this.on("touchendoutside", this.onMouseUpOutside, this);
	}

	protected onMouseOver(): void {
		if (this.isEnabled && this.currentFrame === ButtonFrame.Up) {
			this.currentFrame = ButtonFrame.Over;
		}
	}

	protected onMouseDown(): void {
		if (this.isEnabled) {
			this.currentFrame = ButtonFrame.Down;
		}
	}

	protected onMouseUp(): void {
		if (this.isEnabled) {
			this.currentFrame = ButtonFrame.Over;
			if (this.onClickEvent) {
				this.eventBus.emit(this.onClickEvent);
			} else {
				this.emit(Events.Interaction.Click);
			}
		}
	}

	protected onTouchEnd(): void {
		if (this.isEnabled) {
			this.currentFrame = ButtonFrame.Up;
			if (this.onClickEvent) {
				this.eventBus.emit(this.onClickEvent);
			} else {
				this.emit(Events.Interaction.Click);
			}
		}
	}

	protected onMouseOut(): void {
		if (this.isEnabled && this.currentFrame !== ButtonFrame.Down) {
			this.currentFrame = ButtonFrame.Up;
		}
	}

	protected onMouseUpOutside(): void {
		if (this.isEnabled) {
			this.currentFrame = ButtonFrame.Up;
		}
	}

	/**
	 * Enables or disables the button. Optionally forces a specific frame when called.
	 *
	 * @param value True if the button should be enabled.
	 * @param forceFrame
	 */
	public setEnabled(value: boolean, forceFrame?: ButtonFrame): void {
		this.isEnabled = value;
		this.buttonMode = value;
		this.interactive = value;

		if (this.isEnabled) {
			this.addListeners();
			this.currentFrame = forceFrame ? forceFrame : ButtonFrame.Up;
		} else {
			this.removeAllListeners();
			this.currentFrame = forceFrame ? forceFrame : ButtonFrame.Disabled;
		}
	}
}

export class Events {
	// Needed to work with pixi typings.
	protected static Error: "error" = "error";
	protected static Progress: "progress" = "progress";
	protected static Complete: "complete" = "complete";

	public static readonly Loading = {
		Start: "StartLoading",
		LoadSounds: "LoadSounds",
		SoundsLoaded: "SoundsLoaded",
		Error: Events.Error,
		Progress: Events.Progress,
		Complete: Events.Complete
	};

	public static readonly Interaction = {
		Click: "clicked",
		Exit: "Exit"
	};

	public static readonly Sound = {
		Play: "PlaySound",
		Loop: "LoopSound",
		Pause: "PauseSound",
		Resume: "ResumeSound",
		Stop: "StopSound",
		Fade: "FadeSound",
		FadeAndStop: "FadeAndStopSound",
		Enable: "EnableSound",
		Enabled: "SoundEnabled",
		Disable: "DisableSound",
		Disabled: "SoundDisabled"
	};

	public static readonly Animation = {
		Update: "AnimationUpdate",
		Complete: "AnimationComplete",
	};

	public static readonly LeaderBoard = {
		Show: "ShowLeaderBoard",
		Update: "UpdateLeaderBoard",
		Updated: "LeaderBoardUpdated",
		ShowTopRanks: "ShowTopRanks"
	}
}

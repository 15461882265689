import { Ease } from "./Ease";

export class Sine {
	public static easeIn: Ease = (time: number, from: number, to: number, duration: number): number => {
		if (time >= duration) {
			return to;
		}

		return -(to - from) * Math.cos((time / duration) * (Math.PI / 2)) + (to - from) + from;
	};

	public static easeOut: Ease = (time: number, from: number, to: number, duration: number): number => {
		if (time >= duration) {
			return to;
		}

		return (to - from) * Math.sin((time / duration) * (Math.PI / 2)) + from;
	};

	public static easeInOut: Ease = (time: number, from: number, to: number, duration: number): number => {
		if (time >= duration) {
			return to;
		}

		return (-(to - from) / 2) * (Math.cos((Math.PI * time) / duration) - 1) + from;
	};
}

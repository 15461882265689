export enum GradientDirection {
	ToLeft,
	ToTopLeft,
	ToTop,
	ToTopRight,
	ToRight,
	ToBottomLeft,
	ToBottom,
	ToBottomRight
}

import { injectable } from "inversify";
import { ComponentBuilder } from "./ComponentBuilder";
import { LayoutComponent } from "../LayoutComponent";
import { appContainer, lazyInject } from "../../ioc";
import { TextFieldConfig } from "../config";
import { TextField } from "../TextField";
import { Translations } from "../../lang";
import { Types } from "../../types/Types";

@injectable()
/**
 * The TextBuilder creates TextField objects which define an area within which text will automatically be resized to
 * fit. Translations are supported.
 */
export class TextBuilder implements ComponentBuilder {
	@lazyInject(Types.Translations)
	protected translations!: Translations;

	public build(config: TextFieldConfig<any>, parentDimensions?: { width: number; height: number }): LayoutComponent {
		let text: string;
		if (typeof config.text === "string") {
			text = config.text;
		} else {
			text = this.translations.get(config.text.id, config.text.values);
		}

		const textField: TextField = new TextField(text, config.style, config.layout);

		if (config.alpha != undefined) {
			textField.alpha = config.alpha;
		}

		if (config.background != undefined) {
			textField.setBackground(config.background);
		}

		if (config.filters != undefined) {
			textField.filters = config.filters;
		}

		if (config.visible === false) {
			textField.visible = false;
		}

		if (config.id != undefined) {
			textField.name = config.id;
			appContainer.bind<TextField>(config.id).toConstantValue(textField);
		}

		return textField;
	}
}

export class LeaderBoardComponents {
	public static LeaderBoard: string = "LeaderBoard";
	public static LeaderBoardFade: string = "LeaderBoardFade";

	public static Player1Container: string = "Player1Container";
	public static Player1Rank: string = "Player1Rank";
	public static Player1Name: string = "Player1Name";
	public static Player1Points: string = "Player1Points";
	public static Player2Container: string = "Player1Container";
	public static Player2Rank: string = "Player2Rank";
	public static Player2Name: string = "Player2Name";
	public static Player2Points: string = "Player2Points";
	public static Player3Container: string = "Player1Container";
	public static Player3Rank: string = "Player3Rank";
	public static Player3Name: string = "Player3Name";
	public static Player3Points: string = "Player3Points";
	public static Player4Container: string = "Player1Container";
	public static Player4Rank: string = "Player4Rank";
	public static Player4Name: string = "Player4Name";
	public static Player4Points: string = "Player4Points";
	public static Player5Container: string = "Player1Container";
	public static Player5Rank: string = "Player5Rank";
	public static Player5Name: string = "Player5Name";
	public static Player5Points: string = "Player5Points";
}

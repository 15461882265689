export class HiLoEvents {
	public static readonly Game = {
		Init: "Init",
		Join: "Join",
		Resize: "Resize",
		StatusUpdated: "StatusUpdated",
		StartSpin: "StartSpin",
		RoundUpdated: "RoundUpdated",
		SpinComplete: "SpinComplete",
		HigherAccepted: "HigherAccepted",
		LowerAccepted: "LowerAccepted",
		GameOver: "GameOver",
		RoundWin: "RoundWin",
		PrizeWin: "PrizeWin",
		SetUserChoice: "SetUserChoice",
		ShowGameOverDialog: "ShowGameOverDialog",
		ShowError: "ShowError",
		ShowPreloadError: "ShowPreloadError",
		HideButtons: "HideButtons",
		ShowButtons: "ShowButtons",
		ChoiceMade: "ChoiceMade",
		ObserveGameWin: "ObserveGameWin",
		ObserveGameOver: "ObserveGameOver",
		ShowPrizeWinDialog: "ShowPrizeWinDialog",
		ShowObserveWinDialog: "ShowObserveWinDialog",
		ShowObserveLoseDialog: "ShowObserveLoseDialog",
		ShowGameInfoDialog: "ShowGameInfoDialog",
		ShowPreviousNumber: "ShowPreviousNumber",
		ShowGameOverLeaderBoard: "ShowGameOverLeaderBoard",
		ChoiceFailed: "ChoiceFailed",
		Reset: "Reset",
		KnockedOut: "KnockedOut",
		JoinAfterSpectatorMode: "joinAfterSpectatorMode"
	};

	public static readonly User = {
		ExitSpectatorMode: "ExitSpectatorMode",
		ChooseHigher: "ChooseHigher",
		ChooseLower: "ChooseLower",
		Close: "Close",
		ToggleSound: "ToggleSound",
		ObserveGame: "ObserveGame",
		CloseGameInfo: "CloseGameInfo",
		PlayAgain: "PlayAgain",
		CollectPrize: "CollectPrize"
	};

	public static readonly Animation = {
		BoxSizeUpdated: "BoxSizeUpdated",
		ShowPrizeWinAnimation: "ShowPrizeWinAnimation"
	};
}

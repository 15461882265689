import { injectable } from "inversify";
import { HiLoGameMode } from "./HiLoGameMode";
import { PlayerRankInfo, PrizeInfo } from "@tournament/ui-core";

@injectable()
export class GameModel {
	/**
	 * The game mode for this tournament.
	 * 0 = Standard
	 * 1 = Leaderboard (Leaderboard)
	 */
	public gameMode: HiLoGameMode | undefined;
	/**
	 * The number of lives the player has left for this entry (Leaderboard mode)
	 */
	public livesRemaining: number | undefined;
	/**
	 * The maximim number of lives the player has per entry (Leaderboard mode)
	 */
	public livesPerEntry: number | undefined;
	/**
	 * The number of points for a successful choice (Leaderboard mode)
	 */
	public pointsPerRound: number | undefined;
	/**
	 * The start time of the game as a unix date stamp.
	 */
	public startTime: number | undefined;
	/**
	 * The end time of the tournament as a unix date stamp (Leaderboard mode)
	 */
	public endTime: number | undefined;
	/**
	 * The prize amount in pence.
	 */
	public prizeAmount: number | undefined;
	/**
	 * The currency of the prize in ISO 4217 3-character code format.
	 */
	public currency: string | undefined;
	/**
	 * The minimum number that can be selected.
	 */
	public minNumber: number | undefined;
	/**
	 * The maximum number that can be selected.
	 */
	public maxNumber: number | undefined;
	/**
	 * The current round number.
	 */
	public roundNumber: number | undefined;
	/**
	 * The player round number - used for display in hilo Leaderboard.
	 */
	public playerRoundNumber: number | undefined;
	/**
	 * The duration of each round in seconds.
	 */
	public roundDurationSeconds: number | undefined;
	/**
	 * The cut off time beyond which players cannot place further bets, in seconds.
	 */
	public cutOffTime: number | undefined;
	/**
	 * The time the next round starts as a unix date stamp.
	 */
	public nextRoundStartTime: number | undefined;
	/**
	 * The current number.
	 */
	public currentNumber: number | undefined;

	/**
	 * The maximum number of rounds (survival mode)
	 */
	public maxRounds: number | undefined;

	/**
	 * The maximum number of winners (last man/men standing mode)
	 */
	public maxWinners: number | undefined;

	/**
	 * The number of players that have chosen higher for the current round.
	 */
	public numUpPlayers: number | undefined;
	/**
	 * The number of players that have chosen lower for the current round.
	 */
	public numDownPlayers: number | undefined;
	/**
	 * All previously drawn numbers.
	 */
	public lastNumbers: number[] = [];
	/**
	 * The number of registered participants for the game.
	 */
	public participants: number | undefined;
	/**
	 * The number of participants still remaining in the game
	 */
	public numParticipantsRemaining: number | undefined;
	/**
	 * The list of winners of the game.
	 */
	public winners: Array<{ playerId: string; nickname: string; winAmount: number }> | undefined;
	/**
	 * The prize won by the player.
	 */
	public winAmount: number | undefined;
	/**
	 * The total number of players currently in the leaderboard.
	 */
	public leaderboardTotalPlayers: number | undefined;
	/**
	 * The rank, display name and current points of the players around and including the current player.
	 */
	public leaderboardRankings: Array<PlayerRankInfo> | undefined;
	/**
	 * The prizes that can be won in this tournament (Leaderboard mode)
	 */
	public prizes: Array<PrizeInfo> | undefined;
	/**
	 * Determines if the user is still active in the game - i.e. they haven't lost yet.
	 * Used to determine if the game should enter observer mode when loading.
	 */
	public inPlay: boolean = true;
	/**
	 * The prize ladder (Take it or Leave it mode)
	 */
	public prizeLadder: Array<{ roundNumber: number, prizeAmount: number }> | undefined;

	public locale: string = 'en-US';

	public constructor() {
		/*if (navigator.languages && navigator.languages.length) {
			this.locale = navigator.languages[0];
		} else {
			this.locale =
				(navigator as any).userLanguage || navigator.language || (navigator as any).browserLanguage || "en-US";
		}*/
	}
}

import { injectable } from "inversify";
import { LayoutComponent } from "../LayoutComponent";
import { appContainer } from "../../ioc";
import { LeaderBoardConfig } from "../config/LeaderBoardConfig";
import { ComponentBuilder } from "./ComponentBuilder";
import { LeaderBoard } from "../LeaderBoard";

@injectable()
/**
 * The LeaderBoardBuilder creates LeaderBoard components used for displaying leaderboards in the UI.
 *
 * @see LeaderBoard
 * @see LeaderBoardConfig
 */
export class LeaderBoardBuilder implements ComponentBuilder {
	public build(config: LeaderBoardConfig<any, any>, parentDimensions?: { width: number; height: number }): LayoutComponent {
		const leaderBoard: LeaderBoard = new LeaderBoard(config, config.layout);

		this.setLeaderBoardProps(config, leaderBoard);

		return leaderBoard;
	}

	protected setLeaderBoardProps(config: LeaderBoardConfig<any, any>, leaderBoard: LeaderBoard): void {
		if (config.background != undefined) {
			leaderBoard.setBackground(config.background);
		}

		if (config.visible === false) {
			leaderBoard.visible = false;
		}

		this.createBindings(config.id, leaderBoard);
	}

	protected createBindings(id: string | undefined, leaderBoard: LeaderBoard): void {
		if (id != undefined) {
			leaderBoard.name = id;
			appContainer.bind<LeaderBoard>(id).toConstantValue(leaderBoard);
		}
	}
}

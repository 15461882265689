import { NineSlicePlane, } from "pixi.js";
import { FixedDimensions, Layout } from "./config";
import { lazyInject } from "../ioc";
import { Types } from "../types/Types";
import { EventBus, Events } from "../events";
import { ButtonFrame } from "./ButtonFrame";
import { LayoutContainer } from "./LayoutContainer";

export class NineSliceButton extends LayoutContainer {
	@lazyInject(Types.EventBus)
	protected eventBus!: EventBus;

	protected frames: NineSlicePlane[];

	protected isEnabled: boolean = true;

	protected onClickEvent: string | undefined;

	protected _currentFrame: ButtonFrame = ButtonFrame.Up;

	protected get currentFrame(): ButtonFrame {
		return this._currentFrame;
	}

	protected set currentFrame(value: ButtonFrame) {
		if (this._currentFrame !== value) {
			this.frames[this._currentFrame].visible = false;
			this._currentFrame = value;
			this.frames[value].visible = true;
		}
	}

	public constructor(
		frames: [NineSlicePlane, NineSlicePlane, NineSlicePlane, NineSlicePlane],
		layout: Layout,
		onClickEvent?: string
	) {
		super(layout);

		this.onClickEvent = onClickEvent;

		for (let i = 0; i < frames.length; i++) {
			frames[i].visible = false;
			this.addChild(frames[i]);
		}

		frames[ButtonFrame.Up].visible = true;

		this.frames = frames;
		this.buttonMode = true;
		this.interactive = true;

		this.addListeners();
	}

	public addListeners(): void {
		this.removeAllListeners();
		this.on("mouseover", this.onMouseOver, this);

		this.on("mousedown", this.onMouseDown, this);
		this.on("touchstart", this.onMouseDown, this);

		this.on("mouseup", this.onMouseUp, this);
		// As there is no touch out event, touch end should go straight to the up state.
		this.on("touchend", this.onTouchEnd, this);

		this.on("mouseout", this.onMouseOut, this);

		this.on("mouseupoutside", this.onMouseUpOutside, this);
		this.on("touchendoutside", this.onMouseUpOutside, this);
	}

	protected onMouseOver(): void {
		if (this.isEnabled && this.currentFrame === ButtonFrame.Up) {
			this.currentFrame = ButtonFrame.Over;
		}
	}

	protected onMouseDown(): void {
		if (this.isEnabled) {
			this.currentFrame = ButtonFrame.Down;
		}
	}

	protected onMouseUp(): void {
		if (this.isEnabled) {
			this.currentFrame = ButtonFrame.Over;
			if (this.onClickEvent) {
				this.eventBus.emit(this.onClickEvent);
			} else {
				this.emit(Events.Interaction.Click);
			}
		}
	}

	protected onTouchEnd(): void {
		if (this.isEnabled) {
			this.currentFrame = ButtonFrame.Up;
			if (this.onClickEvent) {
				this.eventBus.emit(this.onClickEvent);
			} else {
				this.emit(Events.Interaction.Click);
			}
		}
	}

	protected onMouseOut(): void {
		if (this.isEnabled && this.currentFrame !== ButtonFrame.Down) {
			this.currentFrame = ButtonFrame.Up;
		}
	}

	protected onMouseUpOutside(): void {
		if (this.isEnabled) {
			this.currentFrame = ButtonFrame.Up;
		}
	}

	public resize(parentDimensions: FixedDimensions): FixedDimensions {
		const dimensions: FixedDimensions = super.resize(parentDimensions);

		for (const frame of this.frames) {
			frame.width = dimensions.width;
			frame.height = dimensions.height;
		}

		return dimensions;
	}

	protected getIgnoredResizeChildren(): any[] {
		return [this.background, ...this.frames];
	}

	public setEnabled(value: boolean): void {
		this.isEnabled = value;
		this.buttonMode = value;
		this.interactive = value;

		if (this.isEnabled) {
			this.addListeners();
			this.currentFrame = ButtonFrame.Up;
		} else {
			this.removeAllListeners();
			this.currentFrame = ButtonFrame.Disabled;
		}
	}
}

export class ColorUtils {
	/**
	 * Converts a hexadecimal color to a string.
	 *
	 * @param color The hexadecimal color to change.
	 */
	public static hexToString(color: number): string {
		let hex: string = color.toString(16);
		return "#" + (hex.length == 1 ? "0" + hex : hex);
	}

	/**
	 * Converts a string to a hexadecimal color.
	 *
	 * @param color The string to change.
	 */
	public static stringToHex(color: string): number {
		return parseInt(color.slice(1), 16);
	}

	/**
	 * Lightens the specified colour.
	 *
	 * @param color The colour to lighten
	 * @param amount The amount to lighten the colour. Values range from 0 (0%) to 1 (100%).
	 */
	public static lightenColor(color: string, amount: number): string {
		if (amount <= 0 || amount >= 1) {
			throw new Error("Amount must be a decimal value between 0 and 1.");
		}

		return this.hexToString(this.adjustColor(this.stringToHex(color), amount));
	}

	/**
	 * Darkens the specified colour.
	 *
	 * @param color The colour to darken
	 * @param amount The amount to darken the colour. Values range from 0 (0%) to 1 (100%).
	 */
	public static darkenColor(color: string, amount: number): string {
		if (amount <= 0 || amount >= 1) {
			throw new Error("Amount must be a decimal value between 0 and 1.");
		}

		return this.hexToString(this.adjustColor(this.stringToHex(color), -amount));
	}

	/**
	 * Lightens or darkens a color by the specified amount.
	 *
	 * @param color The colour to change
	 * @param amount The amount to lighten/darken the colour. Values range from -1 (-100%) to 1 (100%).
	 */
	public static adjustColor(color: number, amount: number): number {
		if (amount < -1 || amount > 1) {
			throw new Error("Amount must be a decimal value between -1 and 1.");
		}
		
		let r: number = color >> 16;
		let g: number = (color >> 8) & 0x00ff;
		let b: number = color & 0x0000ff;

		if (amount < 0) {
			r = Math.max(0, (1 - amount) * r);
			g = Math.max(0, (1 - amount) * g);
			b = Math.max(0, (1 - amount) * b);
		} else {
			r = Math.min(255, (1 + amount) * r);
			g = Math.min(255, (1 + amount) * g);
			b = Math.min(255, (1 + amount) * b);
		}

		return (r << 16) | (g << 8) | b;
	}

	/**
	 * Blends two colors together.
	 *
	 * @param color The first color to blend.
	 * @param blendColor The second color to blend.
	 * @param amount How much of the second color to blend into the first color. Values between 0 (0%) to 1 (100%).
	 */
	public static blendColors(color: number, blendColor: number, amount: number): number {
		if (amount < 0 || amount > 1) {
			throw new Error("Amount must be a decimal value between 0 and 1.");
		}
		
		const r1: number = color >> 16;
		const g1: number = (color >> 8) & 0x00ff;
		const b1: number = color & 0x0000ff;

		const r2: number = blendColor >> 16;
		const g2: number = (blendColor >> 8) & 0x00ff;
		const b2: number = blendColor & 0x0000ff;
		
		const r: number = Math.round(r1 + (r2 - r1) * amount);
		const g: number = Math.round(g1 + (g2 - g1) * amount);
		const b: number = Math.round(b1 + (b2 - b1) * amount);

		return (r << 16) | (g << 8) | b;
	}
}

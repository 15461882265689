export class Components {
	public static HiLoGame: string = "HiLoGame";
	public static PreloadConfig: string = "PreloadConfig";
	public static AssetConfig: string = "AssetConfig";

	public static VersionNumber: string = "VersionNumber";
	public static GameContainer: string = "GameContainer";

	public static LoadingBar: string = "LoadingBar";
	public static GameOverDialog: string = "GameOverDialog";
	public static GameOverConfirm: string = "GameOverConfirm";
	public static ObserverGameOverDialog: string = "ObserverGameOverDialog";
	public static ObserverGameOverConfirm: string = "ObserverGameOverConfirm";
	public static ObserveGameButton: string = "ObserveGameButton";

	public static GameStartingText: string = "GameStartingText";
	public static GameStartCountDownText: string = "GameStartCountDownText";

	public static ObserverGameWonDialog: string = "ObserverGameWonDialog";
	public static ObserverGameWonConfirm: string = "ObserverGameWonConfirm";
	public static GameWinnersContainer: string = "GameWinnersContainer";

	public static WinDialogAnimContainer: string = "WinDialogAnimContainer";

	public static PrizeWinDialog: string = "PrizeWinDialog";
	public static PrizeWinConfirm: string = "PrizeWinConfirm";
	public static PrizeWinValue: string = "PrizeWinValue";

	public static ErrorDialog: string = "ErrorDialog";
	public static ErrorTitle: string = "ErrorDialogTitle";
	public static ErrorMessage: string = "ErrorDialogMessage";
	public static ErrorDialogConfirm: string = "ErrorDialogConfirm";

	// TODO: This shouldn't really be necessary.
	public static PreloadErrorDialog: string = "PreloadErrorDialog";
	public static PreloadErrorTitle: string = "PreloadErrorTitle";
	public static PreloadErrorMessage: string = "PreloadErrorMessage";
	public static PreloadErrorDialogConfirm: string = "PreloadErrorDialogConfirm";

	public static SoundDialog: string = "SoundDialog";
	public static SoundDialogCancel: string = "SoundDialogCancel";
	public static SoundDialogConfirm: string = "SoundDialogConfirm";
	public static HigherButton: string = "HigherButton";
	public static ExitSpectatorMode: string = "ExitSpectatorMode";
	public static SpectatorModeButton: string = "SpectatorModeButton";
	public static LowerButton: string = "LowerButton";
	public static SoundButton: string = "SoundButton";
	public static CloseButton: string = "CloseButton";
	public static NoMoreBetsText: string = "NoMoreBetsText";
	public static PreviousNumberContainer: string = "PreviousNumberContainer";
	public static PreviousNumberBackground: string = "PreviousNumberBackground";
	public static NumRemainingText: string = "NumRemainingText";
	public static RoundsText: string = "RoundsText";
	public static SpectatorModeText: string = "SpectatorModeText";
	public static JoinText: string = "JoinText";
	public static GameTitleText: string = "GameTitleText";
	public static TimerContainer: string = "TimerContainer";
	public static TimerBackground: string = "TimerBackground";
	public static TimerProgress: string = "ProgressTimer";
	public static TimerProgressCutoff: string = "TimerProgressCutoff";
	public static TimerGlow: string = "TimerGlow";
	public static NumberSpinner: string = "NumberSpinner";
	public static NumUsersUpText: string = "NumUsersUpText";
	public static NumUsersDownText: string = "NumUsersDownText";
	public static NumUsersUpBackground: string = "NumUsersUpBackground";
	public static NumUsersDownBackground: string = "NumUsersDownBackground";

	public static GridContainer: string = "GridContainer";
	public static BackgroundAnimContainer: string = "BackgroundAnimContainer";
	public static ChoiceAnimContainer: string = "ChoiceAnimContainer";
	public static ParticleAnimContainer: string = "ParticleAnimContainer";
	public static TimerAnimContainer: string = "TimerAnimContainer";
	public static ButtonContainer: string = "ButtonContainer";

	public static SurvivalGameInfoConfirm: string = "SurvivalGameInfoConfirm";
	public static SurvivalGameInfoDialog: string = "SurvivalGameInfoDialog";
	public static SurvivalGameDescription: string = "SurvivalGameDescription";
	public static SurvivalGameTitle: string = "SurvivalGameTitle";

	public static LastManGameInfoConfirm: string = "LastManGameInfoConfirm";
	public static LastManGameInfoDialog: string = "LastManGameInfoDialog";
	public static LastManGameDescription: string = "LastManGameDescription";
	public static LastManGameTitle: string = "LastManGameTitle";

	public static LeaderboardGameInfoConfirm: string = "LeaderboardGameInfoConfirm";
	public static LeaderboardGameInfoDialog: string = "LeaderboardGameInfoDialog";
	public static LeaderboardGameDescription: string = "LeaderboardGameDescription";
	public static LeaderboardGameTitle: string = "LeaderboardGameTitle";

	public static LeaderboardGameOverDialog: string = "LeaderboardGameOverDialog";
	public static LeaderboardPlayAgainMessage: string = "LeaderboardPlayAgainMessage";
	public static LeaderboardNoMoreEntriesDialog: string = "LeaderboardNoMoreEntriesDialog";
	public static LeaderboardCurrentRanking: string = "LeaderboardCurrentRanking";
	public static TournamentEndCountdown: string = "TournamentEndCountdown";
	public static PlayAgainButton: string = "PlayAgainButton";

	public static LeaderboardTournamentEndedDialog: string = "LeaderboardTournamentEndedDialog";
	public static LeaderboardTournamentEndedConfirm: string = "LeaderboardTournamentEndedConfirm";
	public static TournamentEndRanking: string = "TournamentEndRanking";

	public static ShowLeaderBoardButton: string = "ShowLeaderBoardButton";
	public static NumberRangeText: string = "NumberRangeText";

	public static LeaderBoardInfoPrizeValue1: string = "LeaderBoardInfoPrizeValue1";
	public static LeaderBoardInfoPrizeValue2: string = "LeaderBoardInfoPrizeValue2";
	public static LeaderBoardInfoPrizeValue3: string = "LeaderBoardInfoPrizeValue3";

	public static TournamentCountDownContainer: string = "TournamentCountDownContainer";
	public static TournamentCountDownTitle: string = "TournamentCountDownTitle";
	public static TournamentCountDownTimer: string = "TournamentCountDownTimer";

	public static FinalRoundMessage: string = "FinalRoundMessage";

	public static RoundProgressBar: string = "RoundProgressBar";
	public static RoundProgressBarContainer: string = "RoundProgressBarContainer";
	public static AfterRoundMessage: string = "AfterRoundMessage";
	public static RoundIndicatorValue: string = "RoundIndicatorValue";
	public static ProgressMinRoundValue: string = "ProgressMinRoundValue";
	public static ProgressMaxRoundValue: string = "ProgressMaxRoundValue";

	public static BackgroundContainer: string = "BackgroundContainer";
	public static HigherBackground: string = "HigherBackground";
	public static LowerBackground: string = "LowerBackground";

	public static MillionaireInfoDialog: string = "MillionaireInfoDialog";
	public static MillionaireMaxPrizeValue: string = "MillionaireMaxPrizeValue";
	public static MillionaireTitle: string = "MillionaireTitle";
	public static MillionaireInfoConfirm: string = "MillionaireInfoConfirm";

	public static MillionairePrizeOfferDialog: string = "MillionairePrizeOfferDialog";
	public static MillionairePrizeOffer: string = "MillionairePrizeOffer";
	public static MillionaireNextPrizeValue: string = "MillionaireNextPrizeValue";
	public static CollectPrizeButton: string = "CollectPrizeButton";
	public static MillionaireContinueButton: string = "MillionaireContinueButton";
	public static MillionairePlayTomorrowDialog: string = "MillionairePlayTomorrowDialog";
	public static MillionairePlayTomorrowTitle: string = "MillionairePlayTomorrowTitle";
	public static MillionareCountDown: string = "MillionareCountDown";
	public static MillionaireGameOverDialog: string = "MillionaireGameOverDialog";
}

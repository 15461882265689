import { LoggingAdapter } from "./LoggingAdapter";
import { injectable } from "inversify";

@injectable()
export class ConsoleLoggingAdapter implements LoggingAdapter {
	public fatal(message: string): void {
		console.error("FATAL: " + message);
	}

	public error(message: string): void {
		console.error(message);
	}

	public warn(message: string): void {
		console.warn(message);
	}

	public info(message: string): void {
		console.info(message);
	}

	public debug(message: string): void {
		console.debug(message);
	}
}

import { injectable } from "inversify";
import { ComponentBuilder } from "./ComponentBuilder";
import { ContainerConfig } from "../config";
import { LayoutComponent } from "../LayoutComponent";
import { LayoutContainer } from "../LayoutContainer";
import { appContainer } from "../../ioc";

@injectable()
/**
 * The ContainerBuilder creates LayoutContainer components which wrap the Pixi Container class to add layout
 * functionality.
 *
 * @see LayoutContainer
 * @see ContainerConfig
 */
export class ContainerBuilder implements ComponentBuilder {
	public build(config: ContainerConfig<any, any>, parentDimensions?: { width: number; height: number }): LayoutComponent {
		const container: LayoutContainer = new LayoutContainer(config.layout);

		this.setContainerProps(config, container);

		return container;
	}

	protected setContainerProps(config: ContainerConfig<any, any>, container: LayoutContainer): void {
		if (config.background != undefined) {
			container.setBackground(config.background);
		}

		if (config.alpha != undefined) {
			container.alpha = config.alpha;
		}

		if (config.filters != undefined) {
			container.filters = config.filters;
		}

		if (config.visible === false) {
			container.visible = false;
		}

		this.createBindings(config.id, container);
	}

	protected createBindings(id: string | undefined, container: LayoutContainer): void {
		if (id != undefined) {
			container.name = id;
			appContainer.bind<LayoutContainer>(id).toConstantValue(container);
		}
	}
}

import { Ease } from "./Ease";

export class Circular {
	public static easeIn: Ease = (time: number, from: number, to: number, duration: number): number => {
		if (time >= duration) {
			return to;
		}

		return -(to - from) * (Math.sqrt(1 - (time /= duration) * time) - 1) + from;
	};

	public static easeOut: Ease = (time: number, from: number, to: number, duration: number): number => {
		if (time >= duration) {
			return to;
		}

		return (to - from) * Math.sqrt(1 - (time = time / duration - 1) * time) + from;
	};

	public static easeInOut: Ease = (time: number, from: number, to: number, duration: number): number => {
		if (time >= duration) {
			return to;
		}

		if ((time /= duration / 2) < 1) {
			return (-(to - from) / 2) * (Math.sqrt(1 - time * time) - 1) + from;
		}

		return ((to - from) / 2) * (Math.sqrt(1 - (time -= 2) * time) + 1) + from;
	};
}

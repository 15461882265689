import { injectable } from "inversify";
import { LayoutContainer } from "./LayoutContainer";
import { Background, FixedDimensions, Layout, Rect } from "./config";
import { ProgressBarConfig } from "./config/ProgressBarConfig";
import { Graphics, NineSlicePlane, Sprite, Texture } from "pixi.js";
import { BackgroundType } from "./config/BackgroundType";
import { GraphicsUtils } from "../utils";
import { ColorBackground } from "./config/ColorBackground";

@injectable()
export class ProgressBar extends LayoutContainer {
	protected config: ProgressBarConfig<any>;

	protected progressBar: Graphics | Sprite | NineSlicePlane | undefined;

	protected currentProgress: number = 0;

	protected maxValue: number;

	public constructor(layout: Layout, config: ProgressBarConfig<any>) {
		super(layout);

		this.config = config;

		this.maxValue = config.maxValue || 100;
	}

	public setBackground(background: Background): void {
		this.background = background;

		if (this.background && this.background.type !== BackgroundType.Color) {
			this.createBackgroundImage();
			this.createProgressBar();
		}
	}

	public setMaxValue(value: number): void {
		this.maxValue = value;

		if (this.layoutDimensions != undefined) {
			this.setProgress(this.currentProgress);
		}
	}


	protected createProgressBar(): void {
		const config = this.config;

		let progressBar: Graphics | Sprite | NineSlicePlane;
		switch (config.progressBar.type) {
			case BackgroundType.Color:
				progressBar = new Graphics();
				break;
			case BackgroundType.Image:
				progressBar = new Sprite(Texture.from(config.progressBar.src));
				break;
			case BackgroundType.NineSliceImage:
				progressBar = new NineSlicePlane(
					Texture.from(config.progressBar.src),
					config.progressBar.slices.leftWidth,
					config.progressBar.slices.topHeight,
					config.progressBar.slices.rightWidth,
					config.progressBar.slices.bottomHeight
				);
				break;
		}

		this.progressBar = progressBar;

		if (this.config.maskProgress === true || this.config.maskProgress == undefined) {
			const progressMask: Graphics = new Graphics();
			this.progressBar.mask = progressMask;
			this.addChild(progressMask);
		}

		this.addChild(progressBar);
	}

	protected resizeChildren(parentDimensions: FixedDimensions, layoutDimensions: Rect): void {
		super.resizeChildren(parentDimensions, layoutDimensions);

		this.setProgress(this.currentProgress);
	}

	public setProgress(value: number): void {
		this.currentProgress = value;

		if (this.progressBar != undefined) {
			if (value === 0) {
				this.progressBar.visible = false;
			} else {
				this.progressBar.visible = true;
				const layoutDimensions = this.layoutDimensions as Rect;

				let xPadding: number = 0;
				let yPadding: number = 0;
				const padding = this.config.progressBar.padding;
				if (padding != undefined) {
					if (padding.x || padding.xPercent) {
						xPadding = padding.x || (padding.xPercent as number / 100) * layoutDimensions.width;
						xPadding = Math.max(1, xPadding);
					}

					if (padding.y || padding.yPercent) {
						yPadding = padding.y || (padding.yPercent as number / 100) * layoutDimensions.height;
						yPadding = Math.max(1, yPadding);
					}
				}

				const width: number = Math.max(1, layoutDimensions.width - (xPadding * 2));
				const height: number = Math.max(1, layoutDimensions.height - (yPadding * 2));

				const progressWidth: number = Math.max(1, width * (this.currentProgress / this.maxValue));

				switch (this.config.progressBar.type) {
					case BackgroundType.Color:
						GraphicsUtils.drawBackground(
							this.progressBar as Graphics,
							this.config.progressBar as ColorBackground,
							this.config.maskProgress ? width : progressWidth,
							height
						);
						break;
					case BackgroundType.Image:
						const sprite: Sprite = this.progressBar as Sprite;
						sprite.width = this.config.maskProgress ? width : progressWidth;
						sprite.height = height;
						break;
					case BackgroundType.NineSliceImage:
						const slicePlane: NineSlicePlane = this.progressBar as NineSlicePlane;
						slicePlane.width = this.config.maskProgress ? width : progressWidth;
						slicePlane.height = height;
						break;
				}

				this.progressBar.x = (layoutDimensions.width - this.progressBar.width) / 2;
				this.progressBar.y = (layoutDimensions.height - this.progressBar.height) / 2;

				if (this.config.maskProgress === true || this.config.maskProgress == undefined) {
					const mask = this.progressBar.mask as Graphics;
					mask.clear();
					mask.beginFill(0xffffff);
					mask.drawRect(this.progressBar.x, this.progressBar.y, progressWidth, this.progressBar.height);
					mask.endFill();
					this.progressBar.mask = mask;
				}
			}
		}
	}
}

import { Container } from "inversify";
import getDecorators from "inversify-inject-decorators";

/**
 * Makes property decorators compatible with babel.
 */
function fixPropertyDecorator<T extends Function>(decorator: T): T {
	return ((...args: any[]): any => (target: any, propertyName: any, ...decoratorArgs: any[]): any => {
		decorator(...args)(target, propertyName, ...decoratorArgs);
		return Object.getOwnPropertyDescriptor(target, propertyName);
	}) as any;
}

export const appContainer: Container = new Container();
const decorators = getDecorators(appContainer);

export const lazyInject: any = fixPropertyDecorator(decorators.lazyInject);
export const lazyInjectNamed: any = fixPropertyDecorator(decorators.lazyInjectNamed);
export const lazyInjectTagged: any = fixPropertyDecorator(decorators.lazyInjectTagged);
export const lazyMultiInject: any = fixPropertyDecorator(decorators.lazyMultiInject);


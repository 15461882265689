import { injectable } from "inversify";
import { AnimatedSprite, Graphics, Sprite, Texture } from "pixi.js";
import {
	Events,
	FixedDimensions,
	Gradient,
	GradientDirection,
	GraphicsUtils,
	PixiTween,
	TweenProperty
} from "@tournament/ui-core";
import { Direction, HiLoEvents, AbstractBackgroundAnimController } from "@tournament/hilo-ui-core";

enum GridBackground {
	Default = "default",
	Lower = "lower",
	Higher = "higher",
	Failed = "Failed"
}

@injectable()
export class BackgroundAnimController extends AbstractBackgroundAnimController {
	protected backgrounds: { [key: string]: Sprite } = {};

	protected higherMask!: Graphics;

	protected lowerMask!: Graphics;

	protected higherFade!: Graphics;
	protected lowerFade!: Graphics;

	protected backgroundMask!: AnimatedSprite;

	public constructor() {
		super();
		this.createBackgrounds();
		this.createMasks();
		this.drawChoiceFades();

		this.animContainer.onResize = this.onContainerResized;
	}

	public onContainerResized = (dimensions: FixedDimensions): void => {
		for (const key in this.backgrounds) {
			this.backgrounds[key].width = dimensions.width;
			this.backgrounds[key].height = dimensions.height;
		}
	};

	protected setUserChoice(direction: Direction): void {
		if (direction === Direction.Higher) {
			this.backgrounds[GridBackground.Higher].visible = true;
		} else if (direction === Direction.Lower) {
			this.backgrounds[GridBackground.Lower].visible = true;
		}
	}

	protected clearBackgroundAnimations(): void {
		if (this.backgroundMask != undefined) {
			this.backgroundMask.stop();
			this.animContainer.removeChild(this.backgroundMask);
		}
	}

	protected playGameOverAnimation(): void {
		this.backgroundMask = new AnimatedSprite(
			GraphicsUtils.createTextureSequence("GameOverTransition", 35).reverse()
		);
		this.backgroundMask.animationSpeed = 1;
		this.backgroundMask.height = this.animContainer.height;
		this.backgroundMask.width = this.animContainer.width;
		this.backgroundMask.loop = false;

		this.backgroundContainer.mask = this.backgroundMask;
		this.animContainer.addChild(this.backgroundMask);

		this.backgroundMask.onComplete = () => {
			// @ts-ignore
			this.backgroundContainer.mask = null;
			this.animContainer.removeChild(this.backgroundMask);
		};

		this.backgroundMask.play();
	}

	protected reset(): void {
		super.reset();

		this.backgrounds[GridBackground.Default].visible = true;
		this.backgrounds[GridBackground.Higher].visible = false;
		this.backgrounds[GridBackground.Lower].visible = false;
		// @ts-ignore
		this.backgrounds[GridBackground.Lower].mask = null;
		// @ts-ignore
		this.backgrounds[GridBackground.Higher].mask = null;

		// @ts-ignore
		this.backgroundContainer.mask = null;
		if (this.backgroundMask) {
			this.backgroundMask.stop();
			this.animContainer.removeChild(this.backgroundMask);

			this.backgroundMask = new AnimatedSprite(
				GraphicsUtils.createTextureSequence("GameOverTransition", 35).reverse()
			);
			this.backgroundMask.animationSpeed = 1;
			this.backgroundMask.height = this.animContainer.height;
			this.backgroundMask.width = this.animContainer.width;
			this.backgroundMask.loop = false;

			this.backgroundContainer.mask = this.backgroundMask;
			this.animContainer.addChild(this.backgroundMask);

			this.backgroundMask.onComplete = () => {
				// @ts-ignore
				this.backgroundContainer.mask = null;
				this.animContainer.removeChild(this.backgroundMask);
			};

			this.backgroundMask.play();
		}
	}

	protected onGameOver(): void {
		this.backgroundMask = new AnimatedSprite(GraphicsUtils.createTextureSequence("GameOverTransition", 35));
		this.backgroundMask.animationSpeed = 0.5;
		this.backgroundMask.height = this.animContainer.height;
		this.backgroundMask.width = this.animContainer.width;
		this.backgroundMask.loop = false;

		this.backgroundContainer.mask = this.backgroundMask;
		this.animContainer.addChild(this.backgroundMask);
		this.eventBus.emit(Events.Sound.Play, "LoseSound");

		this.backgroundMask.onComplete = () => {
			if (!this.isObserving) {
				if (this.model.winners && this.model.winners.length > 0 && this.model.winAmount != undefined) {
					// Prize win dialog
					this.eventBus.once(HiLoEvents.Animation.ShowPrizeWinAnimation, this.animatePrizeWinGlow, this);
					this.eventBus.emit(HiLoEvents.Game.ShowPrizeWinDialog);
				} else {
					this.eventBus.emit(HiLoEvents.Game.ShowGameOverDialog);
				}
			} else if (this.model.winners && this.model.winners.length > 0) {
				this.eventBus.emit(HiLoEvents.Game.ShowObserveWinDialog);
			} else {
				this.eventBus.emit(HiLoEvents.Game.ShowObserveLoseDialog);
			}
		};
		this.backgroundMask.play();
	}

	protected animatePrizeWinGlow(): void {
		this.backgroundAnimation = new AnimatedSprite(GraphicsUtils.createTextureSequence("Shine", 180));
		this.backgroundAnimation.alpha = 0.7;
		const ratio: number = this.backgroundAnimation.width / this.backgroundAnimation.height;

		this.backgroundAnimation.width = this.animContainer.width;
		this.backgroundAnimation.height = this.backgroundAnimation.width / ratio;

		this.backgroundAnimation.x = 0;
		this.backgroundAnimation.y = (this.animContainer.height - this.backgroundAnimation.height) / 2;

		this.backgroundAnimation.loop = true;

		this.winDialogAnimContainer.addChild(this.backgroundAnimation);
		this.winDialogAnimContainer.onResize = (dimensions: FixedDimensions): void => {
			if (this.backgroundAnimation) {
				const ratio: number = this.backgroundAnimation.width / this.backgroundAnimation.height;

				this.backgroundAnimation.width = this.animContainer.width;
				this.backgroundAnimation.height = this.backgroundAnimation.width / ratio;

				this.backgroundAnimation.x = 0;
				this.backgroundAnimation.y = (this.animContainer.height - this.backgroundAnimation.height) / 2;
			}
		};
		this.backgroundAnimation.play();
	}

	protected onChooseHigher(): void {
		this.clearCurrentAnimations();

		this.higherFade.height = this.backgrounds[GridBackground.Higher].height * 0.05;
		this.higherFade.y = 360;
		this.lowerFade.y = -this.lowerFade.height;

		this.backgroundContainer.setChildIndex(
			this.backgrounds[GridBackground.Higher],
			this.backgroundContainer.children.length - 1
		);
		this.higherMask.y = 360;
		this.backgrounds[GridBackground.Higher].mask = this.higherMask;
		this.backgrounds[GridBackground.Higher].visible = true;

		const tween: PixiTween = new PixiTween([this.higherMask], TweenProperty.Y, this).to(0, 15);

		tween.on(Events.Animation.Update, () => {
			this.higherFade.y = this.higherMask.y;
		});

		tween.once(
			Events.Animation.Complete,
			() => {
				tween.removeAllListeners(Events.Animation.Update);
				// @ts-ignore
				this.backgrounds[GridBackground.Lower].mask = null;
				this.backgrounds[GridBackground.Lower].visible = false;
				this.onAnimationComplete(tween);

				const clearFade: PixiTween = new PixiTween([this.higherFade], TweenProperty.Y, this).to(
					-this.higherFade.height,
					5
				);

				clearFade.once(Events.Animation.Complete, this.onAnimationComplete, this);
				this.animations.push(clearFade);
				clearFade.play();
			},
			this
		);

		this.animations.push(tween);
		tween.play();
	}

	protected onChooseLower(): void {
		this.clearCurrentAnimations();

		this.lowerFade.visible = true;
		this.higherFade.y = this.backgrounds[GridBackground.Higher].height;
		this.lowerFade.height = this.backgrounds[GridBackground.Lower].height * 0.05;
		this.lowerFade.y = -this.lowerFade.height;

		this.backgroundContainer.setChildIndex(
			this.backgrounds[GridBackground.Lower],
			this.backgroundContainer.children.length - 1
		);
		this.lowerMask.y = -this.lowerMask.height;
		this.backgrounds[GridBackground.Lower].mask = this.lowerMask;
		this.backgrounds[GridBackground.Lower].visible = true;

		const tween: PixiTween = new PixiTween([this.lowerMask], TweenProperty.Y, this).to(0, 15);

		tween.on(Events.Animation.Update, () => {
			this.lowerFade.y = this.lowerMask.y + this.lowerMask.height - this.lowerFade.height;
		});

		tween.once(
			Events.Animation.Complete,
			() => {
				this.lowerFade.visible = false;
				tween.removeAllListeners(Events.Animation.Update);
				// @ts-ignore
				this.backgrounds[GridBackground.Higher].mask = null;
				this.backgrounds[GridBackground.Higher].visible = false;
				this.onAnimationComplete(tween);

				const clearFade: PixiTween = new PixiTween([this.lowerFade], TweenProperty.Y, this).to(
					this.lowerFade.y + this.lowerFade.height,
					5
				);

				clearFade.once(Events.Animation.Complete, this.onAnimationComplete, this);
				this.animations.push(clearFade);
				clearFade.play();
			},
			this
		);

		this.animations.push(tween);
		tween.play();
	}

	protected returnToOriginalColors(): void {
		this.clearCurrentAnimations();

		if (this.backgrounds[GridBackground.Higher].visible) {
			const tween: PixiTween = new PixiTween(
				[this.backgrounds[GridBackground.Higher]],
				TweenProperty.Alpha,
				this
			).to(0, 15);
			tween.once(
				Events.Animation.Complete,
				() => {
					this.backgrounds[GridBackground.Higher].alpha = 1;
					this.backgrounds[GridBackground.Higher].visible = false;
					this.onAnimationComplete(tween);
				},
				this
			);
			this.animations.push(tween);
			tween.play();
		}

		if (this.backgrounds[GridBackground.Lower].visible) {
			const tween: PixiTween = new PixiTween(
				[this.backgrounds[GridBackground.Lower]],
				TweenProperty.Alpha,
				this
			).to(0, 15);
			tween.once(
				Events.Animation.Complete,
				() => {
					this.backgrounds[GridBackground.Lower].alpha = 1;
					this.backgrounds[GridBackground.Lower].visible = false;
					this.onAnimationComplete(tween);
				},
				this
			);
			this.animations.push(tween);
			tween.play();
		}
	}

	protected onChoiceFailed(): void {
		this.clearCurrentAnimations();

		this.backgroundContainer.setChildIndex(
			this.backgrounds[GridBackground.Failed],
			this.backgroundContainer.children.length - 1
		);

		this.backgrounds[GridBackground.Failed].visible = true;
		this.backgrounds[GridBackground.Failed].alpha = 0;

		const tween: PixiTween = new PixiTween(
			[this.backgrounds[GridBackground.Failed]],
			TweenProperty.Alpha,
			this
		).fromTo(1, 0, 8);
		tween.once(
			Events.Animation.Complete,
			() => {
				this.backgrounds[GridBackground.Failed].alpha = 0;
				this.backgrounds[GridBackground.Failed].visible = false;
				this.onAnimationComplete(tween);
			},
			this
		);
		this.animations.push(tween);
		tween.play();
	}

	protected createMasks(): void {
		this.higherMask = new Graphics();
		this.higherMask.beginFill(0xffffff);
		this.higherMask.drawRect(0, 0, 1, 360);
		this.higherMask.endFill();
		this.higherMask.y = 360;
		this.backgrounds[GridBackground.Higher].addChild(this.higherMask);
		this.backgrounds[GridBackground.Higher].visible = false;

		this.lowerMask = new Graphics();
		this.lowerMask.beginFill(0xffffff);
		this.lowerMask.drawRect(0, 0, 1, 360);
		this.lowerMask.endFill();
		this.lowerMask.y = -360;
		this.backgrounds[GridBackground.Lower].addChild(this.lowerMask);
		this.backgrounds[GridBackground.Lower].visible = false;
	}

	protected drawChoiceFades(): void {
		const width: number = 1;
		const height: number = 360;

		const higherFade: Graphics = new Graphics();
		higherFade.beginFill(0xffffff, 0.8);
		higherFade.drawRect(0, 0, width, 1);
		higherFade.beginFill(0xffffff, 0.6);
		higherFade.drawRect(0, 1, width, 1);
		higherFade.beginFill(0xffffff, 0.4);
		higherFade.drawRect(0, 2, width, 1);
		higherFade.beginFill(0xffffff, 0.2);
		higherFade.drawRect(0, 3, width, 1);
		higherFade.endFill();
		higherFade.y = height;
		this.backgrounds[GridBackground.Higher].addChild(higherFade);
		this.higherFade = higherFade;

		const lowerFade: Graphics = new Graphics();
		lowerFade.beginFill(0xffffff, 0.2);
		lowerFade.drawRect(0, 0, width, 1);
		lowerFade.beginFill(0xffffff, 0.4);
		lowerFade.drawRect(0, 1, width, 1);
		lowerFade.beginFill(0xffffff, 0.6);
		lowerFade.drawRect(0, 2, width, 1);
		lowerFade.beginFill(0xffffff, 0.8);
		lowerFade.drawRect(0, 3, width, 1);
		lowerFade.endFill();
		lowerFade.y = -lowerFade.height;
		this.backgrounds[GridBackground.Lower].addChild(lowerFade);
		this.lowerFade = lowerFade;
	}

	protected createBackgrounds(): void {
		this.backgrounds[GridBackground.Default] = this.createGradient({
			direction: GradientDirection.ToBottom,
			colorStops: [
				{ offset: 0, color: "#111027" },
				{ offset: 0.15, color: "#2D1135" },
				{ offset: 0.3, color: "#723880" },
				{ offset: 0.35, color: "#723880" },
				{ offset: 0.5, color: "#207142" },
				{ offset: 0.75, color: "#206f7a" },
				{ offset: 0.85, color: "#1a2977" },
				{ offset: 0.9, color: "#18194a" },
				{ offset: 1, color: "#111429" }
			]
		});
		this.backgrounds[GridBackground.Default].alpha = 0.75;

		this.backgrounds[GridBackground.Lower] = this.createGradient({
			direction: GradientDirection.ToBottom,
			colorStops: [
				{ offset: 0, color: "#111027" },
				{ offset: 0.2, color: "#491544" },
				{ offset: 0.45, color: "#723880" },
				{ offset: 0.55, color: "#9959d4" },
				{ offset: 0.75, color: "#441e89" },
				{ offset: 0.9, color: "#222852" },
				{ offset: 1, color: "#111429" }
			]
		});

		this.backgrounds[GridBackground.Higher] = this.createGradient({
			direction: GradientDirection.ToBottom,
			colorStops: [
				{ offset: 0, color: "#111027" },
				{ offset: 0.2, color: "#20154c" },
				{ offset: 0.45, color: "#103885" },
				{ offset: 0.55, color: "#1056b4" },
				{ offset: 0.75, color: "#131e8b" },
				{ offset: 0.9, color: "#12135d" },
				{ offset: 1, color: "#111429" }
			]
		});

		this.backgrounds[GridBackground.Failed] = this.createGradient({
			direction: GradientDirection.ToBottom,
			colorStops: [
				{ offset: 0, color: "#270f11" },
				{ offset: 0.2, color: "#4c0f20" },
				{ offset: 0.45, color: "#850f10" },
				{ offset: 0.55, color: "#b40f10" },
				{ offset: 0.75, color: "#8b0f13" },
				{ offset: 0.9, color: "#5d0f12" },
				{ offset: 1, color: "#290f11" }
			]
		});

		this.backgrounds[GridBackground.Failed].visible = false;

		this.backgroundContainer.addChild(this.backgrounds[GridBackground.Default]);
		this.backgroundContainer.addChild(this.backgrounds[GridBackground.Failed]);
		this.backgroundContainer.addChild(this.backgrounds[GridBackground.Lower]);
		this.backgroundContainer.addChild(this.backgrounds[GridBackground.Higher]);
		this.animContainer.addChild(this.backgroundContainer);
	}

	protected createGradient(gradient: Gradient): Sprite {
		const texture: Texture | undefined = GraphicsUtils.createGradientTexture(
			gradient.colorStops,
			1,
			360,
			gradient.direction
		);

		if (!texture) {
			throw new Error("Unable to create background from gradient:" + gradient);
		}

		return Sprite.from(texture);
	}
}

import { injectable } from "inversify";
import { ComponentBuilder } from "./ComponentBuilder";
import { LayoutComponent } from "../LayoutComponent";
import { appContainer } from "../../ioc";
import { SpriteAnimationConfig } from "../config";
import { LayoutSpriteAnimation } from "../LayoutSpriteAnimation";
import { GraphicsUtils } from "../../utils";

@injectable()
/**
 * The SpriteAnimationBuilder creates sprite animations from a sequence of images.
 *
 * @see LayoutSpriteAnimation
 * @see SpriteAnimationConfig
 */
export class SpriteAnimationBuilder implements ComponentBuilder {
	public build(config: SpriteAnimationConfig<any>, parentDimensions?: { width: number; height: number }): LayoutComponent {
		const animation: LayoutSpriteAnimation = new LayoutSpriteAnimation(
			GraphicsUtils.createTextureSequence(config.frames.baseName, config.frames.count),
			config.layout
		);

		if (config.id != undefined) {
			animation.name = config.id;
			appContainer.bind<LayoutSpriteAnimation>(config.id).toConstantValue(animation);
		}

		if (config.visible === false) {
			animation.visible = false;
		}

		if (config.alpha != undefined) {
			animation.alpha = config.alpha;
		}

		return animation;
	}
}

import { Components } from "../types/Components";
import { Application, Ticker } from "pixi.js";
import { injectable } from "inversify";
import { ClientService } from "../io";
import { GameModel } from "../models/GameModel";
import {
	Button,
	EventBus,
	Events,
	LayoutContainer,
	lazyInject,
	NineSliceButton,
	PlayerRankInfo,
	PrizeInfo,
	Sine,
	Services,
	TextField,
	ToggleButton,
	Translations,
	Tween,
	Types
} from "@tournament/ui-core";
import { HiLoEvents } from "../events";
import { HiLoGameMode } from "../models/HiLoGameMode";
import Dinero from "dinero.js";
import Config from "../config";

@injectable()
export class UIController {
	@lazyInject(Services.Client)
	protected clientService!: ClientService;

	@lazyInject(Types.Translations)
	protected translations!: Translations;

	@lazyInject(Types.EventBus)
	protected eventBus!: EventBus;

	@lazyInject(Types.Application)
	protected application!: Application;

	@lazyInject(Types.GameModel)
	protected model!: GameModel;

	@lazyInject(Components.GameOverDialog)
	protected gameOverDialog!: LayoutContainer;

	@lazyInject(Components.ObserverGameOverDialog)
	protected observerGameOverDialog!: LayoutContainer;

	@lazyInject(Components.SpectatorModeButton)
	protected spectatorModeButton!: LayoutContainer;

	@lazyInject(Components.ObserverGameWonDialog)
	protected observerGameWonDialog!: LayoutContainer;

	@lazyInject(Components.GameWinnersContainer)
	protected gameWinnersContainer!: TextField;

	@lazyInject(Components.ObserveGameButton)
	protected observeGameButton!: Button;

	@lazyInject(Components.PrizeWinDialog)
	protected prizeWinDialog!: LayoutContainer;

	@lazyInject(Components.PrizeWinValue)
	protected prizeWinValue!: TextField;

	@lazyInject(Components.NumUsersUpBackground)
	protected numUsersUpBackground!: LayoutContainer;

	@lazyInject(Components.NumUsersDownBackground)
	protected numUsersDownBackground!: LayoutContainer;

	@lazyInject(Components.NumUsersUpText)
	protected numUsersUpText!: TextField;

	@lazyInject(Components.NumUsersDownText)
	protected numUsersDownText!: TextField;

	@lazyInject(Components.RoundsText)
	protected roundsText!: TextField;

	@lazyInject(Components.NumRemainingText)
	protected numRemainingText!: TextField;

	@lazyInject(Components.SoundButton)
	protected soundButton!: ToggleButton;

	@lazyInject(Components.CloseButton)
	protected closeButton!: Button;

	@lazyInject(Components.LeaderboardGameInfoConfirm)
	protected LeaderboardGameInfoConfirm!: Button;

	@lazyInject(Components.LeaderboardGameInfoDialog)
	protected LeaderboardGameInfoDialog!: LayoutContainer;

	@lazyInject(Components.LeaderBoardInfoPrizeValue1)
	protected LeaderboardGameInfoPrize1!: TextField;

	@lazyInject(Components.LeaderBoardInfoPrizeValue2)
	protected LeaderboardGameInfoPrize2!: TextField;

	@lazyInject(Components.LeaderBoardInfoPrizeValue3)
	protected LeaderboardGameInfoPrize3!: TextField;

	@lazyInject(Components.TournamentEndRanking)
	protected tournamentEndRanking!: TextField;

	@lazyInject(Components.LeaderboardGameTitle)
	protected LeaderboardGameTitle!: TextField;

	@lazyInject(Components.LeaderboardGameDescription)
	protected LeaderboardGameDescription!: TextField;

	@lazyInject(Components.LeaderboardGameOverDialog)
	protected LeaderboardGameOverDialog!: LayoutContainer;

	@lazyInject(Components.LeaderboardPlayAgainMessage)
	protected LeaderboardPlayAgainMessage!: TextField;

	@lazyInject(Components.LeaderboardNoMoreEntriesDialog)
	protected LeaderboardNoMoreEntriesDialog!: LayoutContainer;

	@lazyInject(Components.LeaderboardTournamentEndedDialog)
	protected LeaderboardTournamentEndedDialog!: LayoutContainer;

	@lazyInject(Components.LeaderboardCurrentRanking)
	protected LeaderboardCurrentRanking!: TextField;

	@lazyInject(Components.LeaderboardTournamentEndedConfirm)
	protected tournamentEndedConfirm!: NineSliceButton;

	@lazyInject(Components.TournamentEndCountdown)
	protected tournamentEndCountdown!: TextField;

	@lazyInject(Components.MillionareCountDown)
	protected millionaireCountDown!: TextField;

	@lazyInject(Components.SurvivalGameInfoConfirm)
	protected survivalGameInfoConfirm!: Button;

	@lazyInject(Components.SurvivalGameInfoDialog)
	protected survivalGameInfoDialog!: LayoutContainer;

	@lazyInject(Components.SurvivalGameTitle)
	protected survivalGameTitle!: TextField;

	@lazyInject(Components.SurvivalGameDescription)
	protected survivalGameDescription!: TextField;

	@lazyInject(Components.LastManGameInfoConfirm)
	protected lastManGameInfoConfirm!: Button;

	@lazyInject(Components.LastManGameInfoDialog)
	protected lastManGameInfoDialog!: LayoutContainer;

	@lazyInject(Components.LastManGameTitle)
	protected lastManGameTitle!: TextField;

	@lazyInject(Components.LastManGameDescription)
	protected lastManGameDescription!: TextField;

	@lazyInject(Components.MillionaireInfoDialog)
	protected millionaireInfoDialog!: LayoutContainer;

	@lazyInject(Components.MillionaireMaxPrizeValue)
	protected millionaireMaxPrizeValue!: TextField;

	@lazyInject(Components.MillionaireInfoConfirm)
	protected millionaireInfoConfirm!: TextField;

	@lazyInject(Components.MillionaireGameOverDialog)
	protected millionaireGameOverDialog!: LayoutContainer;

	@lazyInject(Components.MillionairePlayTomorrowDialog)
	protected millionairePlayTomorrowDialog!: LayoutContainer;

	@lazyInject(Components.MillionairePlayTomorrowTitle)
	protected millionairePlayTomorrowTitle!: TextField;

	@lazyInject(Components.NumberRangeText)
	protected numberRangeText!: TextField;

	protected currentNumber: number | undefined;

	protected previousNumRemainingParticipants: number = 0;

	protected counter: number = 0;

	protected currentTween: Tween | undefined;

	protected lastNumbersUp: number = 0;

	protected lastNumbersDown: number = 0;

	public constructor() {
		this.spectatorModeButton.visible = false;
		this.numUsersUpBackground.visible = false;
		this.numUsersDownBackground.visible = false;
		this.numUsersUpText.setText("");
		this.numUsersDownText.setText("");

		this.eventBus.on(HiLoEvents.Game.Reset, this.reset, this);
		this.eventBus.once(HiLoEvents.Game.Init, this.initGame, this);
		this.eventBus.on(HiLoEvents.Game.StatusUpdated, this.updateStatus, this);
		this.eventBus.once(HiLoEvents.Game.RoundUpdated, this.updateInitialNumber, this);
		this.eventBus.on(HiLoEvents.Game.RoundWin, this.setRoundNumber, this);
		// this.eventBus.on(HiLoEvents.User.ExitSpectatorMode, this.exitSpectatorMode, this);

		if (this.model.gameMode === HiLoGameMode.Standard) {
			this.eventBus.on(HiLoEvents.Game.ShowObserveLoseDialog, () => {
				setTimeout(() => {
					this.showObserverGameOverDialog();
				}, 0);
			});
			this.eventBus.on(HiLoEvents.Game.ShowObserveWinDialog, () => {
				setTimeout(() => {
					this.showObserverGameWonDialog();
				}, 0);
			});
			this.eventBus.on(HiLoEvents.Game.ShowPrizeWinDialog, () => {
				setTimeout(() => {
					this.onShowPrizeWinDialog();
				}, 0);
			});
			this.eventBus.on(HiLoEvents.User.ObserveGame, this.onObserveGame, this);
		}

		this.eventBus.on(HiLoEvents.Game.ShowGameOverDialog, () => {
			setTimeout(() => {
				this.showGameOverDialog();
			}, 0);
		});

		this.eventBus.on(HiLoEvents.Game.GameOver, this.onGameComplete, this);

		this.eventBus.on(HiLoEvents.Game.PrizeWin, this.onPrizeWin, this);

		this.eventBus.on(HiLoEvents.Game.HideButtons, this.hideButtons, this);
		this.eventBus.on(HiLoEvents.Game.ShowButtons, this.showButtons, this);

		this.eventBus.once(HiLoEvents.Game.ShowGameInfoDialog, this.showGameInfo, this);

		this.numRemainingText.setText("");
		Ticker.shared.add(this.update, this);

		if (!Config.controlsEnabled) {
			this.hideButtons();
		}
	}

	protected exitSpectatorMode():void{
		this.spectatorModeButton.visible = false;
	}

	protected reset(): void {
		this.currentNumber = undefined;
		this.millionaireGameOverDialog.visible = false;
		this.LeaderboardGameOverDialog.visible = false;
		this.numUsersUpBackground.visible = false;
		this.numUsersDownBackground.visible = false;
		this.LeaderboardNoMoreEntriesDialog.visible = false;
		this.roundsText.setText("");
		this.numUsersUpText.setText("");
		this.numUsersDownText.setText("");
		this.numRemainingText.setText("");
		this.lastNumbersUp = 0;
		this.lastNumbersDown = 0;
		this.showButtons();

		this.eventBus.once(HiLoEvents.Game.Init, this.initGame, this);
	}

	protected initGame(): void {
		if (this.model.minNumber && this.model.maxNumber) {
			this.numberRangeText.setText(
				this.translations.get("numberRangeMessage", { min: this.model.minNumber, max: this.model.maxNumber })
			);
		}

		this.setRoundNumber();
	}

	protected showButtons(): void {
		if (!Config.controlsEnabled) {
			return;
		}

		this.closeButton.visible = true;
		this.soundButton.visible = true;
	}

	protected hideButtons(): void {
		this.closeButton.visible = false;
		this.soundButton.visible = false;
	}

	protected update(deltaFrame: number): void {
		if (this.currentTween) {
			this.currentTween.update(deltaFrame);
			if (this.model.gameMode === HiLoGameMode.Standard) {
				this.setParticipantsRemaining(Math.floor(this.previousNumRemainingParticipants));
			}

			if (!this.currentTween.isPlaying()) {
				this.currentTween = undefined;
			}
		}

		if (this.model.gameMode === HiLoGameMode.Leaderboard && this.LeaderboardNoMoreEntriesDialog.visible) {
			this.updateTournamentEndTimer();
		} else if (this.model.gameMode === HiLoGameMode.Millionaire && this.millionairePlayTomorrowDialog.visible) {
			this.updateTournamentEndTimer();
		}
	}

	protected updateInitialNumber(): void {
		if (this.model.numParticipantsRemaining && this.model.gameMode === HiLoGameMode.Standard) {
			this.previousNumRemainingParticipants = this.model.numParticipantsRemaining;
			this.numRemainingText.setText(
				this.translations.get("playersRemaining", {
					count: this.model.numParticipantsRemaining
				})
			);
		}

		this.numUsersUpText.setText("");
		this.numUsersDownText.setText("");
		this.numUsersUpBackground.visible = false;
		this.numUsersDownBackground.visible = false;

		this.setRoundNumber();
		this.currentNumber = 1;

		this.eventBus.on(HiLoEvents.Game.RoundUpdated, this.updateCurrentNumber, this);
	}

	protected setRoundNumber(): void {
		if (this.clientService.getIsSpectatorMode()) {
			this.spectatorModeButton.visible = true;
			this.roundsText.setText(this.translations.get("roundTitle", { count: this.model.roundNumber }));
		}
		else if (this.model.roundNumber) {
			if (this.model.gameMode === HiLoGameMode.Leaderboard || this.model.gameMode === HiLoGameMode.Millionaire) {
				const roundNumber: number =
					this.model.playerRoundNumber && this.model.playerRoundNumber > 0 ? this.model.playerRoundNumber : 1;

				this.roundsText.setText(this.translations.get("roundTitle", { count: roundNumber }));
			} else if (this.model.maxRounds) {
				this.roundsText.setText(
					this.translations.get("survivalRoundTitle", {
						count: this.model.roundNumber,
						total: this.model.maxRounds
					})
				);
			} else {
				this.roundsText.setText(this.translations.get("roundTitle", { count: this.model.roundNumber }));
			}
		}
	}

	protected setLeaderBoardPosition(rank: number, totalPlayers: number): void {
		if (this.model.startTime == undefined || this.model.startTime > Date.now()) {
			this.numRemainingText.setText("");
		} else {
			this.numRemainingText.setText(
				this.translations.get("leaderboardPosition", { count: this.makeOrdinal(rank), total: totalPlayers })
			);
		}
	}

	// TODO: Use a library to make this locale specific.
	protected makeOrdinal(value: number): string {
		if (value === 0) {
			return `{value}`;
		}

		switch (value) {
			case 11:
			case 12:
			case 13:
				return `${value.toLocaleString(this.model.locale)}th`;
		}

		const lastDigit: number = +value.toString(10).slice(-1);

		switch (lastDigit) {
			case 1:
				return `${value.toLocaleString(this.model.locale)}st`;
			case 2:
				return `${value.toLocaleString(this.model.locale)}nd`;
			case 3:
				return `${value.toLocaleString(this.model.locale)}rd`;
			default:
				return `${value.toLocaleString(this.model.locale)}th`;
		}
	}

	protected setParticipants(value: number): void {
		if (this.model.gameMode === HiLoGameMode.Standard) {
			if (value === 0) {
				this.numRemainingText.setText("");
			} else {
				this.numRemainingText.setText(this.translations.get("playersRegistered", { count: value }));
			}
		}
	}

	protected setParticipantsRemaining(value: number): void {
		if (this.model.gameMode === HiLoGameMode.Standard) {
			if (value === 0) {
				this.numRemainingText.setText(this.translations.get("noPlayersRemaining"));
			} else {
				this.numRemainingText.setText(this.translations.get("playersRemaining", { count: value }));
			}
		}
	}

	protected updateParticipantsRemaining(): void {
		this.counter = 0;
		if (this.model.numParticipantsRemaining && this.model.gameMode === HiLoGameMode.Standard) {
			if (!this.currentTween) {
				this.currentTween = new Tween(this, "previousNumRemainingParticipants", this).to(
					this.model.numParticipantsRemaining,
					60,
					Sine.easeOut
				);
				this.currentTween.play();
			}
		}
	}

	protected updateStatus(): void {
		if (this.model.gameMode === HiLoGameMode.Standard && this.model.participants && !this.model.roundNumber) {
			this.setParticipants(this.model.participants);
		} else if (
			this.model.gameMode === HiLoGameMode.Leaderboard &&
			this.model.leaderboardRankings != undefined &&
			this.model.leaderboardTotalPlayers != undefined
		) {
			for (const player of this.model.leaderboardRankings) {
				if (player.isPlayer === true) {
					this.setLeaderBoardPosition(player.rank, this.model.leaderboardTotalPlayers);
				}
			}
		}

		if (this.model.startTime && this.model.startTime < Date.now()) {
			const numUp: number = this.model.numUpPlayers || 0;
			const numDown: number = this.model.numDownPlayers || 0;

			if (numUp > 0) {
				this.numUsersUpText.setText(numUp.toString(10));
				this.numUsersUpBackground.visible = true;
			} else {
				this.numUsersUpText.setText("");
				this.numUsersUpBackground.visible = false;
			}

			if (numDown > 0) {
				this.numUsersDownText.setText(numDown.toString(10));
				this.numUsersDownBackground.visible = true;
			} else {
				this.numUsersDownText.setText("");
				this.numUsersDownBackground.visible = false;
			}

			this.lastNumbersUp = numUp;
			this.lastNumbersDown = numDown;
		}
	}

	protected updateCurrentNumber(): void {
		if (this.clientService.getIsSpectatorMode()) {
			this.roundsText.setText(this.translations.get("roundTitle", { count: this.model.roundNumber }));
		}
		if (this.currentNumber !== this.model.currentNumber) {
			if (this.model.gameMode === HiLoGameMode.Standard) {
				this.updateParticipantsRemaining();
			} else if (
				this.model.gameMode === HiLoGameMode.Leaderboard &&
				this.model.leaderboardRankings != undefined &&
				this.model.leaderboardTotalPlayers != undefined
			) {
				for (const player of this.model.leaderboardRankings) {
					if (player.isPlayer === true) {
						this.setLeaderBoardPosition(player.rank, this.model.leaderboardTotalPlayers);
					}
				}
			}

			this.currentNumber = this.model.currentNumber;
		}
	}

	protected showObserverGameOverDialog(): void {
		this.updateParticipantsRemaining();

		this.observerGameOverDialog.visible = true;
	}

	protected showObserverGameWonDialog(): void {
		this.updateParticipantsRemaining();

		let winnersText: string = "";

		if (this.model.winners) {
			for (let i = 0; i < this.model.winners.length; i++) {
				const winAmount: string = Dinero({
					amount: this.model.winners[i].winAmount,
					currency: this.model.currency as any
				}).toFormat("$0,0.00");

				winnersText += this.model.winners[i].nickname + " won " + winAmount + "\n";

				if (i === 7 && this.model.winners.length > 8) {
					winnersText += "\n" + this.translations.get("andXMore", { count: this.model.winners.length - 8 });
				}
			}
		}

		this.gameWinnersContainer.setText(winnersText);

		this.observerGameWonDialog.visible = true;
	}

	protected showGameOverDialog(): void {
		this.LeaderboardGameInfoDialog.visible = false;
		this.lastManGameInfoDialog.visible = false;
		this.survivalGameInfoDialog.visible = false;

		if (this.model.gameMode === HiLoGameMode.Standard) {
			this.updateParticipantsRemaining();

			this.gameOverDialog.visible = true;

			if (this.model.winners != undefined) {
				if (this.observeGameButton.parent != undefined) {
					this.observeGameButton.parent.removeChild(this.observeGameButton);
				}

				this.gameOverDialog.resize({
					width: this.application.screen.width,
					height: this.application.screen.height
				});
			}
		} else {
			if (
				(this.model.livesRemaining || 0) > 0 &&
				this.model.gameMode === HiLoGameMode.Leaderboard
			) {
				if (this.model.gameMode === HiLoGameMode.Leaderboard) {
					this.LeaderboardGameOverDialog.visible = true;

					this.LeaderboardPlayAgainMessage.setText(
						this.translations.get("playAgainMessage", {
							count: this.model.livesRemaining
						})
					);
					this.eventBus.emit(HiLoEvents.Game.KnockedOut, this.model.livesRemaining, this.model.livesPerEntry);
				} else {
					this.millionaireGameOverDialog.visible = true;
				}
			} else if (this.model.nextRoundStartTime == undefined) {
				this.showTournamentEnded();
			} else {
				if (this.model.gameMode === HiLoGameMode.Leaderboard) {
					this.LeaderboardNoMoreEntriesDialog.visible = true;
					this.eventBus.emit(HiLoEvents.Game.KnockedOut, this.model.livesRemaining, this.model.livesPerEntry);
				} else if (this.model.gameMode === HiLoGameMode.Millionaire) {
					this.millionairePlayTomorrowDialog.visible = true;

					this.millionairePlayTomorrowTitle.setText(
						this.translations.get("millionaireAlreadyPlayed", { count: this.model.livesPerEntry })
					);
				}

				this.updateTournamentEndTimer();

				if (
					this.model.leaderboardRankings != undefined &&
					this.model.leaderboardTotalPlayers != undefined
				) {
					for (const player of this.model.leaderboardRankings) {
						if (player.isPlayer === true) {
							this.LeaderboardCurrentRanking.setText(
								this.translations.get("leaderboardPosition", {
									count: this.makeOrdinal(player.rank),
									total: this.model.leaderboardTotalPlayers
								})
							);
						}
					}
				}
			}
		}
	}

	protected showTournamentEnded(): void {
		if (this.model.gameMode === HiLoGameMode.Leaderboard) {
			this.LeaderboardTournamentEndedDialog.visible = true;
			this.LeaderboardNoMoreEntriesDialog.visible = false;
			this.LeaderboardGameOverDialog.visible = false;
		}

		if (this.model.gameMode === HiLoGameMode.Millionaire) {
			this.millionaireGameOverDialog.visible = false;
			this.millionaireInfoDialog.visible = false;
			this.millionairePlayTomorrowDialog.visible = false;
			this.LeaderboardTournamentEndedDialog.visible = true;
		}

		if (this.model.leaderboardRankings != undefined && this.model.leaderboardTotalPlayers != undefined) {
			const player = this.model.leaderboardRankings.find(p => p.isPlayer);

			if (player) {
				this.tournamentEndRanking.visible = true;
				this.tournamentEndRanking.setText(
					this.translations.get("finalRanking", {
						rank: this.makeOrdinal(player.rank),
						total: this.model.leaderboardTotalPlayers
					})
				);
			} else
				this.tournamentEndRanking.visible = false;
		}
	}

	protected showLeaderboardGameInfo(playerRankings: Array<PlayerRankInfo>, prizes: Array<PrizeInfo>): void {
		this.LeaderboardGameInfoDialog.visible = true;
		this.setGameInfoPrizes(prizes);
	}

	protected setGameInfoPrizes(prizes: Array<PrizeInfo>): void {
		this.LeaderboardGameInfoPrize1.setText(
			Dinero({ amount: prizes[0].amount * 100, currency: prizes[0].currencyCode as any }).toFormat("$0,0.00")
		);
		this.LeaderboardGameInfoPrize2.setText(
			Dinero({ amount: prizes[1].amount * 100, currency: prizes[1].currencyCode as any }).toFormat("$0,0.00")
		);
		this.LeaderboardGameInfoPrize3.setText(
			Dinero({ amount: prizes[2].amount * 100, currency: prizes[2].currencyCode as any }).toFormat("$0,0.00")
		);
	}

	protected updateTournamentEndTimer(): void {
		if (this.model.endTime) {
			if (Date.now() < this.model.endTime) {
				let secondsUntilEnd: number = Math.max(Math.floor((this.model.endTime - Date.now()) / 1000), 0);

				if (secondsUntilEnd === 0) {
					this.showTournamentEnded();
					return;
				}

				const hours: number = Math.floor(secondsUntilEnd / 3600);
				const minutes: number = Math.floor(secondsUntilEnd / 60) - hours * 60;

				secondsUntilEnd = secondsUntilEnd % 60;

				let timeUntilEnd: string = "";

				if (hours < 10) {
					timeUntilEnd += "0";
				}

				timeUntilEnd += hours + ":";

				if (minutes < 10) {
					timeUntilEnd += "0";
				}

				timeUntilEnd += minutes + ":";

				if (secondsUntilEnd < 10) {
					timeUntilEnd += "0";
				}

				timeUntilEnd += secondsUntilEnd;

				if (this.model.gameMode === HiLoGameMode.Leaderboard) {
					this.tournamentEndCountdown.setText(timeUntilEnd);
					this.tournamentEndCountdown.resize(this.tournamentEndCountdown.getParentDimensions());
				} else {
					this.millionaireCountDown.setText(timeUntilEnd);
					this.millionaireCountDown.resize(this.tournamentEndCountdown.getParentDimensions());
				}
			}
		}
	}

	protected onObserveGame(): void {
		this.gameOverDialog.visible = false;
	}

	protected onGameComplete(): void {
		if (this.model.gameMode === HiLoGameMode.Standard) {
			this.updateParticipantsRemaining();
		}
	}

	protected onPrizeWin(): void {
		this.updateParticipantsRemaining();
	}

	protected onShowPrizeWinDialog(): void {
		this.prizeWinDialog.visible = true;

		this.prizeWinValue.setText(
			Dinero({ amount: (this.model.winAmount as number) * 100, currency: this.model.currency as any }).toFormat(
				"$0,0.00"
			)
		);

		this.prizeWinDialog.resize({
			width: this.application.screen.width,
			height: this.application.screen.height
		});

		setTimeout(() => {
			this.eventBus.emit(HiLoEvents.Animation.ShowPrizeWinAnimation);
		}, 0);
	}

	protected showGameInfo(): void {
		if (this.model.gameMode === HiLoGameMode.Leaderboard) {
			if (this.model.prizes != undefined) {
				this.LeaderboardGameInfoDialog.visible = true;
				this.setGameInfoPrizes(this.model.prizes);
			} else {
				this.eventBus.once(Events.LeaderBoard.Update, this.showLeaderboardGameInfo, this);
			}

			this.LeaderboardGameDescription.setText(
				this.translations.get("leaderboardDescription", {
					entries: this.model.livesPerEntry as number,
					count: this.model.pointsPerRound || 1
				})
			);
			this.LeaderboardGameTitle.setText(this.translations.get("leaderboardTitle", { count: this.model.maxRounds }));
			this.LeaderboardGameInfoConfirm.once(Events.Interaction.Click, this.closeLeaderboardGameInfoDialog, this);
		} else if (this.model.gameMode === HiLoGameMode.Millionaire) {
			this.millionaireInfoDialog.visible = true;
			// TODO: Set max prize value.
			if (this.model.prizeLadder) {
				const lastPrize: number = this.model.prizeLadder[this.model.prizeLadder.length - 1].prizeAmount;

				this.millionaireMaxPrizeValue.setText(
					Dinero({
						amount: lastPrize * 100,
						currency: this.model.currency as any
					}).toFormat("$0,0.00")
				);
			}
			this.millionaireInfoConfirm.once(Events.Interaction.Click, this.closeMillionaireInfoDialog, this);
		} else if (this.model.gameMode === HiLoGameMode.Standard) {
			if (this.model.maxRounds != undefined) {
				this.survivalGameInfoDialog.visible = true;
				this.survivalGameDescription.setText(this.translations.get("survivalDescription"));
				this.survivalGameTitle.setText(this.translations.get("survivalTitle", { count: this.model.maxRounds }));

				this.survivalGameInfoConfirm.once(Events.Interaction.Click, this.closeSurvivalInfoDialog, this);
			} else {
				const numWinners: number = this.model.maxWinners || 1;

				this.lastManGameInfoDialog.visible = true;
				this.lastManGameDescription.setText(this.translations.get("lastManDescription", { count: numWinners }));
				this.lastManGameTitle.setText(this.translations.get("lastManTitle", { count: numWinners }));
				this.lastManGameInfoConfirm.once(Events.Interaction.Click, this.closeLastManInfoDialog, this);
			}
		}
	}

	protected closeSurvivalInfoDialog(): void {
		this.survivalGameInfoDialog.visible = false;
		this.eventBus.emit(HiLoEvents.Game.Join);
	}

	protected closeLastManInfoDialog(): void {
		this.lastManGameInfoDialog.visible = false;
		this.eventBus.emit(HiLoEvents.Game.Join);
	}

	protected closeLeaderboardGameInfoDialog(): void {
		this.LeaderboardGameInfoDialog.visible = false;
		this.eventBus.emit(HiLoEvents.Game.Join);
	}

	protected closeMillionaireInfoDialog(): void {
		this.millionaireInfoDialog.visible = false;
		this.eventBus.emit(HiLoEvents.Game.Join);
	}
}

import { Sprite, Texture } from "pixi.js";
import { injectable } from "inversify";
import { lazyInject } from "../ioc";
import { Types } from "../types/Types";
import { LayoutComponent } from "./LayoutComponent";
import { LayoutManager } from "../layout";
import { EventBus } from "../events";
import { FixedDimensions, Layout, Rect } from "./config";

@injectable()
export class LayoutSprite extends Sprite implements LayoutComponent {
	@lazyInject(Types.LayoutManager)
	protected layoutManager!: LayoutManager;

	@lazyInject(Types.EventBus)
	protected eventBus!: EventBus;

	/**
	 * @inheritDoc
	 */
	public onResize: ((dimensions: FixedDimensions) => void) | undefined;

	/**
	 * The layout configuration for this component.
	 */
	protected layoutConfig: Layout;
	/**
	 * The layout dimensions calculated for the component (does not necessarily match the actual dimensions!)
	 */
	protected layoutDimensions: Rect | undefined;

	public constructor(texture: Texture, layout: Layout) {
		super(texture);

		this.roundPixels = true;

		this.layoutConfig = layout;
	}

	/**
	 * @inheritDoc
	 */
	public getLayoutDimensions(): Rect | undefined {
		return this.layoutDimensions;
	}

	/**
	 * @inheritDoc
	 */
	public getWidth(): number {
		return (this.layoutDimensions && this.layoutDimensions.width) || this.width;
	}

	/**
	 * @inheritDoc
	 */
	public getHeight(): number {
		return (this.layoutDimensions && this.layoutDimensions.height) || this.height;
	}

	/**
	 * @inheritDoc
	 */
	public setXPosition(value: number): void {
		if (this.layoutDimensions) {
			this.x = value;
			this.layoutDimensions.x = value;
		}
	}

	/**
	 * @inheritDoc
	 */
	public setYPosition(value: number): void {
		if (this.layoutDimensions) {
			this.y = value;
			this.layoutDimensions.y = value;
		}
	}

	/**
	 * @inheritDoc
	 */
	public setXOffset(value: number): void {
		if (this.layoutDimensions) {
			this.x = value + this.layoutDimensions.x;
		}
	}

	/**
	 * @inheritDoc
	 */
	public setYOffset(value: number): void {
		if (this.layoutDimensions) {
			this.y = value + this.layoutDimensions.y;
		}
	}

	/**
	 * @inheritDoc
	 */
	public resize(parentDimensions: FixedDimensions): FixedDimensions {
		const prevDimensions: Rect | undefined = this.layoutDimensions;

		try {
			const { x, y, width, height } = this.layoutManager.calculate(this.layoutConfig, parentDimensions);

			this.x = x;
			this.y = y;
			this.width = width;
			this.height = height;

			this.layoutDimensions = { x, y, width, height };
		} catch (error) {
			console.warn("Error resizing:", error);
			this.layoutDimensions = prevDimensions || { x: 0, y: 0, width: 1, height: 1 };
		}

		if (this.onResize != undefined) {
			this.onResize.call(this, this.layoutDimensions);
		}

		return { width: this.layoutDimensions.width, height: this.layoutDimensions.height };
	}
}

import { injectable } from "inversify";
import { Components } from "../types/Components";
import {
	AssetConfig,
	AssetLoader,
	AudioAdapter,
	EventBus,
	Events,
	LayoutContainer,
	lazyInject,
	LoadingBar,
	Logger,
	NineSliceButton,
	TextField,
	Translations,
	Types
} from "@tournament/ui-core";
import { HiLoEvents } from "../events";
import { ErrorMessage, ErrorTitle } from "../types";

@injectable()
export class PreloadController {
	@lazyInject(Types.Translations)
	protected translations!: Translations;

	@lazyInject(Types.EventBus)
	protected eventBus!: EventBus;

	@lazyInject(Components.LoadingBar)
	protected loadingBar!: LoadingBar;

	@lazyInject(Components.SoundDialog)
	protected soundDialog!: LayoutContainer;

	@lazyInject(Types.AudioAdapter)
	protected audioAdapter!: AudioAdapter;

	@lazyInject(Components.SoundDialogCancel)
	protected cancelButton!: NineSliceButton;

	@lazyInject(Components.SoundDialogConfirm)
	protected confirmButton!: NineSliceButton;

	@lazyInject(Components.PreloadErrorDialog)
	protected errorDialog!: LayoutContainer;

	@lazyInject(Components.PreloadErrorDialogConfirm)
	protected errorDialogConfirm!: NineSliceButton;

	@lazyInject(Components.PreloadErrorMessage)
	protected errorMessage!: TextField;

	@lazyInject(Components.PreloadErrorTitle)
	protected errorTitle!: TextField;

	@lazyInject(Components.VersionNumber)
	protected versionNumber!: TextField;

	@lazyInject(Types.VersionInfo)
	protected versionInfo!: string;

	protected assetLoader: AssetLoader = new AssetLoader();

	protected currentProgress: number = 0;

	protected isLoading: boolean = false;

	protected config: AssetConfig | undefined;

	public constructor() {
		this.soundDialog.visible = false;

		this.eventBus.once(Events.Loading.Start, (config: AssetConfig) => {
			this.versionNumber.setText("v" + this.versionInfo);
			this.load(config);
		});

		this.eventBus.on(HiLoEvents.Game.ShowPreloadError, this.displayError, this);

		this.eventBus.once(Events.Loading.LoadSounds, this.loadSounds, this);
	}

	public load(config: AssetConfig): void {
		if (!this.isLoading) {
			this.config = config;
			this.soundDialog.visible = true;
			this.cancelButton.once(Events.Interaction.Click, this.onDisableSound, this);
			this.confirmButton.once(Events.Interaction.Click, this.onEnableSound, this);
			this.eventBus.once(Events.Sound.Enable, this.onEnableSound, this);
			this.eventBus.once(Events.Sound.Disable, this.onDisableSound, this);
		} else {
			throw new Error("Already loading.");
		}
	}

	protected loadAssets(config: AssetConfig, shouldLoadSounds: boolean): void {
		this.currentProgress = 0;
		this.assetLoader.on(Events.Loading.Progress, this.onProgress, this);
		this.assetLoader.once(Events.Loading.Complete, this.onComplete, this);
		this.assetLoader.load(config, shouldLoadSounds);

		this.eventBus.removeListener(Events.Sound.Enable, this.onEnableSound, this);
		this.eventBus.removeListener(Events.Sound.Disable, this.onDisableSound, this);
	}

	protected onProgress(progress: number): void {
		if (this.currentProgress !== progress) {
			this.currentProgress = progress;
			this.loadingBar.setProgress(progress);
		}
	}

	protected onComplete(): void {
		this.assetLoader.removeListener(Events.Loading.Progress, this.onProgress, this);
		this.isLoading = false;
		this.eventBus.emit(Events.Loading.Complete);
	}

	protected onDisableSound(): void {
		this.soundDialog.visible = false;
		if (!this.config) {
			this.displayError(ErrorTitle.Generic, ErrorMessage.GenericLoading);
			return;
		}

		this.loadAssets(this.config, false);
		this.eventBus.emit(Events.Sound.Disabled);
	}

	protected loadSounds(): void {
		console.log("Loading sounds");
		if (!this.config) {
			this.displayError(ErrorTitle.Generic, ErrorMessage.GenericLoading);
			return;
		}

		this.currentProgress = 0;
		this.assetLoader.on(Events.Loading.Progress, this.onProgress, this);
		this.assetLoader.once(Events.Loading.Complete, this.onLoadSoundsComplete, this);
		this.assetLoader.loadSounds(this.config);
	}

	protected onLoadSoundsComplete(): void {
		console.log("Sounds loaded");
		this.assetLoader.removeListener(Events.Loading.Progress, this.onProgress, this);
		this.eventBus.emit(Events.Loading.SoundsLoaded);
	}

	protected onEnableSound(): void {
		this.soundDialog.visible = false;
		if (!this.config) {
			this.displayError(ErrorTitle.Generic, ErrorMessage.GenericLoading);
			return;
		}

		this.audioAdapter.unmute();

		this.loadAssets(this.config, true);
	}

	protected displayError(title: string, message: string): void {
		this.errorTitle.setText(this.translations.get(title));
		this.errorMessage.setText(this.translations.get(message));
		this.errorDialogConfirm.once(Events.Interaction.Click, this.closeGame, this);
		this.errorDialog.visible = true;
		Logger.error(this.translations.getForLang("en", title), this.translations.getForLang("en", message));
	}

	protected closeGame(): void {
		this.eventBus.emit(Events.Interaction.Exit);
	}
}

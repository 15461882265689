import {
	BackgroundType,
	ComponentType,
	ContainerConfig,
	GradientDirection,
	HAlign,
	LayoutMode,
	TextAlign,
	VAlign
} from "@tournament/ui-core";
import { SkinComponentType } from "../../types/SkinComponentType";
import { SkinDisplayChild } from "../../components/config/SkinDisplayChild";
import { Components, HiLoComponentType, HiLoEvents } from "@tournament/hilo-ui-core";

export const ErrorDialog: ContainerConfig<SkinDisplayChild, HiLoComponentType | SkinComponentType> = {
	id: Components.ErrorDialog,
	type: ComponentType.Container,
	visible: false,
	layout: {
		vAlign: VAlign.Middle,
		hAlign: HAlign.Center,
		dimensions: {
			width: 800,
			height: 350,
			widthPercent: 70,
			heightPercent: 50
		},
		childLayout: {
			mode: LayoutMode.Vertical
		}
	},
	children: [
		{
			type: ComponentType.Container,
			background: {
				type: BackgroundType.Color,
				color: 0x230640,
				gradient: {
					colorStops: [
						{ offset: 0, color: "#911ba9" },
						{ offset: 0.03, color: "#230640" },
						{ offset: 0.65, color: "#340750" },
						{ offset: 0.9, color: "#230640" },
						{ offset: 0.95, color: "#520874" },
						{ offset: 0.96, color: "#230640" },
						{ offset: 1, color: "#000000" }
					],
					direction: GradientDirection.ToBottom
				},
				alpha: 1,
				cornerRadius: {
					topLeft: 5,
					topRight: 5
				}
			},
			layout: {
				dimensions: {
					widthPercent: 100,
					heightPercent: 20
				}
			},
			children: [
				{
					id: Components.ErrorTitle,
					type: ComponentType.Text,
					text: {
						id: "errorTitle"
					},
					style: {
						fontFamily: ["Oxygen", "sans-serif"],
						fontWeight: "700",
						fill: 0xf5f0fb,
						align: TextAlign.Center
					},
					layout: {
						dimensions: {
							widthPercent: 30,
							heightPercent: 80
						},
						offset: {
							xPercent: 1
						},
						vAlign: VAlign.Middle,
						hAlign: HAlign.Center
					}
				}
			]
		},
		{
			type: ComponentType.Container,
			background: {
				type: BackgroundType.Color,
				color: 0xf5f0fb,
				alpha: 1,
				cornerRadius: {
					bottomLeft: 5,
					bottomRight: 5
				}
			},
			layout: {
				dimensions: {
					widthPercent: 100,
					heightPercent: 80
				}
			},
			children: [
				{
					id: Components.ErrorMessage,
					type: ComponentType.Text,
					text: {
						id: "genericErrorMessage"
					},
					style: {
						fontFamily: ["Oxygen", "sans-serif"],
						fontWeight: "700",
						fill: 0x0e0d21,
						align: TextAlign.Center
					},
					layout: {
						dimensions: {
							widthPercent: 90,
							heightPercent: 40
						},
						offset: {
							yPercent: 15
						},
						hAlign: HAlign.Center
					}
				},
				{
					type: ComponentType.Container,
					layout: {
						dimensions: {
							widthPercent: 100,
							heightPercent: 25
						},
						offset: {
							yPercent: -10
						},
						vAlign: VAlign.Bottom
					},
					children: [
						{
							id: Components.ErrorDialogConfirm,
							type: ComponentType.NineSliceButton,
							textures: {
								up: "assets/images/Dialog-Button-Up.png",
								over: "assets/images/Dialog-Button-Over.png",
								down: "assets/images/Dialog-Button-Down.png",
								disabled: "assets/images/Dialog-Button-Disabled.png"
							},
							clickEvent: HiLoEvents.User.Close,
							slices: {
								top: 10,
								left: 10,
								right: 10,
								bottom: 10
							},
							layout: {
								dimensions: {
									widthPercent: 30,
									heightPercent: 100
								},
								hAlign: HAlign.Center
							},
							children: [
								{
									type: ComponentType.Text,
									text: {
										id: "confirmLabel"
									},
									style: {
										fontFamily: ["Oxygen", "sans-serif"],
										fontWeight: "700",
										fill: 0xf5f0fb,
										align: TextAlign.Center
									},
									layout: {
										dimensions: {
											widthPercent: 85,
											heightPercent: 60
										},
										vAlign: VAlign.Middle,
										hAlign: HAlign.Center
									}
								}
							]
						}
					]
				}
			]
		}
	]
};

import { injectable } from "inversify";
import { Texture } from "pixi.js";
import { ComponentBuilder } from "./ComponentBuilder";
import { LayoutComponent } from "../LayoutComponent";
import { appContainer } from "../../ioc";
import { ButtonConfig, ToggleButtonConfig } from "../config";
import { Button } from "../Button";
import { ToggleButton } from "../ToggleButton";

@injectable()
/**
 * The ButtonBuilder creates Buttons and ToggleButtons. If 9-Slice functionality is needed, use NineSliceButtonBuilder.
 *
 * @see Button
 * @see ToggleButton
 * @see ButtonConfig
 * @see ToggleButtonConfig
 * @see NineSliceButtonBuilder
 */
export class ButtonBuilder implements ComponentBuilder {
	public build(
		config: ButtonConfig<any> | ToggleButtonConfig<any>,
		parentDimensions?: { width: number; height: number }
	): LayoutComponent {
		const textures: [Texture, Texture, Texture, Texture] = this.getTextures(config.textures);

		let button: Button;

		if (config.offTextures == undefined) {
			button = new Button(textures, config.layout, config.clickEvent);
		} else {
			const toggleButtonConfig: ToggleButtonConfig<any> = config as ToggleButtonConfig<any>;

			const offTextures: [Texture, Texture, Texture, Texture] = this.getTextures(toggleButtonConfig.offTextures);

			button = new ToggleButton(
				{ on: textures, off: offTextures },
				toggleButtonConfig.layout,
				toggleButtonConfig.clickEvent
			);
		}

		if (config.visible === false) {
			button.visible = false;
		}

		if (config.id != undefined) {
			button.name = config.id;
			appContainer.bind<Button>(config.id).toConstantValue(button);
		}

		return button;
	}

	protected getTextures(textureGroup: {
		up: string;
		over?: string;
		down?: string;
		disabled?: string;
	}): [Texture, Texture, Texture, Texture] {
		const upTexture: Texture = Texture.from(textureGroup.up);
		const overTexture: Texture = textureGroup.over ? Texture.from(textureGroup.over) : upTexture;
		const downTexture: Texture = textureGroup.down ? Texture.from(textureGroup.down) : overTexture;
		const disabledTexture: Texture = textureGroup.disabled ? Texture.from(textureGroup.disabled) : downTexture;

		return [upTexture, overTexture, downTexture, disabledTexture];
	}
}

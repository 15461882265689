import { injectable } from "inversify";
import { GameModel } from "../models/GameModel";
import { Components } from "../types/Components";
import {
	Builders,
	ComponentType,
	EventBus,
	FixedDimensions,
	GameBuilder,
	HAlign,
	LayoutContainer,
	LayoutSprite,
	lazyInject,
	TextAlign,
	TextFieldConfig,
	Types,
	VAlign
} from "@tournament/ui-core";
import { HiLoEvents } from "../events";

@injectable()
export class LastNumbersController {
	@lazyInject(Types.EventBus)
	protected eventBus!: EventBus;

	@lazyInject(Types.GameModel)
	protected model!: GameModel;

	// last numbers
	@lazyInject(Builders.Game)
	protected gameBuilder!: GameBuilder;

	@lazyInject(Components.PreviousNumberContainer)
	protected previousNumberContainer!: LayoutContainer;

	@lazyInject(Components.PreviousNumberBackground)
	protected previousNumberBackground!: LayoutSprite;

	protected previousNumbers: LayoutContainer[] = [];

	protected lastNumber?: number;

	public constructor() {
		this.previousNumberContainer.onResize = this.onResize;
		this.eventBus.on(HiLoEvents.Game.Reset, this.reset, this);
		this.eventBus.on(HiLoEvents.Game.ShowPreviousNumber, this.addNumber, this);

		this.eventBus.on(HiLoEvents.User.ObserveGame, this.onObserveGame, this);

		this.eventBus.on(HiLoEvents.Game.GameOver, this.onGameComplete, this);
		this.eventBus.on(HiLoEvents.Game.PrizeWin, this.onGameComplete, this);

		this.eventBus.once(HiLoEvents.Game.Init, this.initLastNumbers, this);
	}

	protected onGameComplete(): void {
		this.previousNumberContainer.visible = false;
		this.previousNumberBackground.visible = false;
	}

	protected onObserveGame(): void {
		this.previousNumberContainer.visible = true;
		this.previousNumberBackground.visible = true;
	}

	protected reset(): void {
		this.previousNumbers = [];
		this.previousNumberContainer.removeChildren();
		this.previousNumberContainer.visible = true;
		this.previousNumberBackground.visible = true;

		this.eventBus.once(HiLoEvents.Game.Init, this.initLastNumbers, this);
	}

	protected initLastNumbers(): void {
		if (this.model.lastNumbers != undefined) {
			const endIndex: number = this.model.lastNumbers.length;
			const startIndex: number = Math.max(0, endIndex - 5);
			for (let i = startIndex; i < endIndex; i++) {
				const prevNumber: LayoutContainer = this.createPreviousNumber(this.model.lastNumbers[i]);
				this.previousNumbers.push(prevNumber);
				this.previousNumberContainer.addChild(prevNumber);
			}
			this.lastNumber = this.model.lastNumbers[this.model.lastNumbers.length - 1];
			this.eventBus.emit(HiLoEvents.Game.Resize);
		}

		if (this.model.currentNumber === undefined) {
			this.eventBus.once(HiLoEvents.Game.RoundUpdated, this.addNumber, this);
		} else {
			this.addNumber();
		}
	}

	protected onResize = (dimensions: FixedDimensions): void => {
		this.previousNumberContainer.x = dimensions.width * 0.095;
	};

	protected addNumber(): void {
		const currentNumber = this.model.currentNumber;

		if (currentNumber === undefined) {
			console.error("No number set");
			console.trace();
			return;
		}

		if (this.lastNumber !== undefined && currentNumber === this.lastNumber) {
			return;
		}

		const prevNumber: LayoutContainer = this.createPreviousNumber(currentNumber);
		this.previousNumbers.push(prevNumber);
		this.previousNumberContainer.addChild(prevNumber);
		this.lastNumber = currentNumber;

		if (this.previousNumbers.length >= 5) {
			const removedItem: any = this.previousNumbers.shift();
			this.previousNumberContainer.removeChild(removedItem);
		}

		this.eventBus.emit(HiLoEvents.Game.Resize);
	}

	protected createPreviousNumber(value: number): LayoutContainer {
		const config: TextFieldConfig<any> = {
			type: ComponentType.Text,
			text: value.toString(10),
			style: {
				fontFamily: ["Roboto", "sans-serif"],
				fontWeight: "700",
				fill: 0xffffff,
				align: TextAlign.Center
			},
			layout: {
				hAlign: HAlign.Center,
				vAlign: VAlign.Middle,
				dimensions: { widthPercent: 19, heightPercent: 60 }
			}
		};

		return this.gameBuilder.buildComponent(config) as LayoutContainer;
	}
}

import { Container, Graphics, Text, TextStyle } from "pixi.js";
import { injectable } from "inversify";
import { LayoutComponent } from "./LayoutComponent";
import { lazyInject } from "../ioc";
import { Types } from "../types/Types";
import { LayoutManager } from "../layout";
import { EventBus } from "../events";
import { Background, FixedDimensions, Layout, Rect } from "./config";
import { GraphicsUtils } from "../utils";
import { TextStyleConfig } from "./config/TextStyleConfig";
import { ColorBackground } from "./config/ColorBackground";

@injectable()
export class TextField extends Container implements LayoutComponent {
	@lazyInject(Types.LayoutManager)
	protected layoutManager!: LayoutManager;

	@lazyInject(Types.EventBus)
	protected eventBus!: EventBus;

	/**
	 * @inheritDoc
	 */
	public onResize: ((dimensions: FixedDimensions) => void) | undefined;

	/**
	 * The layout configuration for this component.
	 */
	protected layoutConfig: Layout;
	/**
	 * The layout dimensions calculated for the component (does not necessarily match the actual dimensions!)
	 */
	protected layoutDimensions: Rect | undefined;

	protected text: Text;

	/**
	 * A background color to be displayed within the container. Provided for debug purposes only.
	 */
	protected background: ColorBackground | undefined;

	/**
	 * The parent dimensions from the last time resize was called. This is stored in order to calculate the change in
	 * text size required for automatic text resizing.
	 */
	protected parentDimensions: FixedDimensions | undefined;

	/**
	 * Contains the background color for a Color background.
	 */
	protected graphics: Graphics;

	public constructor(text: string, style: TextStyleConfig, layout: Layout) {
		super();

		this.graphics = new Graphics();
		this.addChild(this.graphics);

		// TODO: Add text style, translations
		this.text = new Text(text, style);
		// this.text.resolution = 1;
		this.addChild(this.text);

		this.layoutConfig = layout;
	}

	/**
	 * Sets the background color used for debugging purposes.
	 *
	 * @param background The ColorBackground definition
	 */
	public setBackground(background: ColorBackground): void {
		this.background = background;
	}

	/**
	 * @inheritDoc
	 */
	public getLayoutDimensions(): Rect | undefined {
		return this.layoutDimensions;
	}

	/**
	 * @inheritDoc
	 */
	public getWidth(): number {
		return (this.layoutDimensions && this.layoutDimensions.width) || this.width;
	}

	/**
	 * @inheritDoc
	 */
	public getHeight(): number {
		return (this.layoutDimensions && this.layoutDimensions.height) || this.height;
	}

	/**
	 * @inheritDoc
	 */
	public setXPosition(value: number): void {
		if (this.layoutDimensions) {
			this.x = value;
			this.layoutDimensions.x = value;
		}
	}

	/**
	 * @inheritDoc
	 */
	public setYPosition(value: number): void {
		if (this.layoutDimensions) {
			this.y = value;
			this.layoutDimensions.y = value;
		}
	}

	/**
	 * @inheritDoc
	 */
	public setXOffset(value: number): void {
		if (this.layoutDimensions) {
			this.x = value + this.layoutDimensions.x;
		}
	}

	/**
	 * @inheritDoc
	 */
	public setYOffset(value: number): void {
		if (this.layoutDimensions) {
			this.y = value + this.layoutDimensions.y;
		}
	}

	public setText(value: string): void {
		this.text.text = value;
		this.resizeText();
	}

	public getParentDimensions(): FixedDimensions {
		return this.parentDimensions || { width: 0, height: 0 };
	}

	/**
	 * @inheritDoc
	 */
	public resize(parentDimensions: FixedDimensions): FixedDimensions {
		if (parentDimensions) {
			this.parentDimensions = parentDimensions;
		}

		const prevDimensions: Rect | undefined = this.layoutDimensions;

		try {
			const layoutDimensions: Rect = this.layoutManager.calculate(this.layoutConfig, parentDimensions);

			this.x = layoutDimensions.x;
			this.y = layoutDimensions.y;
			// Setting the width/height of a container will cause the container to scale ALL of its  content to fit to
			// exactly that width and height. This creates a huge amount of undesired behaviour.
			// To solve this, store the calculated dimensions and send those to children so any children can correctly
			// position themselves.
			this.layoutDimensions = layoutDimensions;

			if (this.background != undefined) {
				GraphicsUtils.drawBackground(
					this.graphics,
					this.background,
					layoutDimensions.width,
					layoutDimensions.height
				);
			}
		} catch (error) {
			console.warn("Error resizing:", error);
			this.layoutDimensions = prevDimensions || { x: 0, y: 0, width: 1, height: 1 };
		}

		this.resizeText();

		if (this.onResize != undefined) {
			this.onResize.call(this, this.layoutDimensions);
		}

		return { width: this.layoutDimensions.width, height: this.layoutDimensions.height };
	}

	protected resizeText(): void {
		const layoutDimensions = this.layoutDimensions;

		if (layoutDimensions != undefined && this.parent != undefined && this.text.text !== "") {
			const style: TextStyle = this.text.style;
			let fontSize: number = typeof style.fontSize === "string" ? parseInt(style.fontSize, 10) : style.fontSize;

			if (this.text.width > layoutDimensions.width || this.text.height > layoutDimensions.height) {
				while (this.text.width > layoutDimensions.width || this.text.height > layoutDimensions.height) {
					if (fontSize === 1) {
						break;
					}
					fontSize--;
					style.fontSize = fontSize;
					this.text.style = style;
				}
			} else if (this.text.width < layoutDimensions.width && this.text.height < layoutDimensions.height) {
				while (this.text.width < layoutDimensions.width && this.text.height < layoutDimensions.height) {
					fontSize++;
					style.fontSize = fontSize;
					this.text.style = style;

					if (this.text.width > layoutDimensions.width || this.text.height > layoutDimensions.height) {
						if (fontSize === 1) {
							break;
						}
						fontSize--;
						style.fontSize = fontSize;
						this.text.style = style;
						break;
					}
				}
			}

			let x: number = 0;
			if (style.align === "center") {
				x = (layoutDimensions.width - this.text.width) / 2;
			} else if (style.align === "right") {
				x = layoutDimensions.width - this.text.width;
			}

			this.text.x = x;
			this.text.y = (layoutDimensions.height - this.text.height) / 2;
		}
	}
}
